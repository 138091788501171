// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 2560px){
    .writer_profile_wrapper{
    
        padding: 2%;
    gap: 20px;
                
            }

            .writer_profile_details_container{
                width: 80%;
                background-color:#ffffff ;
                display: flex;
                justify-content: space-between;
                padding: 2%;
                gap:20px;
                border-radius: 15px;
                
            }    

            .writer_description{
                display: flex;
                flex-direction: column;
                gap: 30px;
                /* justify-content: space-between; */
                /* height: 85%; */
                
                               
                            }
                            .contributions_card_wrapper{
                                width: 30%;
                
                            } 
                            .contributions_card_container{
                                width: 80%;
                                display: flex;
                                justify-content: space-between;
                                
                                
                                               
                                            }
        .about_writer{
            font-size:22px
        }                                    
}`, "",{"version":3,"sources":["webpack://./src/styles/screen_2560_layout/screen_2560_writer_profile.css"],"names":[],"mappings":"AAAA;IACI;;QAEI,WAAW;IACf,SAAS;;YAED;;YAEA;gBACI,UAAU;gBACV,yBAAyB;gBACzB,aAAa;gBACb,8BAA8B;gBAC9B,WAAW;gBACX,QAAQ;gBACR,mBAAmB;;YAEvB;;YAEA;gBACI,aAAa;gBACb,sBAAsB;gBACtB,SAAS;gBACT,oCAAoC;gBACpC,iBAAiB;;;4BAGL;4BACA;gCACI,UAAU;;4BAEd;4BACA;gCACI,UAAU;gCACV,aAAa;gCACb,8BAA8B;;;;4CAIlB;QACpC;YACI;QACJ;AACR","sourcesContent":["@media only screen and (min-width: 2560px){\r\n    .writer_profile_wrapper{\r\n    \r\n        padding: 2%;\r\n    gap: 20px;\r\n                \r\n            }\r\n\r\n            .writer_profile_details_container{\r\n                width: 80%;\r\n                background-color:#ffffff ;\r\n                display: flex;\r\n                justify-content: space-between;\r\n                padding: 2%;\r\n                gap:20px;\r\n                border-radius: 15px;\r\n                \r\n            }    \r\n\r\n            .writer_description{\r\n                display: flex;\r\n                flex-direction: column;\r\n                gap: 30px;\r\n                /* justify-content: space-between; */\r\n                /* height: 85%; */\r\n                \r\n                               \r\n                            }\r\n                            .contributions_card_wrapper{\r\n                                width: 30%;\r\n                \r\n                            } \r\n                            .contributions_card_container{\r\n                                width: 80%;\r\n                                display: flex;\r\n                                justify-content: space-between;\r\n                                \r\n                                \r\n                                               \r\n                                            }\r\n        .about_writer{\r\n            font-size:22px\r\n        }                                    \r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
