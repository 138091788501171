import {
  date_format,
  ensure_https,
  size_wise_split_title,
  split_title,
} from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import React from "react";
import { useNavigate } from "react-router-dom";

const VerticalStoryCard = (props) => {
  const {
    thumbnail_img,
    published_date_time,
    title,
    staff_profile,
    user_profile,
    loading,
    type,
    perma_link,
    is_title_change,
    record_type,
  } = props;
  const navigate = useNavigate();
  const image_url = ensure_https(thumbnail_img);
  const { size } = useDynamicSelector("screen_width");
  const handle_redirect = () => {
    if (type === "article" || record_type === "article") {
      // navigate(`${ROUTES.SINGLE_ARTICLE}/${perma_link}.html`);
      const url = `${ROUTES.SINGLE_ARTICLE}/${perma_link}.html`;
      window.open(url, "_blank");
    } else if (type === "fact_check" || record_type === "fact_check") {
      // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
      const url = `${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`;
      window.open(url, "_blank");
    } else {
      const url = `${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`;
      window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
    }
  };
  return (
    <div
      className="home_user_contribution_card_container"
      onClick={handle_redirect}
    >
      <div className="zoom-effect home_user_contribution_image">
        <img
          src={image_url || BROKEN_IMAGE}
          className="home_user_contribution_vertical_image"
        />
      </div>
      <div
        className="home_user_contribution_second_container"
        style={{ flexDirection: is_title_change ? "column-reverse" : "column" }}
      >
        <h6 className="home_user_contribution_two_profile home_slot_card_author_name">
          {`${user_profile?.name || staff_profile?.name} | ${date_format(
            published_date_time
          )}`}
        </h6>
        <h6 className="home_user_contribution_two_title">
          {size_wise_split_title(title, size)}
          {/* {title} */}
        </h6>
      </div>
    </div>
  );
};

export default VerticalStoryCard;
