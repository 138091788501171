import { ensure_https, format_views_count } from "@src/helpers/functions";
import { BROKEN_IMAGE, PLAY_ICON } from "@src/helpers/image_constants";
import React from "react";

const HomeVideosSectionMobileView = (props) => {
  const { videos_list } = props;

  const navigate_youtube = (video_id) => {
    window.open(`https://www.youtube.com/watch?v=${video_id}`, "_blank");
  };

  return (
    <div className="home_videos_mobile_view_contents">
      {videos_list?.slice(1, 4).map((video_item, index) => {
        const image_url = ensure_https(video_item?.thumbnails);

        return (
          <div key={index} className="home_videos_mobile_view_item">
            <img
              src={image_url || BROKEN_IMAGE}
              className="home_videos_mobile_view_image"
              alt={`Video Thumbnail ${index}`}
              onClick={() => navigate_youtube(video_item?.video_id)}
            />
            <div className="home_videos_second_column_overlay_play_button">
              <img
                onClick={() => navigate_youtube(video_item?.video_id)}
                src={PLAY_ICON}
                alt="Play Icon"
                style={{ width: "100%" }}
              />
            </div>
            <div className="home_videos_viewed_count_and_published_div">
              <text className="home_videos_viewed_count_and_published">
                {`${format_views_count(video_item?.views)} views | ${
                  video_item?.days_since_published
                } days ago`}
              </text>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HomeVideosSectionMobileView;
