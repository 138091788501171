import React, { useEffect } from "react";
import UserProfileInformation from "./user_profile_details/user_profile_information";
import UserProfileDonationCard from "./user_profile_details/user_profile_donation_card";
import UserBookmarkCard from "./user_profile_details/user_bookmark_card";
import UserContributionCard from "./user_profile_details/user_contribution_card";
import UserClaimCard from "./user_profile_details/user_claim_card";
import { get_user_dashboard_count_query } from "@src/services/graphql/graphql_user_profile";
import { useAPIRequest } from "@src/helpers/hooks";

const UserProfileDetails = () => {
  const get_user_dashboard_counts = useAPIRequest(
    "get_user_dashboard_count",
    get_user_dashboard_count_query
  );

  useEffect(() => {
    get_user_dashboard_counts({}, true);
  }, []);

  return (
    <div className="user_dashboard_main_container">
      <div className="user_dashboard_container">
        <UserProfileInformation />
        <div className="user_dashboard_second_container">
          <UserProfileDonationCard />
          <UserContributionCard />
        </div>
        <div className="user_dashboard_third_container">
          <UserBookmarkCard />
          <UserClaimCard />
        </div>
      </div>
    </div>
  );
};

export default UserProfileDetails;
