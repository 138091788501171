import React from "react";
import FactCheckItems from "./fact_check_items";
import {
  INSTAGRAM_LOGO,
  LINKEDIN_LOGO,
  TELEGRAM_LOGO,
  THREADS_LOGO,
  TWITTER_LOGO,
  YOUTUBE_OUTLINED,
} from "@src/helpers/image_constants";
import { useTranslation } from "react-i18next";
import { retrieve_item } from "@src/helpers/cryptos";

function FactCheckCategorisation() {
  const { t } = useTranslation();
  const language = retrieve_item("language");
  const handle_redirect = (url) => {
    window.open(url);
  };

  return (
    <div className="fact_check_container">
      <p className="policies_heading">{t("fact_check_categorisations")}</p>
      <div className="fact_check_category_div">
        <FactCheckItems />
        <div className="share_circle">
          <img
            src={YOUTUBE_OUTLINED}
            alt=""
            className="circle_share_one"
            onClick={() =>
              handle_redirect(
                "https://www.youtube.com/channel/UCCLsLUlxvfdnRwG8_Uh40Ew"
              )
            }
          />
          <img
            src={LINKEDIN_LOGO}
            alt=""
            className="circle_share_two"
            onClick={() =>
              handle_redirect("https://in.linkedin.com/in/youturn32017")
            }
          />
          <img
            src={INSTAGRAM_LOGO}
            alt=""
            className="circle_share_three"
            onClick={() =>
              handle_redirect("https://www.instagram.com/youturn.media/")
            }
          />
          <div className="circle_share_text">
            <p
              className={
                language?.name === "tamil"
                  ? "circle_share_text_heading_ta"
                  : "circle_share_text_heading"
              }
            >
              {t("Don’t miss anything")}
            </p>
            <p
              className={
                language?.name === "tamil"
                  ? "circle_share_text_description_ta"
                  : "circle_share_text_description"
              }
            >
              {t("Follow us on our social media!")}
            </p>
          </div>
          <img
            src={TWITTER_LOGO}
            alt=""
            className="circle_share_four"
            onClick={() => handle_redirect("https://twitter.com/youturn_in")}
          />
          <img
            src={TELEGRAM_LOGO}
            alt=""
            className="circle_share_five"
            onClick={() => handle_redirect("https://t.me/youturn_in")}
          />
          <img
            src={THREADS_LOGO}
            alt=""
            className="circle_share_six"
            onClick={() =>
              handle_redirect(
                language?.name === "tamil"
                  ? "https://www.threads.net/@youturn.media"
                  : "https://www.threads.net/@youturn_english"
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FactCheckCategorisation;
