export const HEADER_LOGO = require("../assets/icons/YT1.png");
export const MENU_LOGO = require("../assets/icons/Menu_icon.png");
export const SEARCH_LOGO = require("../assets/icons/search_image.png");
export const DEFAULT_USER = require("../assets/icons/account_circle.png");
export const TWITTER_LOGO = require("../assets/icons/twitter.png");
export const YOUTUBE_LOGO = require("../assets/icons/you_tube.png");
export const LINKEDIN_LOGO = require("../assets/icons/linkedin.png");
export const INSTAGRAM_LOGO = require("../assets/icons/instagram.png");
export const FB_LOGO = require("../assets/icons/facebook.png");
export const TELEGRAM_LOGO = require("../assets/icons/telegram.png");
export const NOTIFICATION_ICON = require("../assets/icons/notification_icon.png");
export const PLAY_ICON = require("../assets/icons/play_icon.png");
export const VIDEO_ROUND_ICON = require("../assets/icons/video_round_icon.png");
export const READING_TAG = require("../assets/icons/reading_tag.gif");
export const USER_PROFILE = require("../assets/icons/user_image.jpg");
export const ARROW_GIF_ICON = require("../assets/icons/arrow_gif.gif");
export const SIGNATORY = require("../assets/icons/signatory.png");
export const INSTAGRAM_FILED_LOGO = require("../assets/icons/instagram_og.png");
export const YOUTUBE_FILED_LOGO = require("../assets/icons/youtube_og.png");
export const TELEGRAM_FILED_LOGO = require("../assets/icons/telegram_og.png");
export const FACEBOOK_FILED_LOGO = require("../assets/icons/facebook_og.png");
export const WHATSAPP_FILED_LOGO = require("../assets/icons/what's_app_logo.png");
export const FOOTER_LOGO_ICON = require("../assets/icons/you_turn_footer_logo.png");
export const TWITTER_FILED_LOGO = require("../assets/icons/twitter_black_background.png");
export const LINKEDIN_FILED_LOGO = require("../assets/icons/linkedin_og.png");
export const SHARE_CHAT_FILED_LOGO = require("../assets/icons/share_chat.png");
export const STAR_IMAGE = require("../assets/icons/hotel_class.png");
export const WRITING_IMAGE = require("../assets/icons/writing_image_new.png");
export const DRAWER_CLOSE_ICON = require("../assets/icons/drawer_close_image.png");
export const APPRECIATION_IMAGE_ = require("../assets/icons/appreciation.jpeg");
export const THREADS_LOGO = require("../assets/icons/threads_gray.png");
export const YOUTURN_JOIN_US_LOGO = require("../assets/icons/youturn_join_us_logo.png");
export const EMAIL_LOGO = require("../assets/icons/email_logo.png");
export const SEARCH_IMAGE = require("../assets/icons/search_image.png");
export const REPORT_ICON_WHITE = require("../assets/icons/report_icon_white.png");
export const SHARE_ICON = require("../assets/icons/share_icon.png");
export const SAVE_ICON_FILLED = require("../assets/icons/save_icon_filled.png");
export const REPORT_ICON_COLOR = require("../assets/icons/report_icon_color.png");
export const SHARE_ICON_COLOR = require("../assets/icons/share_icon_color.png");
export const WHATSAPP_GIF_ICON = require("../assets/icons/animation_whatsapp.gif");
export const MICK_IMAGE = require("../assets/icons/mick_image.png");
export const TRUE_NEWS_ICON = require("../assets/icons/true_news.png");
export const SAVE_FILLED_ICON = require("../assets/icons/save_icon_filled.png");
export const SAVE_FILLED_COLOR = require("../assets/icons/save_filled_color.png");
export const ARROW_ICON = require("../assets/icons/arrow_image.png");
export const YOU_TURN_LOGO_BLUE = require("../assets/icons/you_turn_small_logo_image.png");
export const YOUTURN_NEW_LOGO = require("../assets/icons/youturn_new_logo_image.png");
export const NO_CLAIM_DATA_IMAGE = require("../assets/icons/no_claim_data_image.png");
export const SAVE_WHITE_OUTLINED = require("../assets/icons/save_white_out_line.png");
export const LINK_ICON = require("../assets/icons/link_icon.png");
export const BROKEN_IMAGE = require("../assets/icons/broken_image.png");
export const FORM_BACKGROUND = require("../assets/icons/background.jpg");
export const SAVE_ICON = require("../assets/icons/save_icon.png");
export const YOUTUBE_COLOR = require("../assets/icons/you_tube_icon.png");
export const THREADS_ICON = require("../assets/icons/threads_icon.png");
export const FALSE_NEWS = require("../assets/icons/false_news.png");
export const FAKE_NEWS = require("../assets/icons/fake_news.png");
export const SARCASM_NEWS = require("../assets/icons/sarcasm_news.png");
export const MISLEADING_NEWS = require("../assets/icons/misleading_news.png");
export const MIX_NEWS = require("../assets/icons/mix_news.png");
export const YOUTURN_ICON = require("../assets/icons/aboutuc_icons/youTurnIcon.png");
export const TEAM_MEMBER_1 = require("../assets/icons/aboutuc_icons/team_members/Rectangle 179.png");
export const TEAM_MEMBER_2 = require("../assets/icons/aboutuc_icons/team_members/Rectangle 179 (1).png");
export const TEAM_MEMBER_3 = require("../assets/icons/aboutuc_icons/team_members/Rectangle 179 (2).png");
export const TEAM_MEMBER_4 = require("../assets/icons/aboutuc_icons/team_members/Rectangle 179 (3).png");
export const TEAM_MEMBER_5 = require("../assets/icons/aboutuc_icons/team_members/Rectangle 179 (4).png");
export const TEAM_MEMBER_6 = require("../assets/icons/aboutuc_icons/team_members/Rectangle 179 (5).png");
export const TEAM_MEMBER_7 = require("../assets/icons/aboutuc_icons/team_members/Rectangle 179 (6).png");
export const TEAM_MEMBER_8 = require("../assets/icons/aboutuc_icons/team_members/Rectangle 179 (7).png");
export const TEAM_MEMBER_9 = require("../assets/icons/aboutuc_icons/team_members/Rectangle 179 (8).png");
export const TEAM_MEMBER_10 = require("../assets/icons/aboutuc_icons/team_members/Rectangle 179 (9).png");
export const TEAM_MEMBER_11 = require("../assets/icons/aboutuc_icons/team_members/Rectangle 179 (10).png");
export const IFCN_LOGO = require("../assets/icons/aboutuc_icons/IFCN sticker.png");
export const BUTTON_LINK_ICON = require("../assets/icons/aboutuc_icons/Vector (1).png");
export const IMPORTANT_DEVICES = require("../assets/icons/aboutuc_icons/important_devices.png");
export const GROUP_DEVICES = require("../assets/icons/aboutuc_icons/Group 197.png");
export const POLITICS_ICON = require("../assets/icons/aboutuc_icons/politics icon.png");
export const MEDICINE_ICON = require("../assets/icons/aboutuc_icons/medicine icon.png");
export const MEDIA_RUMOR_ICON = require("../assets/icons/aboutuc_icons/media rumors icon.png");
export const NEWS_CHECK_ICON = require("../assets/icons/aboutuc_icons/news check.png");
export const FACT_CHECK_1 = require("../assets/icons/aboutuc_icons/image 50.png");
export const FACT_CHECK_2 = require("../assets/icons/aboutuc_icons/image 50 (1).png");
export const FACT_CHECK_3 = require("../assets/icons/aboutuc_icons/image 50 (2).png");
export const FACT_CHECK_4 = require("../assets/icons/aboutuc_icons/image 50 (3).png");
export const FACT_CHECK_5 = require("../assets/icons/aboutuc_icons/image 50 (4).png");
export const FACT_CHECK_6 = require("../assets/icons/aboutuc_icons/image 50 (5).png");
export const CIRCLE_SHARE_1 = require("../assets/icons/aboutuc_icons/facebook 2.png");
export const CIRCLE_SHARE_2 = require("../assets/icons/aboutuc_icons/image 17.png");
export const CIRCLE_SHARE_3 = require("../assets/icons/aboutuc_icons/image 18.png");
export const CIRCLE_SHARE_4 = require("../assets/icons/aboutuc_icons/Group 135.png");
export const FACEBOOK = require("../assets/icons/aboutuc_icons/image 36.png");
export const TWITTER = require("../assets/icons/aboutuc_icons/image 35.png");
export const INSTAGRAM = require("../assets/icons/aboutuc_icons/image 34.png");
export const YOUTUBE = require("../assets/icons/aboutuc_icons/image 33.png");
export const LINKEDIN = require("../assets/icons/aboutuc_icons/image 32.png");
export const TELEGRAM = require("../assets/icons/aboutuc_icons/image 31.png");
export const LINK_BUTTON = require("../assets/icons/aboutuc_icons/launch.png");
export const AWARD_IMAGE = require("../assets/icons/aboutuc_icons/image 23.png");
export const AWARD_LIST = require("../assets/icons/aboutuc_icons/Component 3.png");
export const STAR_ICON = require("../assets/icons/aboutuc_icons/Vector.png");

export const PUBLIC_SPIRITED_IMAGE = require("../assets/icons/public_spirited_media_image.png");
export const WHITE_STAR = require("../assets/icons/aboutuc_icons/white_star.png");

export const YOUTUBE_OUTLINED = require("../assets/icons/youtube.png");
