import JoditEditor from "jodit-react";
import React, { useMemo, useRef } from "react";

const Editor = (props) => {
  const { value, set_value, is_disabled, placeholder } = props;
  const editorRef = useRef(null);


  const config = useMemo(
    () => ({
      readonly: is_disabled || false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "",
      uploader: {
        insertImageAsBase64URI: true,
      }
    }),
    [placeholder]
  );

  const handle_blur = (value) => {
    if (value) {
      set_value(value);
    }
  };

  return (
    <div>
      <span style={{ color: "white" }}>{props.label}</span>
      <JoditEditor
        ref={editorRef}
        config={config}
        value={value}
        onBlur={handle_blur}
        key={props.label}
      />
    </div>
  );
};

export default Editor;
