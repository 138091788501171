import React from "react";
import FeaturedArticleImageContainer from "./featured_article_image_components";
import FeaturedArticleContent from "./featured_article_content";
import FeaturedArticleImageComponentSkeleton from "../featured_article_skeleton_loading/featured_article_image_component_skeleton";
import FeaturedArticleContentSkeleton from "../featured_article_skeleton_loading/featured_article_content_skeleton";
import { useDynamicSelector } from "@src/services/redux";

const FeaturedArticleBackGroundContainer = () => {
  const { loading: featured_article_loading } = useDynamicSelector(
    "get_featured_article"
  );
  return (
    <div className="featured_story_article_container">
      <div className="featured_article_back_ground_container"></div>
      <div className="featured_fact_check_container">
        {featured_article_loading ? (
          <FeaturedArticleImageComponentSkeleton />
        ) : (
          <FeaturedArticleImageContainer />
        )}
        {featured_article_loading ? (
          <FeaturedArticleContentSkeleton />
        ) : (
          <FeaturedArticleContent />
        )}
      </div>
    </div>
  );
};

export default FeaturedArticleBackGroundContainer;
