import React from "react";
import { Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";

const Description = () => {
  const { t } = useTranslation();

  return (
    <Form.Item name="description">
      <TextArea
        rows={3}
        name="description"
        placeholder={t("description")}
        style={{ marginBottom: "15px" }}
        maxLength={150}
      />
    </Form.Item>
  );
};

export default Description;
