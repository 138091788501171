import React from "react";
import FeaturedArticleContentMobileView from "./featured_user_contribution_content_mobile_view";
import FeaturedUserContributionImageContainer from "../user_contribution_featured_content/featured_user_contribution_image_components";
import FeaturedUserContributionContentMobileView from "./featured_user_contribution_content_mobile_view";
import FeaturedUserContributionImageComponentSkeleton from "../user_contribution_featured_content_skeleton_loading/featured_user_contribution_image_component_skeleton";
import FeaturedUserContributionContentSkeleton from "../user_contribution_featured_content_skeleton_loading/featured_user_contribution_content_skeleton";
import { useDynamicSelector } from "@src/services/redux";

const FeaturedUserContributionBackGroundContainerMobileView = (props) => {
  const { featured_user_contribution } = props;
  const { loading: user_contribution_loading } = useDynamicSelector(
    "get_user_contribution_list"
  );
  return (
    <div className="featured_story_fact_check_container_mobile_view">
      <div className="featured_article_back_ground_container_mobile_view"></div>
      <div className="featured_article_container_mobile_view">
        {user_contribution_loading ? (
          <FeaturedUserContributionImageComponentSkeleton />
        ) : (
          <FeaturedUserContributionImageContainer
            featured_user_contribution={featured_user_contribution}
          />
        )}
      </div>
      {user_contribution_loading ? (
        <FeaturedUserContributionContentSkeleton />
      ) : (
        <FeaturedUserContributionContentMobileView
          featured_user_contribution={featured_user_contribution}
        />
      )}
    </div>
  );
};

export default FeaturedUserContributionBackGroundContainerMobileView;
