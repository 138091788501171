import { Card, Col, Row } from "antd";
import React, { useState } from "react";
import CustomProfileCard from "./custom_profile_card";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import { useRef } from "react";
import { useEffect } from "react";
import {
  FACEBOOK_FILED_LOGO,
  INSTAGRAM_FILED_LOGO,
  LINKEDIN_FILED_LOGO,
  TWITTER_FILED_LOGO,
} from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";

const TeamMember = () => {
  const { t } = useTranslation();

  const [detail_card_open, set_detail_card_open] = useState(false);
  const [open_index, set_open_index] = useState(-1);
  const [person_detail, set_person_detail] = useState({});

  const cardRef = useRef(null);

  useEffect(() => {
    if (detail_card_open === true) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [detail_card_open]);

  const { items } = useDynamicSelector("get_team_member_list");
  const empty_click = () => {};
  const responsive = {
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const people_array =
    items
      ?.filter((item) => item.type === "team")
      .map((item) => ({
        image: item?.image_url,
        name: item?.name,
        role: item?.role,
        description: item?.description,
        id: item?.id,
        social_media_links: item?.social_media_link
          ? JSON.parse(item.social_media_link).map((link) => ({
              type: link.type,
              link: link.link.trim(),
            }))
          : [],
      })) || [];

  const chunkSize = 4;

  const chunkArray = (array, size) => {
    return array.reduce((acc, _, index) => {
      if (index % size === 0) {
        acc.push(array.slice(index, index + size));
      }
      return acc;
    }, []);
  };

  const groupedPeople = chunkArray(people_array, chunkSize);

  const people_array1 =
    items
      ?.filter((item) => item.type === "medical_expert")
      .map((item) => ({
        image: item?.image_url,
        name: item?.name,
        role: item?.role,
        description: item?.description,
        id: item?.id,
      })) || [];
  return (
    <Col className="meet_the_team">
      <Row
        className="hide_about_page_in_mobile"
        style={{
          width: "90%",
          height: "Hug (557px)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "85%" }}>
          {groupedPeople.map((row, rowIndex) => (
            <>
              <div key={rowIndex} className="team_members_profile_card">
                {row.map((people, index) => (
                  <CustomProfileCard
                    key={index}
                    rowIndex={rowIndex}
                    people={people}
                    set_detail_card_open={set_detail_card_open}
                    detail_card_open={detail_card_open}
                    set_person_detail={set_person_detail}
                    person_detail={person_detail}
                    set_open_index={set_open_index}
                  />
                ))}
              </div>
              {detail_card_open && open_index === rowIndex && (
                <div className="team_member_details_container" ref={cardRef}>
                  <div className="team_members_body_container">
                    <div className="team_members_details">
                      <h3 className="person_name">
                        {`${person_detail.name} | ${person_detail.role}`}
                      </h3>
                      <text className="person_name_details">
                        {person_detail.description}
                      </text>
                      <div className="team_social_media_container">
                        {person_detail?.social_media_links?.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <h5 className="social_media_title">
                              {"Social media"}
                            </h5>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            height: "9vh",
                            alignItems: "end",
                          }}
                        >
                          {person_detail?.social_media_links?.map(
                            (link, index) =>
                              link.type === "linked_in" ? (
                                <a
                                  key={index}
                                  href={link.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ marginRight: "10px" }}
                                >
                                  <div>
                                    <img
                                      style={{ width: "3vw" }}
                                      src={LINKEDIN_FILED_LOGO}
                                      alt="LinkedIn"
                                    />
                                  </div>
                                </a>
                              ) : link.type === "twitter" ? (
                                <a
                                  key={index}
                                  href={link.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ marginRight: "10px" }}
                                >
                                  <div>
                                    <img
                                      style={{ width: "3vw" }}
                                      src={TWITTER_FILED_LOGO}
                                      alt="Twitter"
                                    />
                                  </div>
                                </a>
                              ) : link.type === "instagram" ? (
                                <a
                                  key={index}
                                  href={link.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ marginRight: "10px" }}
                                >
                                  <div>
                                    <img
                                      style={{ width: "3vw" }}
                                      src={INSTAGRAM_FILED_LOGO}
                                      alt="instagram"
                                    />
                                  </div>
                                </a>
                              ) : link.type === "face_book" ? (
                                <a
                                  key={index}
                                  href={link.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ marginRight: "10px" }}
                                >
                                  <div>
                                    <img
                                      style={{ width: "3vw" }}
                                      src={FACEBOOK_FILED_LOGO}
                                      alt="face_book"
                                    />
                                  </div>
                                </a>
                              ) : null
                          )}
                        </div>
                      </div>
                    </div>

                    <div>
                      <CustomProfileCard
                        key={"single_detail"}
                        people={person_detail}
                        empty_click={empty_click}
                        cursor={"default"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </Row>
      <div className="hide_about_page_in_web">
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%", padding: "15px" }}>
            <Carousel
              responsive={responsive}
              infinite={true}
              draggable={false}
              swipeable={false}
              slidesToShow={1}
            >
              {groupedPeople.map((row, rowIndex) =>
                row.map((people, index) => (
                  <div className="carousel-container" key={index}>
                    <CustomProfileCard
                      rowIndex={rowIndex}
                      people={people}
                      set_detail_card_open={set_detail_card_open}
                      detail_card_open={detail_card_open}
                      set_person_detail={set_person_detail}
                      person_detail={person_detail}
                      set_open_index={set_open_index}
                      class_name={"team-carousel-item"}
                    />
                  </div>
                ))
              )}
            </Carousel>
          </div>
          {detail_card_open && open_index >= 0 && (
            <Card className="team_members_details" ref={cardRef}>
              <div className="team_member_details_body_mobile_container">
                <CustomProfileCard
                  key={"single_detail"}
                  people={person_detail}
                  empty_click={empty_click}
                  cursor={"default"}
                />
                <div className="team_member_details_mobile_container">
                  <h5 className="team_member_name_mobile">
                    {`${person_detail.name} | ${person_detail.role}`}
                  </h5>
                  <h6 className="team_member_description_mobile">
                    {person_detail.description}
                  </h6>
                  <div className="social_media_mobile_container">
                    {person_detail?.social_media_links?.map((link, index) =>
                      link.type === "linked_in" ? (
                        <a
                          key={index}
                          href={link.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginRight: "10px" }}
                        >
                          <div>
                            <img
                              src={LINKEDIN_FILED_LOGO}
                              alt="LinkedIn"
                              className="mobile_social_icon"
                            />
                          </div>
                        </a>
                      ) : link.type === "twitter" ? (
                        <a
                          key={index}
                          href={link.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginRight: "10px" }}
                        >
                          <div>
                            <img
                              className="mobile_social_icon"
                              src={TWITTER_FILED_LOGO}
                              alt="Twitter"
                            />
                          </div>
                        </a>
                      ) : link.type === "instagram" ? (
                        <a
                          key={index}
                          href={link.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginRight: "10px" }}
                          className="mobile_social_icon"
                        >
                          <div>
                            <img
                              className="mobile_social_icon"
                              src={INSTAGRAM_FILED_LOGO}
                              alt="instagram"
                            />
                          </div>
                        </a>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            </Card>
          )}
        </div>
      </div>
    </Col>
  );
};

export default TeamMember;
