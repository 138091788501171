import React from "react";

const ContactCard = (props) => {
  const { icon, contact_info, type } = props;

  return (
    <div className="contact_card">
      <div style={{ color: "#1877f2" }}>{icon}</div>
      <text style={{ fontSize: "15px", width: "100%", marginLeft: "17px" }}>
        <a href={`${type}${contact_info}`}>{contact_info}</a>
      </text>
    </div>
  );
};

export default ContactCard;
