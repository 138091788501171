import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_related_fact_check_list_query } from "@src/services/graphql/graphql_single_fact_check";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import {
  date_format,
  ensure_https,
  split_content,
  split_title,
} from "@src/helpers/functions";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@src/route/my_routes";
import RelatedFactCheckSkeleton from "./single_fact_check_report_donation/related_fact_check_skeleton";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";

const RelatedFactCheckList = () => {
  const language = retrieve_item("language");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dark_mode } = useDynamicSelector("dark_mode");

  const {
    items: related_fact_check_list,
    loading: related_fact_check_list_loading,
  } = useDynamicSelector("get_related_fact_check_list");

  const fact_check = useDynamicSelector("get_fact_check");

  let related_fact_check_list_request = useAPIRequest(
    "get_related_fact_check_list",
    get_related_fact_check_list_query
  );

  useEffect(() => {
    get_related_fact_check_list();
  }, [fact_check?.id]);

  const get_related_fact_check_list = () => {
    related_fact_check_list_request(
      {
        page_number: 2,
        page_limit: 4,
        json: {
          language_id: language?.id,
          exclude_id: fact_check?.id || "",
        },
      },
      true
    );
  };

  const handle_click_page_redirect = (perma_link) => {
    navigate(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
  };
  return (
    <div className="related_fact_check_banner">
      <div className="related_fact_check_title_container">
        <h4>{t("related_fact_checks")}</h4>
      </div>
      <div className="related_fact_check_container">
        {related_fact_check_list_loading ? (
          <>
            {related_fact_check_list?.map((_, index) => (
              <RelatedFactCheckSkeleton key={index} />
            ))}
          </>
        ) : (
          related_fact_check_list &&
          related_fact_check_list?.map((fact_check, index) => (
            <Row
              key={index}
              className="related_fact_check_card"
              onClick={() => handle_click_page_redirect(fact_check?.perma_link)}
            >
              <img
                src={ensure_https(fact_check?.thumbnail_img || BROKEN_IMAGE)}
                className="home_article_trending_image"
                alt="Thumbnail"
              />
              <Col className="home_article_trending_details_container">
                <div className="home_article_trending_title_container">
                  <Typography
                    className={`home_trending_title_text ${
                      dark_mode ? "dark_mode_card" : ""
                    }`}
                  >
                    {split_title(fact_check?.title)}
                  </Typography>
                </div>
                <div className="home_article_trending_author_container">
                  <Typography
                    className={`home_trending_author_text ${
                      dark_mode ? "dark_mode_card" : ""
                    }`}
                  >
                    {`${
                      fact_check?.staff_profile?.name ||
                      fact_check?.user_profile?.name ||
                      "youturn Editorial"
                    } | ${date_format(fact_check?.created_date_time)}`}
                  </Typography>
                </div>
              </Col>
            </Row>
          ))
        )}
      </div>
    </div>
  );
};

export default RelatedFactCheckList;
