import { ROUTES } from "@src/route/my_routes";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ExposingFakeNewsButtonMobileView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handle_redirect = () => {
    navigate(ROUTES.FACT_CHECK);
  };
  return (
    <div className="exposing_fake_news_button_mobile_container">
      <Button
        className="exposing_fake_news_button_mobile"
        onClick={handle_redirect}
      >
        {t("see_all")}
      </Button>
    </div>
  );
};

export default ExposingFakeNewsButtonMobileView;
