import { Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { useTranslation } from "react-i18next";

const FeedbackMsg = () => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name="message"
      rules={[
        {
          required: true,
          message: t("required_feedback"),
        },
      ]}
    >
      <TextArea
        maxLength={200}
        rows={4}
        placeholder={t("type_message")}
        className="feedback-input"
      />
    </Form.Item>
  );
};

export default FeedbackMsg;
