import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import {
  get_recent_article_list_query,
  related_article_list_query,
} from "@src/services/graphql/graphql_single_article";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect } from "react";
import RelatedArticleList from "./related_and_recent_article/related_article_list";
import RecentArticleList from "./related_and_recent_article/recent_article_list";

const RelatedAndRecentArticles = () => {
  let language = retrieve_item("language");

  const {
    id: article_id,
    title,
    error: get_article_error,
  } = useDynamicSelector("get_article");

  let related_article_list_request = useAPIRequest(
    "get_related_article_list",
    related_article_list_query
  );

  let recent_article_list_request = useAPIRequest(
    "get_recent_article_list",
    get_recent_article_list_query
  );

  useEffect(() => {
    if (article_id) {
      get_related_article_list();
      get_recent_article_list();
    }
  }, [article_id]);

  const get_related_article_list = () => {
    related_article_list_request(
      {
        page_number: 2,
        page_limit: 4,
        json: {
          language_id: language?.id,
          exclude_id: article_id || "",
        },
      },
      true
    );
  };

  const get_recent_article_list = () => {
    recent_article_list_request(
      {
        language_id: language?.id,
        page_limit: 4,
        page_number: 4,
        exclude_id: article_id || "",
      },
      true
    );
  };

  return (
    <div>
      <div className="related_recent_fact_check_container">
        <div className="related_recent_fact_check_wrapper">
          <div className="related_recent_fact_check_card_container">
            <RelatedArticleList />
            <RecentArticleList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedAndRecentArticles;
