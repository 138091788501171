import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect, useState } from "react";
import { Button, Empty, Skeleton, Typography } from "antd";
import CategoryWiseFactCheckCard from "../../fact_check/browse_categories/category_wise_fact_check_card";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const { Text } = Typography;

const BookmarkLaptopView = ({ page_limit, set_page_limit, record_type }) => {
  const { t } = useTranslation();
  const [show_bookmarks, set_show_bookmarks] = useState([]);
  const {
    items: bookmarks_list,
    pagination: bookmark_pagination,
    loading: bookmark_loading,
  } = useDynamicSelector("get_user_bookmark_list");

  useEffect(() => {
    if (bookmarks_list && bookmarks_list?.length) {
      set_show_bookmarks([...bookmarks_list]);
    } else {
      set_show_bookmarks([]);
    }
  }, [bookmarks_list, page_limit, record_type]);

  const click_to_see_more = () => {
    set_page_limit(page_limit + 6);
  };

  return (
    <>
      <div className="book_mark_container">
        {show_bookmarks?.map((list, index) => (
          <CategoryWiseFactCheckCard
            key={index}
            list={list}
            type={list?.record_type}
          />
        ))}
        {bookmark_loading &&
          Array.from({ length: 3 }, (_, index) => (
            <div className="bookmark bookmark_loading_container">
              <Skeleton.Image
                key={index}
                active
                style={{ height: "200px", width: "370px" }}
              />
            </div>
          ))}
      </div>
      {bookmarks_list?.length <= 0 && (
        <Empty
          description={
            record_type === "article" ? (
              <Text>{t("no_article_bookmarks_text")}</Text>
            ) : (
              <Text>{t("no_fact_check_bookmarks_text")}</Text>
            )
          }
        >
          {record_type === "article" ? (
            <Link to="/articles">
              <span>{t("click_here_to_view_articles")}</span>
            </Link>
          ) : (
            <Link to="/fact-check">
              <span>{t("click_here_to_view_fact_check")}</span>
            </Link>
          )}
        </Empty>
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {bookmark_pagination?.total_count > show_bookmarks?.length && (
          <Button onClick={click_to_see_more} className="see_more_button">
            {t("see_more")}
          </Button>
        )}
      </div>
    </>
  );
};

export default BookmarkLaptopView;
