import TeamMember from "./meet_the_team/team_member";
import MedicalExperts from "./meet_the_team/medical_expert";
import { useTranslation } from "react-i18next";

function MeetTheTeam() {
  const { t } = useTranslation();
  return (
    <div className="meet_the_team_container">
      <div className="meet_the_team_head">
        <div className="meet_the_team_heading">
          <p>{t("meet_the_team")}</p>
        </div>
        <div className="meet_the_team_right_content">
          <p className="our_mission_rightmeet_the_team_right_para">
            {/* {t("meet_the_team_top_content")} */}
            Youturn is a unit of the {""}
            <a
              style={{ textDecoration: "none" }}
              href="https://v-youturn.blr1.vultrobjects.com/2024/07/d0c41024-bf5c-4f0b-bdf4-551386af784f"
              target="_blank"
              rel="noopener noreferrer"
            >
              People Educational Awareness Trust
            </a>
            . {""}The Trust is managed by Mr.Kalidasan (Chairman & Managing
            Trustee), Mr.Rajkumar (Secretary), and Mrs.Kavitha (Treasurer). We
            currently have a team of 10 members who are not a part of any
            political parties nor inclined towards any organizations.
          </p>
          <p className="our_mission_rightmeet_the_team_right_para">
            {t("meet_the_team_middle_content")}
          </p>
          <p className="our_mission_rightmeet_the_team_right_para">
            {t("meet_the_team_bottom_content")}
          </p>
        </div>
      </div>
      <TeamMember />
      {/* <MedicalExperts />   */}
    </div>
  );
}

export default MeetTheTeam;
