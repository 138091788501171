import React, { useState } from "react";
import CategoryList from "./category_list";
import ArticleList from "./article_list";
import { useTranslation } from "react-i18next";
import SocialMediaShare from "../fact_check/social_media_share";
import { useDynamicSelector } from "@src/services/redux";

const CategoryWiseArticles = () => {
  const { t } = useTranslation();

  const [category_id, set_category_id] = useState("");
  const [category_name, set_category_name] = useState(t("all"));
  const [initial_page_limit, set_initial_page_limit] = useState(7);
  const [load_more_page_limit, set_load_more_page_limit] = useState(6);
  const [default_category_name, set_default_category_name] = useState(t("all"));
  const [page_limit, set_page_limit] = useState(6);
  const [article_list_page_limit, set_article_list_page_limit] = useState(6);

  const handle_see_all_articles = () => {
    set_page_limit(page_limit + 6);
    set_article_list_page_limit(article_list_page_limit + 6);
  };

  return (
    <div className="browse_categories_container">
      <div className="category_wise_list_container">
        <text className="fact_check_browse_categories_title">
          {t("browse_categories")}
        </text>
        <CategoryList
          set_category_id={set_category_id}
          set_default_category_name={set_default_category_name}
          set_category_name={set_category_name}
          set_load_more_page_limit={set_load_more_page_limit}
          set_initial_page_limit={set_initial_page_limit}
          set_page_limit={set_page_limit}
        />
        <ArticleList
          default_category_name={default_category_name}
          set_category_id={set_category_id}
          category_id={category_id}
          set_initial_page_limit={set_initial_page_limit}
          handle_see_all_articles={handle_see_all_articles}
          page_limit={page_limit}
        />
      </div>
      <div className="social_media_logo_container">
        <div style={{ width: "95%", height: "100%" }}>
          <SocialMediaShare />
        </div>
      </div>
    </div>
  );
};

export default CategoryWiseArticles;
