// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1440px) and (max-width: 2559px) {
  .support_us_section_container {
    padding: 2% 5% 5% 7%;
    /* height: 600px; */
  }
  .support_us_donation {
    display: none;
  }
  .support_us_section_wrapper {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1440_1999/screen_1440_2559_support_us.css"],"names":[],"mappings":"AAAA;EACE;IACE,oBAAoB;IACpB,mBAAmB;EACrB;EACA;IACE,aAAa;EACf;EACA;IACE,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;AACF","sourcesContent":["@media only screen and (min-width: 1440px) and (max-width: 2559px) {\r\n  .support_us_section_container {\r\n    padding: 2% 5% 5% 7%;\r\n    /* height: 600px; */\r\n  }\r\n  .support_us_donation {\r\n    display: none;\r\n  }\r\n  .support_us_section_wrapper {\r\n    width: 60%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 15px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
