import { ensure_https, format_views_count } from "@src/helpers/functions";
import { BROKEN_IMAGE, PLAY_ICON } from "@src/helpers/image_constants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const HomeVideosSecondColumn = (props) => {
  const { t } = useTranslation();
  const { videos_list } = props;

  const [slice_count, set_slice_count] = useState(get_slice_count());

  const navigate_youtube = (video_item) => {
    window.open(
      `https://www.youtube.com/watch?v=${video_item?.video_id}`,
      "_blank"
    );
  };

  useEffect(() => {
    const handle_resize = () => {
      set_slice_count(get_slice_count());
    };
    window.addEventListener("resize", handle_resize);
    return () => {
      window.removeEventListener("resize", handle_resize);
    };
  }, []);

  function get_slice_count() {
    const screen_width = window.innerWidth;
    if (screen_width > 1440) {
      return 10;
    } else if (screen_width > 1024) {
      return 7;
    } else {
      return 6;
    }
  }

  const get_days_since_published_text = (daysSincePublished) => {
    if (daysSincePublished === 0) {
      return t("Today");
    }

    const years = Math.floor(daysSincePublished / 365);
    const months = Math.floor((daysSincePublished % 365) / 30);
    const days = daysSincePublished % 30;
    if (years > 0) {
      return `${years} ${years === 1 ? t("year") : t("years")} ${t("ago")}`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? t("month") : t("months")} ${t("ago")}`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? t("day") : t("days")} ${t("ago")}`;
    } else {
      return t("Today");
    }
  };

  return (
    <div className="home_videos_second_column">
      <div className="home_videos_second_column_contents">
        {videos_list?.slice(1, slice_count).map((video_item, index) => {
          const image_url = ensure_https(video_item?.thumbnails);

          return (
            <div
              key={index}
              className="home_video_item"
              onClick={() => {
                navigate_youtube(video_item);
              }}
            >
              <div className="home_video_thumbnail_container">
                <img
                  src={image_url || BROKEN_IMAGE}
                  className="home_videos_second_column_image"
                  alt={`Video Thumbnail ${index}`}
                />
                <div className="home_videos_second_column_overlay_play_button">
                  <img
                    src={PLAY_ICON}
                    alt="Play Icon"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="home_videos_viewed_count_and_published_div">
                <span className="home_videos_viewed_count_and_published">{`${format_views_count(
                  video_item?.views
                )} views | ${get_days_since_published_text(
                  Number(video_item?.days_since_published)
                )}`}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default HomeVideosSecondColumn;
