import React, { useEffect, useState } from "react";
import { get_user_bookmark_list_query } from "@src/services/graphql/graphql_user_profile";
import { useAPIRequest } from "@src/helpers/hooks";
import UserProfileLayout from "../ui/user_profile/user_profile";
import { useTranslation } from "react-i18next";
import { retrieve_item } from "@src/helpers/cryptos";
import BookmarkLaptopView from "../components/user_profile/user_bookmark/bookmark_laptop_view";
import Breadcrumbs from "../components/breadcrumb/breadcrumb";
import { ROUTES } from "@src/route/my_routes";

const UserBookmarkFactChecks = () => {
  const [page_limit, set_page_limit] = useState(6);
  const { t } = useTranslation();
  const get_bookmarks_request = useAPIRequest(
    "get_user_bookmark_list",
    get_user_bookmark_list_query
  );
  const language = retrieve_item("language");
  const get_all_my_bookmark = () => {
    get_bookmarks_request(
      {
        type: "fact_check",
        page_limit: page_limit,
        // language_id: language.id
      },
      true
    );
  };

  useEffect(() => {
    get_all_my_bookmark();
  }, [page_limit]);

  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: t("home"),
    },
    {
      path: ROUTES.USER_PROFILE,
      breadcrumb_name: t("profile"),
    },
    {
      path: "",
      breadcrumb_name: t("bookmarks"),
    },
  ];
  return (
    <>
      <Breadcrumbs items={items} height={"30px"} />
      <UserProfileLayout>
        <div className="bookmark_articles_container">
          <div className="bookmark_title_container">
            <h3 className="black_text">{t("bookmark_fact_check")}</h3>
          </div>
          {/* <BookmarkArticlesMobileView /> */}
          <div className="book_mark_laptop_main_container">
            <BookmarkLaptopView
              set_page_limit={set_page_limit}
              page_limit={page_limit}
              record_type={"fact_check"}
            />
          </div>
        </div>
      </UserProfileLayout>
    </>
  );
};

export default UserBookmarkFactChecks;
