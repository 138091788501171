import { PlusOutlined } from "@ant-design/icons";
import {
  action,
  file_list,
  handle_before_upload,
  handle_file_changed,
  handle_upload,
} from "@src/helpers/file_upload";
import { useDynamicSelector } from "@src/services/redux";
import { Form, Input, InputNumber, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TbCameraUp } from "react-icons/tb";

export const UserAboutTextarea = () => {
  const { t } = useTranslation();

  const { TextArea } = Input;
  return (
    <Form.Item
      name={"about"}
      label={<h6 className="input_label"> {t("about")}</h6>}
    >
      <TextArea placeholder={t("about")} rows={4} />
    </Form.Item>
  );
};

export const UserAddressTextarea = () => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  return (
    <Form.Item name={"address"}>
      <TextArea placeholder={t("address")} />
    </Form.Item>
  );
};

export const UserMobileNumberInput = () => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name={"mobile"}
      label={<h6 className="input_label">{t("mobile")}</h6>}
      rules={[
        {
          pattern: /^[6-9]\d{9}$/,
          message: t("mobile_number_is_required"),
        },
      ]}
    >
      <InputNumber
        placeholder={t("mobile")}
        controls={false}
        style={{ width: "100%" }}
        // className="user_name_input"
      />
    </Form.Item>
  );
};
export const UserNameInput = () => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name={"name"}
      label={<h6 className="input_label">{t("name")}</h6>}
    >
      <Input
        placeholder={t("name")}
        // className="user_name_input"
      />
    </Form.Item>
  );
};

export const UserProfileUpload = () => {
  const { dark_mode } = useDynamicSelector("dark_mode");

  return (
    <Form.Item name={"profile"}>
      <Upload
        name="file"
        action={action}
        customRequest={(e) => handle_upload(e)}
        beforeUpload={(args) => handle_before_upload(args)}
        onChange={(e) => handle_file_changed(e)}
        previewFile={false}
        showUploadList={false}
      >
        <TbCameraUp
          id="upload_icon"
          size={20}
          className={dark_mode ? "light_mode_text" : ""}
        />
      </Upload>
    </Form.Item>
  );
};

export const ProfilePic = () => {
  const { t } = useTranslation();
  const { user_profile, loading: get_one_loading } =
    useDynamicSelector("get_user");
  const [file_value, set_file_values] = useState(file_list);
  let profile = user_profile?.profile_pic
    ? [
        {
          uid: 1,
          url: user_profile?.profile_pic,
          status: "done",
        },
      ]
    : [];
  useEffect(() => {
    if (user_profile?.profile_pic) {
      set_file_values([
        {
          uid: 1,
          url: user_profile?.profile_pic,
          status: "done",
        },
      ]);
    }
  }, [user_profile]);
  const handle_remove = (value) => {
    set_file_values([]);
  };

  return (
    <div>
      <Form.Item
        name={"profile"}
        label={<h6 className="input_label">{t("profile")}</h6>}
      >
        <Upload
          listType="picture-card"
          className="thumbnail_image_container"
          maxCount={1}
          action={action}
          customRequest={(e) => handle_upload(e)}
          beforeUpload={(args) => handle_before_upload(args)}
          onChange={(e) => handle_file_changed(e)}
          // showUploadList={true}
          fileList={file_value}
          // onRemove={false}
          onRemove={handle_remove}
        >
          <button className="file_upload_button" type="button">
            <PlusOutlined />
            <div className="file_upload_button_text">{t("upload")}</div>
          </button>
        </Upload>
      </Form.Item>
    </div>
  );
};
