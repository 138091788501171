import React from "react";

const SupportUsHeader = () => {
  return (
    <div className="support_us_header_container">
      <div style={{ width: "90%", display: "flex", alignItems: "center" }}>
        <h2 className="white_font">Support Us</h2>
      </div>
    </div>
  );
};

export default SupportUsHeader;
