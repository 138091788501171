import { PlusOutlined } from "@ant-design/icons";
import {
  action,
  handle_before_upload,
  handle_file_changed,
  handle_upload,
} from "@src/helpers/file_upload";
import { Form, Upload } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ImageUpload = ({ name, label }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name={"thumbnail_img"}
        label={<span style={{ color: "white" }}>{t("thumbnail_image")}</span>}
      >
        <Upload
          listType="picture-card"
          className="thumbnail_image_container"
          maxCount={1}
          action={action}
          customRequest={(e) => handle_upload(e)}
          beforeUpload={(args) => handle_before_upload(args)}
          onChange={(e) => handle_file_changed(e)}
          showUploadList={true}
        >
          <button className="file_upload_button" type="button">
            <PlusOutlined />
            <div className="file_upload_button_text">{t("upload")}</div>
          </button>
        </Upload>
      </Form.Item>
    </div>
  );
};

export default ImageUpload;
