import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import {
  get_writer_dashboard_count_query,
  get_writer_details_query,
} from "@src/services/graphql/graphql_writer_profile";
import { useDynamicSelector } from "@src/services/redux";
import UserProfileLayout from "@src/views/ui/user_profile/user_profile";
import React, { useEffect } from "react";
import WriterDetails from "./writer_details";
import WriterContributionCards from "./writer_contribution_cards";
import Breadcrumbs from "../breadcrumb/breadcrumb";
import { useLocation } from "react-router-dom";

const WriterProfileDetails = () => {
  let language = retrieve_item("language");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const staff_profile_id = queryParams.get("staff_profile");
  const user_profile_id = queryParams.get("user_profile");
  const user_name = queryParams.get("user_name");
  const record_type = queryParams.get("type");
  const { items: writer_details } = useDynamicSelector("get_writer_details");
  let writer_dashboard_count_request = useAPIRequest(
    "get_writer_dashboard_count",
    get_writer_dashboard_count_query
  );

  let writer_details_request = useAPIRequest(
    "get_writer_details",
    get_writer_details_query
  );

  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },

    {
      path: "",
      breadcrumb_name:
        writer_details?.[0]?.staff_profile?.name ||
        writer_details?.[0]?.user_profile?.name,
    },
  ];

  useEffect(() => {
    get_writer_dashboard_count();
  }, [user_profile_id, staff_profile_id]);

  useEffect(() => {
    get_writer_details();
  }, [user_profile_id, staff_profile_id, record_type]);

  const get_writer_dashboard_count = () => {
    writer_dashboard_count_request(
      {
        language_id: language?.id,
        user_id: staff_profile_id || user_profile_id,
      },
      true
    );
  };

  const get_writer_details = () => {
    writer_details_request(
      {
        user_profile_id,
        staff_profile_id,
        type: record_type,
        page_number: 1,
        language_id: language?.id,
        page_limit: 1,
      },
      true
    );
  };

  return (
    <div>
      <div style={{ height: "50px", backgroundColor: "#140f2d" }}>
        <Breadcrumbs items={items} />
      </div>
      <UserProfileLayout>
        <div className="writer_profile_wrapper">
          <WriterDetails user_name={user_name} />
          <WriterContributionCards />
        </div>
      </UserProfileLayout>
    </div>
  );
};

export default WriterProfileDetails;
