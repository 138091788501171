import { useDynamicSelector } from "@src/services/redux";
import VerticalStoryCard from "@src/views/ui/vertical_story_card";
import SlotCard from "@src/views/ui/slot_card";
import React, { useEffect, useState } from "react";
import Donation from "@src/views/ui/donation/donation";
import { useTranslation } from "react-i18next";
import FactCheckPartyWiseSecondColumnSkeletonLoading from "../../fact_check/fact_check_party_wise/fact_check_party_wise_loading/fact_check_party_wise_second_column_loading";
import FactCheckPartyWiseFirstColumnSkeletonLoading from "../../fact_check/fact_check_party_wise/fact_check_party_wise_loading/fact_check_party_wise_first_column_loading";
import { retrieve_item } from "@src/helpers/cryptos";

const UserContributionRecentlyAddedDesktopView = () => {
  const { t } = useTranslation();
  const language = retrieve_item("language");

  const [user_contributions, set_user_contributions] = useState([]);
  const {
    items: user_contribution_list,
    loading: user_contribution_list_loading,
    pagination: user_contribution_pagination,
  } = useDynamicSelector("get_user_contribution_list");
  useEffect(() => {
    set_user_contributions(user_contribution_list);
  }, [user_contribution_list]);

  return (
    <div className="recommended_story_desktop_container">
      <div className="recommended_story_desktop_title_container">
        <h2
          className={
            language?.name === "tamil" ? "user_contribution_title_ta" : ""
          }
        >
          {t("recently_added")}
        </h2>
        <div className="recommended_story_desktop_first_column_container">
          <div style={{ width: "40%" }}>
            {user_contribution_list_loading ? (
              <FactCheckPartyWiseFirstColumnSkeletonLoading />
            ) : (
              <SlotCard
                {...user_contributions?.[1]}
                type={user_contributions?.[1]?.record_type}
              />
            )}
          </div>
          {/* <div className='recommended_story_desktop_second_column_container' style={{ width: "50%" }}> */}
          {/* <div style={{ height: "20%" }}>
                        </div> */}
          <div
            className="display-flex flex-column justify-content-between"
            style={{ width: "60%" }}
          >
            {user_contribution_list_loading ? (
              <FactCheckPartyWiseSecondColumnSkeletonLoading />
            ) : (
              user_contributions?.slice(2, 6)?.map((list) => {
                return <VerticalStoryCard {...list} type={list?.record_type} />;
              })
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
      <Donation />
    </div>
  );
};

export default UserContributionRecentlyAddedDesktopView;
