import React from "react";
import UserProfileDetails from "@src/views/components/user_profile/user_profile_details";
import UserProfileLayout from "../ui/user_profile/user_profile";
import Breadcrumbs from "../components/breadcrumb/breadcrumb";
import { ROUTES } from "@src/route/my_routes";

const UserProfile = () => {
  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },
    {
      path: "",
      breadcrumb_name: "Profile",
    },
  ];
  return (
    <>
      <Breadcrumbs items={items} height={"30px"} />
      <UserProfileLayout>
        <UserProfileDetails />
      </UserProfileLayout>
    </>
  );
};

export default UserProfile;
