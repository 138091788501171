import React from "react";
import HomeUserContributionColumnThree from "../home/home_user_contribution_stories/home_user_contribution_column_three";
import SupportUsDonation from "./support_us_donation";
import { useTranslation } from "react-i18next";
import Donation from "@src/views/ui/donation/donation";

const SupportUsSection = () => {
  const { t } = useTranslation();
  return (
    <div className="support_us_section_container">
      <div className="support_us_section_wrapper">
        <SupportUsDonation />
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <text style={{ fontWeight: "bold" }}>
            {t("terms_and_conditions")}
          </text>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <text>{t("condition_first_point")}</text>
            <text>{t("condition_second_point")}</text>
          </div>
        </div>
      </div>
      <div className="support_us_donation">
        <Donation />
      </div>
      <div className="support_us_card">
        <HomeUserContributionColumnThree />
      </div>
    </div>
  );
};

export default SupportUsSection;
