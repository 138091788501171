import React, { useEffect } from "react";
import SingleFactCheckDetails from "@src/views/components/single_fact_check/single_fact_check_details";
import RelatedAndRecentFactCheck from "@src/views/components/single_fact_check/related_and_recent_fact_check";
import JoinUs from "../components/custom_components/join_us/join_us";
import SingleFactCheckPrimarySection from "../components/single_fact_check/single_fact_check_primary_section/single_fact_check_primary_section";
import SingleFactCheckReportDonation from "../components/single_fact_check/single_fact_check_report_donation/single_fact_check_report_donation";
import { useDispatch } from "react-redux";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import { useAPIRequest } from "@src/helpers/hooks";
import {
  add_fact_check_book_mark_mutation,
  bookmarks_query,
  delete_fact_check_book_mark_mutation,
  get_fact_check_query,
} from "@src/services/graphql/graphql_single_fact_check";
import { retrieve_item } from "@src/helpers/cryptos";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@src/route/my_routes";
import toast from "react-hot-toast";
import Breadcrumbs from "../components/breadcrumb/breadcrumb";
import { split_title_for_bread_crumb } from "@src/helpers/functions";
import ReportModal from "../components/single_fact_check/single_fact_check_report_donation/report_modal";

const SingleFactCheck = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { perma_link } = useParams();
  const user_id = retrieve_item("user_id");
  const language = retrieve_item("language");
  const id = perma_link?.replace(".html", "");
  const url = window.location.pathname;

  const {
    id: fact_check_id,
    title,
    error: fact_check_error,
  } = useDynamicSelector("get_fact_check");
  const { is_saved: bookmark_saved } = useDynamicSelector("get_bookmark");
  const { is_report_modal } = useDynamicSelector("report_modal_state");
  const fact_check_title = split_title_for_bread_crumb(title);

  const { status: removed_bookmark_status } = useDynamicSelector(
    "remove_fact_check_from_saved_items"
  );

  const { status: bookmark_saved_status } = useDynamicSelector(
    "add_fact_check_to_saved_items"
  );

  let fact_check_request = useAPIRequest(
    "get_fact_check",
    get_fact_check_query
  );

  let add_book_mark_request = useAPIRequest(
    "add_fact_check_to_saved_items",
    add_fact_check_book_mark_mutation
  );

  let delete_book_mark_request = useAPIRequest(
    "remove_fact_check_from_saved_items",
    delete_fact_check_book_mark_mutation
  );

  let book_marks_request = useAPIRequest("get_bookmark", bookmarks_query);

  useEffect(() => {
    if (fact_check_error) {
      dispatch(dynamic_clear("get_fact_check"));
      navigate(ROUTES.PAGE_NOT_FOUND);
    }
  }, [fact_check_error]);

  useEffect(() => {
    get_fact_check();
    get_bookmark();
  }, [perma_link]);

  useEffect(() => {
    if (title) document.title = title;
  }, [title]);

  useEffect(() => {
    if (bookmark_saved_status === "Success") {
      // toast.success("Bookmark saved");
      dispatch(dynamic_clear("add_fact_check_to_saved_items"));
      get_bookmark();
    } else if (removed_bookmark_status === "Success") {
      // toast.success("Bookmark removed");
      dispatch(dynamic_clear("remove_fact_check_from_saved_items"));
      get_bookmark();
    }
  }, [bookmark_saved_status, removed_bookmark_status]);

  const create_fact_check_book_mark = () => {
    add_book_mark_request(
      {
        fact_check_id,
      },
      true
    );
  };

  const delete_fact_check_book_mark = () => {
    delete_book_mark_request(
      {
        fact_check_id,
      },
      true
    );
  };

  const get_fact_check = () => {
    fact_check_request(
      {
        perma_link: id,
        language_id: language?.id,
      },
      true
    );
  };

  const get_bookmark = () => {
    book_marks_request(
      {
        perma_link: id,
        language_id: language?.id,
      },
      true
    );
  };

  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },
    {
      path: ROUTES.FACT_CHECK,
      breadcrumb_name: "Fact Check",
    },
    {
      path: "",
      breadcrumb_name: fact_check_title,
    },
  ];

  const handle_save = () => {
    if (user_id) {
      get_bookmark();
      if (bookmark_saved) {
        delete_fact_check_book_mark();
      } else {
        create_fact_check_book_mark();
      }
    } else {
      const queryParams = new URLSearchParams();
      queryParams.set("type", "bookmark");
      queryParams.set("types", "fact-check");
      queryParams.set("id", fact_check_id);
      queryParams.set("redirecturl", url);
      navigate({
        pathname: "/login",
        search: `?${queryParams.toString()}`,
      });
    }
  };

  return (
    <div>
      <Breadcrumbs items={items} />
      <SingleFactCheckPrimarySection handle_save={handle_save} />
      <SingleFactCheckDetails handle_save={handle_save} />
      <SingleFactCheckReportDonation />
      <RelatedAndRecentFactCheck />
      <JoinUs />
      <ReportModal
        model_open={is_report_modal}
        fact_check_id={fact_check_id}
        type={"fact-check"}
        url={url}
      />
    </div>
  );
};

export default SingleFactCheck;
