import React from "react";
import FeaturedUserContributionImageContainer from "./featured_user_contribution_image_components";
import FeaturedUserContributionContent from "./featured_user_contribution_content";
import FeaturedUserContributionImageComponentSkeleton from "../user_contribution_featured_content_skeleton_loading/featured_user_contribution_image_component_skeleton";
import FeaturedUserContributionContentSkeleton from "../user_contribution_featured_content_skeleton_loading/featured_user_contribution_content_skeleton";
import { useDynamicSelector } from "@src/services/redux";

const FeaturedUserContributionBackGroundContainer = (props) => {
  const { featured_user_contribution } = props;
  const { loading: user_contribution_loading } = useDynamicSelector(
    "get_user_contribution_list"
  );
  return (
    <div className="featured_story_article_container">
      <div className="featured_article_back_ground_container"></div>
      <div className="featured_fact_check_container">
        {user_contribution_loading ? (
          <FeaturedUserContributionImageComponentSkeleton />
        ) : (
          <FeaturedUserContributionImageContainer
            featured_user_contribution={featured_user_contribution}
          />
        )}
        {user_contribution_loading ? (
          <FeaturedUserContributionContentSkeleton />
        ) : (
          <FeaturedUserContributionContent
            featured_user_contribution={featured_user_contribution}
          />
        )}
      </div>
    </div>
  );
};

export default FeaturedUserContributionBackGroundContainer;
