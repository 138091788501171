import React from "react";
import FeaturedFactCheckImageComponent from "./featured_fact_check_image_component";
import FeaturedFactCheckContent from "./featured_fact_check_content";
import FeaturedFactCheckImageComponentSkeleton from "../featured_fact_check_loading/featured_fact_check_image_component_skeleton";
import FeaturedFactCheckContentSkeleton from "../featured_fact_check_loading/featured_fact_check_content_skeleton";
import { useDynamicSelector } from "@src/services/redux";

const FeaturedFactCheckBackGroundContainer = () => {
  const { loading: featured_fact_check_loading } = useDynamicSelector(
    "get_featured_fact_check"
  );
  return (
    <div className="featured_story_fact_check_container">
      <div className="featured_fact_check_back_ground_container"></div>
      <div className="featured_fact_check_container">
        {featured_fact_check_loading ? (
          <FeaturedFactCheckImageComponentSkeleton />
        ) : (
          <FeaturedFactCheckImageComponent />
        )}
        {featured_fact_check_loading ? (
          <FeaturedFactCheckContentSkeleton />
        ) : (
          <FeaturedFactCheckContent />
        )}
      </div>
    </div>
  );
};

export default FeaturedFactCheckBackGroundContainer;
