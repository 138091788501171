import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { ARROW_ICON } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { get_trending_result_list_query } from "@src/services/graphql/graphql_search_results";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TrendingNowSearchResults from "./common_results_container/trending_now_search_results";
import SocialMediaCard from "../single_article/single_article_details/social_media_card";

const TrendingResults = () => {
  const { t } = useTranslation();
  const language = retrieve_item("language");

  const { items: trending_result_list, loading: trending_result_list_loading } =
    useDynamicSelector("get_trending_result_list");

  let trending_result_list_request = useAPIRequest(
    "get_trending_result_list",
    get_trending_result_list_query
  );

  useEffect(() => {
    get_trending_result_list();
  }, []);

  const get_trending_result_list = () => {
    trending_result_list_request({ language_id: language?.id }, true);
  };

  return (
    <div className="tab_container_trending_results">
      <div className="trending_now_heading">
        <img src={ARROW_ICON} className="trending_now_image" />
        <h4 className="trending_now_title">{t("trending_now")}</h4>
      </div>
      <div className="trending_now_container">
        {trending_result_list?.map((list, index) => {
          return (
            <div className="trending_now_content" key={index}>
              <TrendingNowSearchResults list={list} />
            </div>
          );
        })}
      </div>
      {/* <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "sticky",
          top: 0,
        }}
      >
        <SocialMediaCard />
      </div> */}
    </div>
  );
};

export default TrendingResults;
