import { redirect_to_social_media } from "@src/helpers/functions";
import React from "react";

const SocialMediaLogo = (props) => {
  const { logo, media_name, margin_left, align_items, justify_content, url, margin_bottom, margin_top } = props;
  const on_click = () => {
    // redirect_to_social_media(media_name);
    window.open(url)
  };
  return (
    <div
      style={{
        display: "flex",
        marginLeft: margin_left,
        alignItems: align_items,
        justifyContent: justify_content,
        marginTop: margin_top || "",
        marginBottom: margin_bottom || ""
      }}
    >
      <img
        // width={30}
        src={logo}
        alt="Logo"
        onClick={on_click}
        className="social_media_logo"
      />
    </div>
  );
};

export default SocialMediaLogo;
