import { IMPORTANT_DEVICES, GROUP_DEVICES } from "@src/helpers/image_constants";
import React from "react";
import { useTranslation } from "react-i18next";

function FactCheck() {
  const { t } = useTranslation()
  return (
    <div className="">
      <p className="policies_heading">{t("how_do_we_carry_out_fact_check")}</p>
      <div className="fact_check_div">
        <div className="fact_check_divs">
          <div className="fact_check_div_mob">
            <img
              src={IMPORTANT_DEVICES}
              alt="fact_check_image"
              className="fact_check_image"
            />
            <p className="fact_check_heading_mob">{t("technology")}</p>
          </div>
          <div>
            <p className="fact_check_heading_dt">{t("technology")}</p>
            <p className="fact_check_description">
              {t("technology_content")}
            </p>
          </div>
        </div>
        <div className="fact_check_divs">
          <div className="fact_check_div_mob">
            <img src={GROUP_DEVICES} alt="" className="fact_check_image" />
            <p className="fact_check_heading_mob">{t("data_journalism")}</p>
          </div>
          <div>
            <p className="fact_check_heading_dt">{t("data_journalism")}</p>
            <p className="fact_check_description">
              {t("data_journalism_content")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FactCheck;
