import React, { useEffect } from "react";
import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import { get_featured_article_query } from "@src/services/graphql/graphql_articles";
import { dynamic_set, useDynamicSelector } from "@src/services/redux";
import { useNavigate } from "react-router-dom";
import FeaturedArticleTitle from "./featured_article/featured_article_desktop_view/featured_article_title";
import FeaturedArticleBackGroundContainer from "./featured_article/featured_article_desktop_view/featured_article_back_ground_container";
import FeaturedArticleBackGroundContainerMobileView from "./featured_article/featured_article_mobile_view/featured_article_back_ground_container_mobile_view";
import Breadcrumbs from "../breadcrumb/breadcrumb";
import { useDispatch } from "react-redux";

const FeaturedArticle = () => {
  let language = retrieve_item("language");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  let featured_article_request = useAPIRequest(
    "get_featured_article",
    get_featured_article_query
  );
  const { items: featured_article, loading: featured_article_loading } =
    useDynamicSelector("get_featured_article");

  useEffect(() => {
    get_featured_article();
  }, []);

  // useEffect(() => {
  //   dispatch(dynamic_set("article_id", { id: featured_article?.[0]?.id }));
  // }, [featured_article]);

  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },
    {
      path: "",
      breadcrumb_name: "Articles",
    },
  ];

  const get_featured_article = () => {
    featured_article_request(
      {
        page_number: 1,
        page_limit: 1,
        json: {
          language_id: language?.id,
        },
      },
      true
    );
  };

  return (
    <div>
      <Breadcrumbs items={items} />
      <FeaturedArticleTitle />
      <FeaturedArticleBackGroundContainer />
      <FeaturedArticleBackGroundContainerMobileView />
    </div>
  );
};

export default FeaturedArticle;
