import { useDynamicSelector } from "@src/services/redux";
import React from "react";
import { useTranslation } from "react-i18next";
import JoinWhatsappChannel from "../single_article/single_article_details/join_whatsapp_channel";
import SubscribeButton from "./subscribe_button";

const FeaturedVideoContent = () => {
  const { t } = useTranslation();

  const { items: video_list, loading: video_list_loading } =
    useDynamicSelector("get_video_list");
  return (
    <div className="featured_video_content">
      <div className="featured_video_content_title_wrapper">
        <h4 className="featured_story_video">{t("featured_video")}</h4>
        <text className="featured_story_video_title">
          {video_list?.[0]?.title}
        </text>
        <SubscribeButton />
      </div>
      <div className="join_whatsapp_channel_videos">
        <JoinWhatsappChannel />
      </div>
    </div>
  );
};

export default FeaturedVideoContent;
