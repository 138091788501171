import { useAPIRequest } from "@src/helpers/hooks";
import {
  YOUTURN_NEW_LOGO,
  YOU_TURN_LOGO_BLUE,
} from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LogoImageForHomePage from "../components/login/sign_up_form/logo_image_form_home";
import { forgot_password_query } from "@src/services/graphql/graphql_forgot_password";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const message = () => {
    return t("sent_email_text");
  };

  const { loading: forgot_password_loading, status: forgot_password_status } =
    useDynamicSelector("forgot_password");

  const [toast_message, set_toast_message] = useState(false);

  let forgot_password_request = useAPIRequest(
    "forgot_password",
    forgot_password_query
  );

  useEffect(() => {
    if (forgot_password_status === "Success") {
      set_toast_message(true);
      dispatch(dynamic_clear("forgot_password"));
    }
    dispatch(dynamic_clear("forgot_password"));
  }, [forgot_password_status]);

  const sign_in = () => {
    navigate(ROUTES.LOGIN);
  };

  const handle_back = () => {
    navigate(ROUTES.LOGIN);
  };

  const on_finish = (values) => {
    forgot_password_request(
      {
        username: values?.username,
      },
      true
    );
  };

  return (
    <div className="login_page_main_container body">
      <LogoImageForHomePage />
      <div className="container" id="container">
        <div className="form-container sign-up">
          <Form layout="vertical">
            <img
              style={{
                borderRadius: "9px",
                zIndex: 1,
                objectFit: "cover",
                height: "80px",
              }}
              src={YOUTURN_NEW_LOGO}
              alt="Profile"
              className="youturn_logo_mobile_view_only"
            />
            <h2 className="sing_in_text">Create Account</h2>
            <div className="view-text">
              <h6 className="sing_in_text">Already have an account?</h6>
              <a
                href="#"
                className="sing_in_text"
                style={{ marginLeft: "5vh" }}
              >
                Sign in
              </a>
            </div>
            <Form.Item
              className="margin-bottom"
              name="name"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <input type="text" placeholder="Name" className="sign_up_input" />
            </Form.Item>
            <Form.Item
              className="margin-bottom"
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Mobile number is required",
                },
              ]}
            >
              <input
                type="number"
                placeholder="Mobile"
                className="sign_up_input"
              />
            </Form.Item>
            <Form.Item
              className="margin-bottom"
              name="email"
              rules={[{ required: true, message: "Email is required" }]}
            >
              <input
                type="email"
                placeholder="Email"
                className="sign_up_input"
              />
            </Form.Item>
            <Button htmlType="submit">Submit</Button>
          </Form>
        </div>
        <div className="form-container sign-in">
          <Form onFinish={on_finish} layout="vertical" form={form}>
            <h1 className="sing_in_text">Forgot Password</h1>

            {toast_message ? (
              <div className="forgot_password_send_mail">
                {message()}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button onClick={handle_back} className="back-button">
                    {t("back")}
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <Form.Item
                  className="margin-bottom"
                  name="username"
                  rules={[
                    { required: true, message: "Email is required" },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <input
                    type="email"
                    placeholder="Username or Email"
                    className="sign_up_input"
                  />
                </Form.Item>

                <Button
                  htmlType="submit"
                  loading={forgot_password_loading}
                  className="submit_button"
                >
                  {t("Submit")}
                </Button>
              </>
            )}
          </Form>
        </div>

        <div className="toggle-container">
          <div className="toggle">
            <div className="toggle-panel toggle-left">
              <div
                style={{
                  marginTop: "70px",
                }}
              >
                <img
                  style={{
                    borderRadius: "9px",
                    zIndex: 1,
                    objectFit: "cover",
                    height: "80px",
                  }}
                  src={YOUTURN_NEW_LOGO}
                  alt="Profile"
                />
                <h1>Welcome Back!</h1>
                <p>Unlock Your News Universe! Register Today.</p>
              </div>
            </div>
            <div className="toggle-panel toggle-right toggle-panel-login">
              <div>
                <img
                  style={{
                    borderRadius: "9px",
                    zIndex: 1,
                    objectFit: "cover",
                    height: "80px",
                  }}
                  src={YOUTURN_NEW_LOGO}
                  alt="Profile"
                />
                <h1>{t("Welcome")}</h1>
                <p>{t("you_remembered_your_password")}</p>
                <div
                  style={{
                    height: "8vh",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                  className="sing_up_button_div"
                >
                  <Button
                    // className="hidden" id="register"
                    onClick={sign_in}
                    className={"login_button"}
                  >
                    {t("login")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
