import { Skeleton } from 'antd'
import React from 'react'

const FactCheckPartyWiseSecondColumnSkeletonLoading = () => {
  return (
    <div className='home_user_contribution_second_card_container' style={{ gap: "10px" }}>
      <div className="home_user_contribution_card_container" style={{ padding: "10px" }}>
        <Skeleton active paragraph={{ rows: 2 }} />
      </div>
      <div className="home_user_contribution_card_container" style={{ padding: "10px" }}>
        <Skeleton active paragraph={{ rows: 2 }} />
      </div>
      <div className="home_user_contribution_card_container" style={{ padding: "10px" }}>
        <Skeleton active paragraph={{ rows: 2 }} />
      </div>
      <div className="home_user_contribution_card_container" style={{ padding: "10px" }}>
        <Skeleton active paragraph={{ rows: 2 }} />
      </div>
    </div>
  )
}

export default FactCheckPartyWiseSecondColumnSkeletonLoading
