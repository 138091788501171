import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import { get_writer_contribution_list_query } from "@src/services/graphql/graphql_writer_profile";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../breadcrumb/breadcrumb";
import UserProfileLayout from "@src/views/ui/user_profile/user_profile";
import { useTranslation } from "react-i18next";
import { Button, Empty, Skeleton, Typography } from "antd";
import HomeArticleTrendingCard from "../home/home_articles/home_article_trending_card";
import CategoryWiseFactCheckCard from "../fact_check/browse_categories/category_wise_fact_check_card";
import { Link } from "react-router-dom";

const { Text } = Typography;

const WriterContributionList = () => {
  let language = retrieve_item("language");
  const queryParams = new URLSearchParams(location.search);
  const staff_profile_id = queryParams.get("staff_profile");
  const user_profile_id = queryParams.get("user_profile");
  const author_name = queryParams.get("author_name");
  const record_type = queryParams.get("type");
  const { t } = useTranslation();

  const [page_limit, set_page_limit] = useState(6);
  const [contribution_items, set_contribution_items] = useState([]);
  const {
    items: writer_contribution_list,
    loading: writer_contribution_list_loading,
    pagination: writer_contribution_list_pagination,
  } = useDynamicSelector("get_writer_contribution_list");

  let writer_contribution_list_request = useAPIRequest(
    "get_writer_contribution_list",
    get_writer_contribution_list_query
  );

  useEffect(() => {
    if (writer_contribution_list && writer_contribution_list?.length > 0) {
      set_contribution_items([...writer_contribution_list]);
    }
  }, [writer_contribution_list, page_limit]);

  useEffect(() => {
    get_writer_contribution_list();
  }, [record_type, page_limit, staff_profile_id, user_profile_id]);

  const get_writer_contribution_list = () => {
    writer_contribution_list_request(
      {
        user_profile_id,
        staff_profile_id,
        type: record_type,
        language_id: language?.id,
        page_limit,
      },
      true
    );
  };

  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },

    {
      path: "",
      breadcrumb_name: author_name,
    },
    // {
    //   path: "",
    //   breadcrumb_name:
    //     record_type === "article" ? t("articles") : t("fact_check"),
    // },
  ];

  const on_click_see_more = () => {
    set_page_limit(page_limit + 6);
  };
  return (
    <>
      <Breadcrumbs items={items} />
      <UserProfileLayout>
        <div className="author_contribution_list_container">
          <div className="bookmark_title_container">
            <h3 className="black_text">
              {record_type === "article" ? t("articles") : t("fact_check")}
            </h3>
          </div>
          <div className="book_mark_laptop_main_container">
            <div className="book_mark_container">
              {writer_contribution_list?.map((list, index) => (
                <CategoryWiseFactCheckCard
                  key={index}
                  list={list}
                  type={list?.record_type}
                />
              ))}
              {writer_contribution_list_loading &&
                Array.from({ length: 6 }, (_, index) => (
                  <div className="bookmark bookmark_loading_container">
                    <Skeleton.Image
                      key={index}
                      active
                      style={{ height: "200px", minWidth: "400px" }}
                    />
                  </div>
                ))}
            </div>
            {writer_contribution_list?.length <= 0 && (
              <Empty
                description={
                  record_type === "article" ? (
                    <Text>{t("no_article_contribution_text")}</Text>
                  ) : (
                    <Text>{t("no_fact_check_contribution_text")}</Text>
                  )
                }
              >
                {/* {record_type === "article" ? (
                  <Link to="/user-article-form">
                    <span>{t("click_here_to_write_articles")}</span>
                  </Link>
                ) : (
                  <Link to="/user-fact-check-form">
                    <span>{t("click_here_to_write_fact_check")}</span>
                  </Link>
                )} */}
              </Empty>
            )}
          </div>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {writer_contribution_list_pagination?.total_count > 0 &&
              writer_contribution_list_pagination?.total_count !==
                writer_contribution_list?.length && (
                <Button className="see_more_button" onClick={on_click_see_more}>
                  {t("see_more")}
                </Button>
              )}
          </div>
        </div>
      </UserProfileLayout>
    </>
  );
};

export default WriterContributionList;
