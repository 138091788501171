import { Skeleton } from "antd";
import React from "react";

const VideoListSkeletonLoading = () => {
  return (
    <div className="video_list_container">
      <div className="video_list_skeleton">
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className="video_card">
            <Skeleton.Image className="video_list_skeleton_image" />
            <div className="video_title_container">
              <Skeleton
                className="video_title"
                active
                title={false}
                paragraph={{ rows: 2 }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoListSkeletonLoading;
