// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 0px) and (max-width: 767px) {
  .user_contribution_card_skeleton_width {
    width: 350px;
    margin: 10px;
    height: 100px;
  }
  .all_stories_and_contributors_container {
    display: none;
  }
  /* .top_contributors_section_mobile_view { */
  /* display: flex;
    flex-direction: column;
    align-items: center; */
  /* } */
  .mobile_title_view_container {
    display: flex;
    width: 90%;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .contributors_image_mobile_view_section {
    width: 90%;
    height: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .user_contributors_list {
    grid-template-columns: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .user_contribution_title_ta {
    font-size: 14px !important;
    font-weight: 800 !important;
  }

  .home_user_contribute_mobile_title {
    font-size: 12px !important;
    line-height: 19px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_767/screen_767_user_contribution.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;IACZ,YAAY;IACZ,aAAa;EACf;EACA;IACE,aAAa;EACf;EACA,4CAA4C;EAC5C;;0BAEwB;EACxB,MAAM;EACN;IACE,aAAa;IACb,UAAU;IACV,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,iBAAiB;IACjB,oBAAoB;EACtB;EACA;IACE,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,0BAA0B;IAC1B,2BAA2B;EAC7B;;EAEA;IACE,0BAA0B;IAC1B,iBAAiB;EACnB;AACF","sourcesContent":["@media only screen and (min-width: 0px) and (max-width: 767px) {\r\n  .user_contribution_card_skeleton_width {\r\n    width: 350px;\r\n    margin: 10px;\r\n    height: 100px;\r\n  }\r\n  .all_stories_and_contributors_container {\r\n    display: none;\r\n  }\r\n  /* .top_contributors_section_mobile_view { */\r\n  /* display: flex;\r\n    flex-direction: column;\r\n    align-items: center; */\r\n  /* } */\r\n  .mobile_title_view_container {\r\n    display: flex;\r\n    width: 90%;\r\n    justify-content: center;\r\n    margin-top: 20px;\r\n    margin-bottom: 20px;\r\n  }\r\n\r\n  .contributors_image_mobile_view_section {\r\n    width: 90%;\r\n    height: 100px;\r\n    padding-top: 10px;\r\n    padding-bottom: 10px;\r\n  }\r\n  .user_contributors_list {\r\n    grid-template-columns: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n  .user_contribution_title_ta {\r\n    font-size: 14px !important;\r\n    font-weight: 800 !important;\r\n  }\r\n\r\n  .home_user_contribute_mobile_title {\r\n    font-size: 12px !important;\r\n    line-height: 19px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
