import {
  ensure_https,
  format_views_count,
  split_tamil_english_words,
} from "@src/helpers/functions";
import { BROKEN_IMAGE, VIDEO_ROUND_ICON } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { Button, Image } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const HomeVideosImageComponents = (props) => {
  const { videos_list } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigate_youtube = (video_item) => {
    window.open(
      `https://www.youtube.com/watch?v=${video_item?.video_id}`,
      "_blank"
    );
  };

  const get_days_since_published_text = (daysSincePublished) => {
    if (daysSincePublished === 0) {
      return t("Today");
    }

    const years = Math.floor(daysSincePublished / 365);
    const months = Math.floor((daysSincePublished % 365) / 30);
    const days = daysSincePublished % 30;
    if (years > 0) {
      return `${years} ${years === 1 ? t("year") : t("years")} ${t("ago")}`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? t("month") : t("months")} ${t("ago")}`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? t("day") : t("days")} ${t("ago")}`;
    } else {
      return t("Today");
    }
  };
  const image_url = ensure_https(videos_list?.[0]?.thumbnails);

  return (
    <div
      className="home_page_videos_thumbnail_div"
      onClick={() => navigate_youtube(videos_list?.[0])}
    >
      <img
        src={image_url || BROKEN_IMAGE}
        className="home_page_videos_thumbnail"
      />
      <div className="home_videos_thumbnail_overlay_content">
        <div className="home_videos_thumbnail_overlay_content_row">
          <img
            className="home_videos_thumbnail_overlay_play_icon"
            src={VIDEO_ROUND_ICON}
          />
          <div className="home_page_overlay_text_column">
            <text className="home_videos_thumbnail_overlay_text">
              {split_tamil_english_words(videos_list?.[0]?.title, "", 6)}
            </text>
            <text className="home_videos_thumbnail_overlay_viewed_text">
              {`${format_views_count(
                videos_list?.[0]?.views
              )} views | ${get_days_since_published_text(
                Number(videos_list?.[0]?.days_since_published)
              )}`}
            </text>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomeVideosImageComponents;
