import { Row, Skeleton } from "antd";
import React from "react";

const HomeArticleSecondColumnSkeleton = () => {
  return (
    <Row className="home_article_sec_column_inner_row_content_skeleton">
      <Skeleton.Input
        className="home_article_sec_column_inner_row_content_skeleton"
        active
      />
    </Row>
  );
};

export default HomeArticleSecondColumnSkeleton;
