// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .featured_article_image_component {
    width: 100% !important;
  }
  .featured_article_thumbnail_overlay_content {
    height: 120px;
    width: 82% !important;
  }
  .featured_article_button {
    width: 90px;
    /* height: 25px; */
    font-size: 11px;
  }
  .featured_article_mobile_view_title {
    font-size: 20px;
    font-weight: 700;
  }
  .featured_article_mobile_view_sub_title {
    font-size: 15px;
  }
  .featured_article_content_mobile_view {
    row-gap: 20px;
  }
  .featured_article_title {
    width: 95%;
  }
  .featured_story_article_container {
    display: none !important;
  }
  .recommended_story_desktop_main_container {
    display: none;
  }
  .recommended_story_mobile_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 95%;
    padding-bottom: 4%;
  }
  .featured_article_mobile_view_title_ta {
    font-size: 20px;
    font-weight: 700;
  }
  .recommended_story_mobile_title {
    font-size: 3px !important;
    color: red !important;
  }
  .recommended_story_mobile_main_container {
    margin-top: 20px;
  }
  .recommended_story_mobile_middle_container{
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_768_1023/screen_768_1023_article.css"],"names":[],"mappings":"AAAA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,aAAa;IACb,qBAAqB;EACvB;EACA;IACE,WAAW;IACX,kBAAkB;IAClB,eAAe;EACjB;EACA;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,eAAe;EACjB;EACA;IACE,aAAa;EACf;EACA;IACE,UAAU;EACZ;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,aAAa;EACf;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,UAAU;IACV,kBAAkB;EACpB;EACA;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,cAAc;EAChB;AACF","sourcesContent":["@media only screen and (min-width: 768px) and (max-width: 1023px) {\r\n  .featured_article_image_component {\r\n    width: 100% !important;\r\n  }\r\n  .featured_article_thumbnail_overlay_content {\r\n    height: 120px;\r\n    width: 82% !important;\r\n  }\r\n  .featured_article_button {\r\n    width: 90px;\r\n    /* height: 25px; */\r\n    font-size: 11px;\r\n  }\r\n  .featured_article_mobile_view_title {\r\n    font-size: 20px;\r\n    font-weight: 700;\r\n  }\r\n  .featured_article_mobile_view_sub_title {\r\n    font-size: 15px;\r\n  }\r\n  .featured_article_content_mobile_view {\r\n    row-gap: 20px;\r\n  }\r\n  .featured_article_title {\r\n    width: 95%;\r\n  }\r\n  .featured_story_article_container {\r\n    display: none !important;\r\n  }\r\n  .recommended_story_desktop_main_container {\r\n    display: none;\r\n  }\r\n  .recommended_story_mobile_container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 20px;\r\n    width: 95%;\r\n    padding-bottom: 4%;\r\n  }\r\n  .featured_article_mobile_view_title_ta {\r\n    font-size: 20px;\r\n    font-weight: 700;\r\n  }\r\n  .recommended_story_mobile_title {\r\n    font-size: 3px !important;\r\n    color: red !important;\r\n  }\r\n  .recommended_story_mobile_main_container {\r\n    margin-top: 20px;\r\n  }\r\n  .recommended_story_mobile_middle_container{\r\n    display: block;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
