import { retrieve_item } from "@src/helpers/cryptos";
import { WHATSAPP_GIF_ICON } from "@src/helpers/image_constants";
import React from "react";
import { useTranslation } from "react-i18next";

const JoinWhatsappChannel = () => {
  const { t } = useTranslation();
  const language = retrieve_item("language");

  const redirect_to_social_media = () => {
    {
      language?.name === "tamil"
        ? window.open(
            "https://whatsapp.com/channel/0029Va4HSHgLikgBHdfkX53V",
            "_blank"
          )
        : window.open(
            "https://whatsapp.com/channel/0029Va4ndSk11ulVi5VkkN30",
            "_blank"
          );
    }
  };
  return (
    <div className="join_whatsapp_channel" onClick={redirect_to_social_media}>
      <div className="whatsapp_gif_icon_container">
        <img className="whatsapp_gif_icon" src={WHATSAPP_GIF_ICON} />
      </div>
      <text className="whatsapp_channel_content">
        {t("join_our_whatsapp_channel_for_quick_updates")}
      </text>
    </div>
  );
};

export default JoinWhatsappChannel;
