import React from "react";
import { AiFillClockCircle } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import ReportShareContainerMobileView from "../../single_article/single_article_details/report_share_container_mobile_view";
import { USER_PROFILE } from "@src/helpers/image_constants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import {
  date_format,
  ensure_https,
  format_view_count,
  get_reading_time,
} from "@src/helpers/functions";
import { useTranslation } from "react-i18next";
import { Avatar, Image } from "antd";
import ReportShareFactCheckMobileView from "./report_share_fact_check_mobile_view";
import { readingTime } from "reading-time-estimator";
import { retrieve_item } from "@src/helpers/cryptos";

const SingleFactCheckWriterDetails = () => {
  const { t } = useTranslation();
  const fact_check_list = useDynamicSelector("get_fact_check");
  const navigate = useNavigate();
  let language = retrieve_item("language");

  console.log("fact_check_list", fact_check_list);

  const parser = new DOMParser();
  const doc = parser.parseFromString(fact_check_list?.content, "text/html");
  const result = readingTime(
    doc?.body?.textContent,
    language?.name === "tamil" ? 20 : 100
  );

  const image_url = ensure_https(
    fact_check_list?.staff_profile?.profile_pic ||
      fact_check_list?.user_profile?.profile_pic
  );

  const on_click_to_view_writer_details = () => {
    const queryParams = new URLSearchParams();
    if (fact_check_list?.staff_profile?.id) {
      queryParams.set("staff_profile", fact_check_list?.staff_profile?.id);
    }
    if (fact_check_list?.user_profile?.id) {
      queryParams.set("user_profile", fact_check_list?.user_profile?.id);
    }
    queryParams.set("type", "fact_check");
    navigate({
      pathname: `/writer-profile/${
        fact_check_list?.staff_profile?.name ||
        fact_check_list?.user_profile?.name
      }`,
      search: `?${queryParams.toString()}`,
    });
  };
  return (
    <div className="writer_details">
      <div className="single_article_writer_details_container">
        <Image
          onClick={on_click_to_view_writer_details}
          className="writer_profile_image"
          src={image_url || USER_PROFILE}
          preview={false}
        />
        <div className="single_article_writer_details_wrapper">
          <text className="writer_name">
            {fact_check_list?.staff_profile?.name ||
              fact_check_list?.user_profile?.name ||
              "Youturn Editorial"}
          </text>
          <text className="published_date">
            {fact_check_list?.published_date_time
              ? date_format(fact_check_list?.published_date_time)
              : date_format(fact_check_list?.created_date_time)}
          </text>
          <div className="single_article_view_and_reading_time">
            <div className="single_article_icons">
              <LuEye color="red" />
              <text className="single_article_view_count">
                {format_view_count(fact_check_list?.views)}
              </text>
            </div>
            <div className="single_article_icons">
              <AiFillClockCircle color="gray" />
              <text className="single_article_reading_time">
                {get_reading_time(fact_check_list?.reading_time, t)}
              </text>
            </div>
          </div>
        </div>
      </div>
      <div className="report_share_container">
        <ReportShareFactCheckMobileView />
      </div>
    </div>
  );
};

export default SingleFactCheckWriterDetails;
