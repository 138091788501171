import { ROUTES } from "@src/route/my_routes";
import React from "react";
import { useTranslation } from "react-i18next";
import { retrieve_item } from "@src/helpers/cryptos";

function HomeBanner() {
  const { t } = useTranslation()
  const language = retrieve_item("language")

  return (
    <>
      <div className="aboutus_banner_container">
        <p className="aboutus_banner_heading">{t("about_us")}</p>
        <p className={language?.name === "tamil" ? "aboutus_banner_content_ta" : "aboutus_banner_content"}>
          {t("about_top_content")}
        </p>
      </div>
    </>
  );

}

export default HomeBanner;
