import React from "react";
import Breadcrumbs from "../components/breadcrumb/breadcrumb";
import { ROUTES } from "@src/route/my_routes";
import TopContributionsTitle from "../components/user_contribution.jsx/top_contributors/top_contributors_title";
import TopContributorsList from "../components/user_contribution.jsx/top_contributors/top_contributors_list";

const TopContributors = () => {
  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },
    {
      path: ROUTES.USER_CONTRIBUTION,
      breadcrumb_name: "User Contribution",
    },
    {
      path: "",
      breadcrumb_name: "Contributors",
    },
  ];
  return (
    <div>
      <Breadcrumbs items={items} />
      <TopContributionsTitle />
      <TopContributorsList />
    </div>
  );
};

export default TopContributors;
