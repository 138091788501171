import React from "react";
import TopContributors from "./top_contributors";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@src/route/my_routes";
import { date_format, ensure_https, split_title } from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import VerticalStoryCard from "@src/views/ui/vertical_story_card";

const HomeUserContributionColumnTwo = ({ user_contribution_list }) => {
  return (
    <div className="home_user_contribute_second_column_container">
      <TopContributors />
      <div className="home_user_contribution_second_card_container">
        {user_contribution_list?.map((contribution) => {
          return <VerticalStoryCard {...contribution} />;
        })}
      </div>
    </div>
  );
};

export default HomeUserContributionColumnTwo;
