import React from "react";
import VerticalStoryCard from "@src/views/ui/vertical_story_card";

const FactCheckPartyWiseSecondColumn = ({ part_wise_fact_checks }) => {

  return (
    <div className="fact_check_party_wise_second_column_container">
      <div className="home_user_contribution_second_card_container">
        {part_wise_fact_checks?.map((contribution) => {
          return <VerticalStoryCard {...contribution} is_title_change={true} />;
        })}
      </div>
    </div>
  );
};

export default FactCheckPartyWiseSecondColumn;
