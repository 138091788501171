import React from "react";
import { useTranslation } from "react-i18next";

const FeaturedVideoTitle = () => {
  const { t } = useTranslation();
  return (
    <div className="featured_fact_check_title_container">
      <h2 className="featured_fact_check_title">{t("videos")}</h2>
    </div>
  );
};

export default FeaturedVideoTitle;
