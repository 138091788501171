import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@src/route/my_routes";
import { YOU_TURN_LOGO_BLUE } from "@src/helpers/image_constants";

const LogoImageForHomePage = () => {
  const navigate = useNavigate()
  const handle_back = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <div className="login_page_logo_container">
      <img
        style={{
          borderRadius: "9px",
          zIndex: 1,
          objectFit: "cover",
          height: "50px",
          cursor: "pointer",
        }}
        src={YOU_TURN_LOGO_BLUE}
        alt="Profile"
        onClick={handle_back}
      />
    </div>
  );
};

export default LogoImageForHomePage;
