import {
  date_format,
  ensure_https,
  split_tamil_english_words,
  split_title,
} from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ExposingFakeNewsImageComponent = (props) => {
  const { list } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handle_click_page_redirect = () => {
    const url = `${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`;
    window.open(url, "_blank");
    // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`);
  };
  const image_url = ensure_https(list?.thumbnail_img);

  return (
    <Row
      className="exposing_fake_news_image_component_container"
      onClick={handle_click_page_redirect}
    >
      <img
        className="exposing_fake_news_third_column_image"
        src={image_url || BROKEN_IMAGE}
      />
      <Col className="exposing_fake_news_third_column">
        <Row className="exposing_fake_news_third_column_first_row">
          <text className="exposing_fake_news_third_column_title">
            {split_title(list?.title)}
          </text>
        </Row>
        <Row className="exposing_fake_news_third_column_second_row">
          <text className="exposing_fake_news_third_column_name">
            {t(
              `${
                list?.staff_profile?.name ||
                list?.user_profile?.name ||
                "youturn Editorial"
              } | ${date_format(list?.published_date_time)}`
            )}
          </text>
        </Row>
      </Col>
    </Row>
  );
};

export default ExposingFakeNewsImageComponent;
