import { useDynamicSelector } from "@src/services/redux";
import VerticalStoryCard from "@src/views/ui/vertical_story_card";
import SlotCard from "@src/views/ui/slot_card";
import React from "react";
import Donation from "@src/views/ui/donation/donation";
import { useTranslation } from "react-i18next";
import FactCheckPartyWiseSecondColumnSkeletonLoading from "../../fact_check/fact_check_party_wise/fact_check_party_wise_loading/fact_check_party_wise_second_column_loading";
import FactCheckPartyWiseFirstColumnSkeletonLoading from "../../fact_check/fact_check_party_wise/fact_check_party_wise_loading/fact_check_party_wise_first_column_loading";

const RecommendedStoriesDesktopView = () => {
  const { t } = useTranslation();
  const {
    items: recommended_story_list,
    loading: recommended_story_list_loading,
  } = useDynamicSelector("get_recommended_story_list");

  return (
    <div className="recommended_story_desktop_container">
      <div className="recommended_story_desktop_title_container">
        <h2>{t("recommended_stories")}</h2>
        <div className="recommended_story_desktop_first_column_container">
          <div style={{ width: "40%" }}>
            {recommended_story_list_loading ? (
              <FactCheckPartyWiseFirstColumnSkeletonLoading />
            ) : (
              <SlotCard
                {...(recommended_story_list?.[0]?.fact_check ||
                  recommended_story_list?.[0]?.article)}
                type={
                  recommended_story_list?.[0]?.fact_check
                    ? "fact_check"
                    : "article"
                }
                perma_link={
                  recommended_story_list?.[0]?.fact_check?.perma_link ||
                  recommended_story_list?.[0]?.article?.perma_link
                }
              />
            )}
          </div>
          {/* <div className='recommended_story_desktop_second_column_container' style={{ width: "50%" }}> */}
          {/* <div style={{ height: "20%" }}>
                        </div> */}
          <div
            className="display-flex flex-column justify-content-between"
            style={{ width: "60%" }}
          >
            {recommended_story_list_loading ? (
              <FactCheckPartyWiseSecondColumnSkeletonLoading />
            ) : (
              recommended_story_list?.slice(1, 5)?.map((list) => {
                return (
                  <VerticalStoryCard
                    {...(list?.fact_check || list?.article)}
                    type={list?.fact_check ? "fact_check" : "article"}
                    perma_link={
                      list?.fact_check?.perma_link || list?.article?.perma_link
                    }
                    is_title_change={true}
                  />
                );
              })
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
      <Donation />
    </div>
  );
};

export default RecommendedStoriesDesktopView;
