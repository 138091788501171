import { retrieve_item } from "@src/helpers/cryptos";
import { useDynamicSelector } from "@src/services/redux";
import { Col, Input, Modal, Radio, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoOptionsOutline } from "react-icons/io5";

const FactCheckCategoryTagsMobileView = (props) => {
  const { get_category_wise_fact_check_list, set_category_id } = props;
  const { t } = useTranslation();
  const { items: category_tag_list, loading: category_tag_list_loading } =
    useDynamicSelector("get_category_tag_list");
  const current_language = retrieve_item("language");
  const [is_modal_visible, set_is_modal_visible] = useState(false);
  const [selected_category_id, set_selected_category_id] = useState(null);

  const hover_effect = (e) => {
    e.target.style.fontWeight = "bold";
    e.target.style.color = "#00adee";
  };

  const handle_radio_change = (e) => {
    set_selected_category_id(e?.target?.value);
  };
  const show_modal = () => {
    set_is_modal_visible(true);
  };

  const handle_cancel = () => {
    set_is_modal_visible(false);
  };

  const handle_ok = () => {
    get_category_wise_fact_check_list(selected_category_id);
    set_is_modal_visible(false);
    set_category_id(selected_category_id);
  };

  return (
    <div className="fact_check_mobile_title_container">
      <div onClick={show_modal} onMouseEnter={hover_effect}>
        <Input
          placeholder={t("select_category")}
          value={
            selected_category_id
              ? category_tag_list?.find(
                  (category) => category?.id === selected_category_id
                )?.name
              : ""
          }
          style={{ width: "100%" }}
          suffix={<IoOptionsOutline style={{ color: "#1890ff" }} />}
          readOnly
        />
      </div>
      <Modal
        title={t("select_category")}
        open={is_modal_visible}
        onOk={handle_ok}
        onCancel={handle_cancel}
        confirmLoading={category_tag_list_loading}
      >
        <Radio.Group
          onChange={handle_radio_change}
          value={selected_category_id}
          style={{ width: "100%" }}
        >
          <Row gutter={[16, 16]}>
            {category_tag_list &&
              category_tag_list?.map((option) => (
                <Col key={option.id} span={12}>
                  <Radio value={option.id}>{option.name}</Radio>
                </Col>
              ))}
          </Row>
        </Radio.Group>
      </Modal>
    </div>
  );
};

export default FactCheckCategoryTagsMobileView;
