import {
  amountFormatWithSymbol,
  initiate_request_razorpay_payment,
} from "@src/helpers/functions";
import { LINK_BUTTON } from "@src/helpers/image_constants";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import AppreciationModal from "@src/views/components/custom_components/appreciation_modal/appreciation_modal";
import UserDetailsFormModal from "@src/views/components/home/home_donation.jsx/user_details_form_modal/user_details_form_modal";
import { Button, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

function Funding() {
  const { t } = useTranslation();
  const [selected_amount, set_selected_amount] = useState(null);
  const [donation_modal, set_donation_modal] = useState(false);
  const [appreciation_modal_open, set_appreciation_modal] = useState(false);
  const [popper_status, set_popper_status] = useState(false);
  const [payment_response, set_payment_response] = useState("");
  const { mobile_number } = useDynamicSelector("user_entered_mobile_number");

  const dispatch = useDispatch();
  const { status: create_donation_status, order_id } =
    useDynamicSelector("create_donation");
  const payment_status = payment_response.razorpay_signature;
  const handle_redirect = () => {
    window.open(
      "https://v-youturn.blr1.vultrobjects.com/2024/07/eec4a3f4-55c1-45ec-834a-af5301e71250"
    );
  };
  const amount = [199, 599, 999];

  const handle_amount = (value) => {
    set_selected_amount(value);
  };
  const handle_change = (e) => {
    set_selected_amount(e);
  };
  const handle_donate = () => {
    set_donation_modal(true);
  };

  useEffect(() => {
    if (order_id) {
      initiate_request_razorpay_payment(
        order_id,
        set_payment_response,
        mobile_number
      );
      dispatch(dynamic_clear("create_donation"));
    }
  }, [order_id]);

  useEffect(() => {
    if (payment_status) {
      set_appreciation_modal(true);
      set_popper_status(true);
      setTimeout(() => {
        set_appreciation_modal(false);
        set_popper_status(false);
      }, 10000);
    }
  }, [payment_status]);

  return (
    <div className="funding_container">
      <div className="funding_container_left">
        <p className="funding_head"> {t("funding")}</p>
        <p className="policies_description">{t("funding_content")}</p>

        <div className="policies_button_div_funding">
          <button className="funding_button " onClick={handle_redirect}>
            {t("finance_statements")}{" "}
            <img src={LINK_BUTTON} alt="" className="button_img" />
          </button>
          {/* <button className="funding_button">
            Finance Statements 2021
            <img src={LINK_BUTTON} alt="" className="button_img" />
          </button>
          <button className="funding_button">
            Finance Statements 2022 <img src={LINK_BUTTON} alt="" className="button_img" />
          </button> */}
        </div>
      </div>
      <div className="funding_container_right">
        <p className="funding_right_head">{t("donate_top_content")}</p>
        <div className="fight_fake_news_funding_div">
          <p className="fight_fake_news_funding_hash">#</p>
          <p className="fight_fake_news_funding_hash_val">
            {t("fight_against_fake_news")}
          </p>
        </div>
        <div className="funding_right_note">
          <p className="funding_right_note_one">
            {t("make_a_small_contribution")}
          </p>
          <p className="funding_right_note_two">{t("donation_note")}</p>
        </div>
        <div className="funding_amount_div">
          {amount.map((list) => {
            return (
              <button
                className={
                  list === selected_amount
                    ? "selected_funding_amount_button"
                    : "funding_amount_button"
                }
                onClick={() => {
                  handle_amount(list);
                }}
              >
                {amountFormatWithSymbol(list)}
              </button>
            );
          })}
        </div>
        <p
          style={{ color: "white", margin: "5px", textAlign: "center" }}
          className="large_device"
        >
          or
        </p>
        <div className="funding_enter_amount_div">
          <InputNumber
            className="amount_input"
            maxLength={10}
            controls={false}
            changeOnWheel={false}
            onChange={handle_change}
            value={selected_amount}
            placeholder={t("enter_amount")}
          />
          {/* <input type="number" placeholder={t("enter_amount")} className="funding_enter_amount_button" value={selected_amount} onChange={handle_change} maxLength={10} max={10} /> */}
        </div>

        <div className="funding_buttont_div">
          <Button
            className="funding_donate_button"
            disabled={selected_amount !== null ? false : true}
            onClick={handle_donate}
          >
            {t("donate")}
          </Button>
        </div>
      </div>
      <UserDetailsFormModal
        modal_open={donation_modal}
        on_cancel={set_donation_modal}
        amount={selected_amount}
      />
      <AppreciationModal
        subtitle={t("donation_success_title")}
        message={t("donation_success_content")}
        popper_status={popper_status}
        appreciation_modal_open={appreciation_modal_open}
        set_appreciation_modal={set_appreciation_modal}
      />
    </div>
  );
}

export default Funding;
