import React from "react";
import SingleFactCheckFirstColumn from "./single_fact_check_first_column";
import SingleFactCheckSecondColumn from "./single_fact_check_second_column";
import SingleArticleFirstColumnSkeleton from "../../single_article/single_article_details/single_article_first_column_skeleton";
import FeaturedArticleImageComponentSkeleton from "../../articles/featured_article/featured_article_skeleton_loading/featured_article_image_component_skeleton";
import { useDynamicSelector } from "@src/services/redux";

const SingleFactCheckPrimarySection = (props) => {
  const { handle_save } = props;
  const { loading: fact_check_loading } = useDynamicSelector("get_fact_check");

  return (
    <div className="single_article_first_container">
      <div className="single_article_back_ground_container"></div>
      <div className="single_article_top_details_wrapper">
        {fact_check_loading ? (
          <SingleArticleFirstColumnSkeleton />
        ) : (
          <SingleFactCheckFirstColumn handle_save={handle_save} />
        )}
        {fact_check_loading ? (
          <FeaturedArticleImageComponentSkeleton />
        ) : (
          <SingleFactCheckSecondColumn />
        )}
      </div>
    </div>
  );
};

export default SingleFactCheckPrimarySection;
