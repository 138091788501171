import React from "react";
import FeaturedFactCheckImageComponent from "../featured_fact_check_desktop_view/featured_fact_check_image_component";
import FeaturedFactCheckContentMobileView from "./featured_fact_check_content_mobile_view";
import FeaturedFactCheckImageComponentSkeleton from "../featured_fact_check_loading/featured_fact_check_image_component_skeleton";
import FeaturedFactCheckContentSkeleton from "../featured_fact_check_loading/featured_fact_check_content_skeleton";
import { useDynamicSelector } from "@src/services/redux";

const FeaturedFactCheckBackGroundContainerMobileView = () => {
  const { loading: featured_fact_check_loading } = useDynamicSelector(
    "get_featured_fact_check"
  );
  return (
    <div className="featured_story_fact_check_container_mobile_view">
      <div className="featured_fact_check_back_ground_container_mobile_view"></div>
      <div className="featured_fact_check_container_mobile_view">
        {featured_fact_check_loading ? (
          <FeaturedFactCheckImageComponentSkeleton />
        ) : (
          <FeaturedFactCheckImageComponent />
        )}
      </div>
      {featured_fact_check_loading ? (
        <FeaturedFactCheckContentSkeleton />
      ) : (
        <FeaturedFactCheckContentMobileView />
      )}
    </div>
  );
};

export default FeaturedFactCheckBackGroundContainerMobileView;
