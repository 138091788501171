import { split_featured_content } from "@src/helpers/functions";
import { useDynamicSelector } from "@src/services/redux";
import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

const FeaturedFactCheckContentMobileView = () => {
  const { t } = useTranslation();
  const { items: featured_fact_check, loading: featured_fact_check_loading } =
    useDynamicSelector("get_featured_fact_check");
  return (
    <div className="featured_fact_check_content_mobile_view">
      <h4 className="featured_story_fact_check">{t("featured_story")}</h4>
      <text className="featured_fact_check_mobile_view_title">
        {featured_fact_check?.[0]?.title}
      </text>
      <text className="featured_fact_check_mobile_view_sub_title">
        {split_featured_content(featured_fact_check?.[0]?.subtitle, "_", 60)}
      </text>
    </div>
  );
};

export default FeaturedFactCheckContentMobileView;
