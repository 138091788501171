import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_my_donation_query } from "@src/services/graphql/graphql_donation";
import { useDynamicSelector } from "@src/services/redux";
import {
  countFormat,
  date_format,
  get_status_tag,
} from "@src/helpers/functions";
import { useTranslation } from "react-i18next";
import { get_claim_list_query } from "@src/services/graphql/graphql_user_profile";
import { BgColorsOutlined, EyeOutlined } from "@ant-design/icons";

const ClaimList = () => {
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const { t } = useTranslation();
  const get_claims_request = useAPIRequest(
    "get_claim_list",
    get_claim_list_query
  );
  const {
    items: claims_list,
    pagination: claim_pagination,
    loading: claim_loading,
  } = useDynamicSelector("get_claim_list");

  const get_all_my_claims = () => {
    get_claims_request(
      {
        json: {
          page_limit: page_limit,
          page_number: page_number,
        },
      },
      true
    );
  };

  useEffect(() => {
    get_all_my_claims();
  }, [page_limit, page_number]);

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };
  const handle_redirect = (url) => {
    window.open(url);
  };
  const columns = [
    {
      title: t("s_no"),
      key: "s_no",
      align: "center",

      render: (record, _, index) => {
        const current_page = page_number;
        const page_size = page_limit;
        return (current_page - 1) * page_size + index + 1;
      },
    },
    {
      title: t("date"),
      key: "created_date_time",
      dataIndex: "created_date_time",
      align: "center",

      width: 150,

      render: date_format,
    },

    {
      title: t("link"),
      align: "center",
      key: "url",
      width: 150,

      render: (text, record) =>
        record?.ref_url ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="user_claim_url"
            onClick={() => handle_redirect(record?.ref_url)}
          >
            Visit link
          </a>
        ) : (
          "-"
        ),
    },
    {
      title: t("description"),
      // title: t("description"),
      key: "description",
      dataIndex: "description",
      align: "center",

      render: (text, record) => text || "-",
    },
    {
      title: t("attachment"),
      key: "attachment",
      dataIndex: "attachment",
      align: "center",
      width: 150,
      render: (attachment) =>
        attachment ? (
          <EyeOutlined
            style={{ cursor: "pointer", color: "#00adee" }}
            onClick={() => window.open(attachment, "_blank")}
          />
        ) : (
          "-"
        ),
    },
    {
      title: t("status"),
      key: "status",
      dataIndex: "status",
      render: get_status_tag,
      className: "claim_status",
    },
  ];

  return (
    <div className="user_donation_details">
      <h2 className="black_text">{t("claims")}</h2>
      <div className="user_donation_list">
        <Table
          columns={columns}
          dataSource={claims_list}
          pagination={false}
          loading={claim_loading}
        />
        <div className="pagination_mobile_container">
          <Pagination
            total={claim_pagination?.total_count}
            showSizeChanger={true}
            page_number={page_number}
            hideOnSinglePage={false}
            onChange={fetch_data}
            showTotal={(total, range) => `Total ${countFormat(total)} items`}
          />
        </div>
      </div>
    </div>
  );
};

export default ClaimList;
