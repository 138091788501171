import { split_featured_content } from "@src/helpers/functions";
import React from "react";
import { useTranslation } from "react-i18next";

const FeaturedUserContributionContentMobileView = (props) => {
  const { featured_user_contribution } = props;
  const { t } = useTranslation();

  return (
    <div className="featured_article_content_mobile_view">
      <h4 className="featured_story_article">{t("featured_story")}</h4>
      <text className="featured_article_mobile_view_title">
        {featured_user_contribution?.[0]?.title}
      </text>
      <text className="featured_article_mobile_view_sub_title">
        {split_featured_content(
          featured_user_contribution?.[0]?.subtitle ||
            featured_user_contribution?.[0]?.content,
          "_",
          60
        )}
      </text>
    </div>
  );
};

export default FeaturedUserContributionContentMobileView;
