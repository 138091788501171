import { retrieve_item } from "@src/helpers/cryptos";
import {
  date_format,
  ensure_https,
  format_views_count,
  get_random_reading_time,
  get_reading_time,
  size_wise_split_content,
  split_content,
} from "@src/helpers/functions";
import {
  ARROW_GIF_ICON,
  BROKEN_IMAGE,
  READING_TAG,
} from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import { Col, Row, Skeleton } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillClockCircle } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import { useNavigate, useParams } from "react-router-dom";
import { readingTime } from "reading-time-estimator";

const HomeSlotCard = (props) => {
  const { slot_list } = props;
  const { t } = useTranslation();
  let language = retrieve_item("language");
  let is_english = language?.name === "english";

  const { dark_mode } = useDynamicSelector("dark_mode");
  const { size } = useDynamicSelector("screen_width");
  const READING_TIME_SEQUENCE = [6, 7, 8];

  const SlotCard = (props) => {
    const {
      thumbnail_img,
      title,
      subtitle,
      staff_profile,
      views,
      published_date_time,
      reading_time,
      height,
    } = props?.article || props.fact_check;
    const navigate = useNavigate();
    console.log("reading_time", reading_time);

    // const parser = new DOMParser();
    // const doc = parser.parseFromString(content, "text/html");
    // const result = readingTime(
    //   doc?.body?.textContent,
    //   language?.name === "tamil" ? 20 : 100
    // );

    const handle_redirect = () => {
      if (props.article && props.article.id) {
        const url = `${ROUTES.SINGLE_ARTICLE}/${props.article.perma_link}.html`;
        window.open(url, "_blank");
        // navigate(`${ROUTES.SINGLE_ARTICLE}/${props.article.perma_link}.html`);
      } else if (props.fact_check && props.fact_check.id) {
        const url = `${ROUTES.SINGLE_FACT_CHECK}/${props.fact_check.perma_link}.html`;
        window.open(url, "_blank");
        // navigate(
        //   `${ROUTES.SINGLE_FACT_CHECK}/${props.fact_check.perma_link}.html`
        // );
      }
    };

    const image_url = ensure_https(thumbnail_img);

    return (
      <div
        className={`slot_main_container ${dark_mode ? "dark_mode_card" : ""}`}
        onClick={handle_redirect}
        style={{ height: height ?? "" }}
      >
        <div className="slot_image_box zoom-effect">
          <img
            src={image_url || BROKEN_IMAGE}
            className=" image_hover_effect slot_card_img"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="slot_card_details">
          <h5 className="slot_card_title_container">{title || ""}</h5>
          <text className="slot_card_subtitle_container">
            {size_wise_split_content(subtitle, size) || ""}
          </text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div className="home_slot_card_author_detail">
              <text className="home_slot_card_author_name">
                {`${staff_profile?.name}
            
          | ${date_format(published_date_time)} `}
              </text>
            </div>
            <div
              className="display-flex flex-row  user_contributed_story_container_footer"
              style={{ alignItems: "center" }}
            >
              <div className="home_slot_view_time_wrapper">
                <div className="display-flex flex-row g-1  align-center home_user_contribute_footer">
                  <LuEye color="red" className="view-count-icon" />
                  <text className={`time_tag_text`}>
                    {format_views_count(views)}
                  </text>
                </div>
                <div className="display-flex flex-row g-1 align-center home_user_contribute_footer">
                  <AiFillClockCircle
                    color="gray"
                    className="reading-time-icon"
                  />
                  <text className="time_tag_text">
                    {get_reading_time(reading_time, t)}
                  </text>
                </div>
              </div>
              <div className="continue_reading_container">
                <h4
                  className={
                    language?.name === "tamil"
                      ? "continue_reading_text_ta"
                      : "continue_reading_text"
                  }
                >
                  {t("continue_reading")}
                </h4>
                <img src={READING_TAG} style={{ height: "30px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {slot_list?.map((list, index) => {
        return <SlotCard key={index} {...list} />;
      })}
    </>
  );
};

export default HomeSlotCard;
