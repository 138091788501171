import { Skeleton } from "antd";
import React from "react";

const SingleFactCheckReplyCommentSkeleton = () => {
  return (
    <div className="reply_comments_skeleton_container">
      <div className="reply_comments_skeleton_profile_wrapper">
        <div className="reply_comments_skeleton_profile">
          <Skeleton.Avatar active size="large" />
        </div>
        <div className="reply_comments_skeleton_info">
          <Skeleton.Input active className="reply_comments_skeleton_name" />
          <Skeleton.Input active className="reply_comments_skeleton_name" />
        </div>
      </div>
      <div>
        <Skeleton.Input className="reply_comments_skeleton_content" active />
      </div>
    </div>
  );
};

export default SingleFactCheckReplyCommentSkeleton;
