import { useDynamicSelector } from "@src/services/redux";
import React from "react";
import { useNavigate } from "react-router-dom";
import WritersSocialMediaLinks from "./writers_social_media_links";

const WriterContributionCards = () => {
  const navigate = useNavigate();
  const count = useDynamicSelector("get_writer_dashboard_count");
  const { items: writer_details } = useDynamicSelector("get_writer_details");
  const on_click_to_writer_contribution_list = (type) => {
    const queryParams = new URLSearchParams();
    if (writer_details?.[0]?.staff_profile?.id) {
      queryParams.set("staff_profile", writer_details?.[0]?.staff_profile?.id);
      queryParams.set("author_name", writer_details?.[0]?.staff_profile?.name);
    }
    if (writer_details?.[0]?.user_profile?.id) {
      queryParams.set("user_profile", writer_details?.[0]?.user_profile?.id);
      queryParams.set("author_name", writer_details?.[0]?.user_profile?.name);
    }
    queryParams.set("type", type);
    navigate({
      pathname: `/writer-contribution-list/${
        writer_details?.[0]?.staff_profile?.name ||
        writer_details?.[0]?.user_profile?.name
      }`,
      search: `?${queryParams.toString()}`,
    });
  };

  let author_links = [];

  if (writer_details?.[0]?.staff_profile?.url) {
    try {
      const social_media_urls = JSON.parse(writer_details[0].staff_profile.url);
      if (social_media_urls.facebook) {
        author_links.push({
          type: "facebook",
          link: social_media_urls.facebook.trim(),
        });
      }
      if (social_media_urls.twitter) {
        author_links.push({
          type: "twitter",
          link: social_media_urls.twitter.trim(),
        });
      }
      if (social_media_urls.instagram) {
        author_links.push({
          type: "instagram",
          link: social_media_urls.instagram.trim(),
        });
      }
    } catch (error) {
      console.error("Error parsing social media URLs:", error);
    }
  }
  const social_media_url = author_links.some((link) => link.link);

  return (
    <div className="contributions_card_container">
      <div
        className="contributions_card_wrapper"
        onClick={() => on_click_to_writer_contribution_list("article")}
      >
        <h5>Article</h5>
        <h2>{count?.article_count}</h2>
      </div>
      <div
        className="contributions_card_wrapper"
        onClick={() => on_click_to_writer_contribution_list("fact_check")}
      >
        <h5>Fact Check</h5>
        <h2>{count?.fact_check_count}</h2>
      </div>
      {social_media_url && (
        <div className="contributions_card_wrapper">
          <h5>Follow me on social media</h5>
          <WritersSocialMediaLinks author_links={author_links} />
        </div>
      )}
    </div>
  );
};

export default WriterContributionCards;
