import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "html-react-parser";
import Donation from "@src/views/ui/donation/donation";
import JoinWhatsappChannel from "./join_whatsapp_channel";
import SocialMediaCard from "./social_media_card";
import ShareSaveContainer from "./share_save_container";
import TweetIframe from "../../custom_components/tweet_iframe/tweet_iframe";
import cheerio from "cheerio";
import HomeDonation from "../../home/home_donation.jsx/home_donation";
import { Skeleton } from "antd";
import SingleFactCheckReport from "../../single_fact_check/single_fact_check_report_donation/single_fact_check_report";

const SingleArticleDetailsWithDonationContainer = (props) => {
  const { handle_save } = props;
  const { content, loading: article_loading } =
    useDynamicSelector("get_article");
  const [parsed_html, set_parsed_html] = useState("");
  const { dark_mode } = useDynamicSelector("dark_mode");
  const options = {
    replace: ({ name, attribs, children }) => {
      if (name === "TweetIframe" || name === "tweetiframe") {
        return <TweetIframe {...attribs}>{children}</TweetIframe>;
      }
      return null;
    },
  };
  useEffect(() => {
    if (content) {
      const $ = cheerio.load(
        content
          .replace(/\[/g, "<")
          .replace(/\]/g, ">")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
      );

      const twitterEmbed = (url) => {
        return `
            <blockquote class="twitter-tweet">
              <a href="${url}"></a>
            </blockquote>
            <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
          `;
      };

      $("a[href^='https://twitter.com/']").each((index, element) => {
        const twitterLink = $(element).attr("href");
        if (twitterLink) {
          const embeddedTweet = twitterEmbed(twitterLink);
          $(element).replaceWith(embeddedTweet);
        }
      });

      $("blockquote.instagram-media").each((index, element) => {
        const blockquote = $(element);
        const instagramLink = blockquote.attr("data-instgrm-permalink");
        if (instagramLink) {
          const instagramUrl = instagramLink.split("?")[0]; // Get the base Instagram URL
          const iframe = `<iframe src="${instagramUrl}embed" width="500" height="800" frameborder="0" scrolling="no" allowtransparency></iframe>`;
          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });
      $("blockquote").each((index, element) => {
        const blockquote = $(element);
        const tweetUrl = blockquote
          .find('a[href^="https://twitter.com/"]')
          .last()
          .attr("href");
        if (tweetUrl) {
          const iframe = `<br><TweetIframe tweeturl=${tweetUrl}></br>`;
          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });

      $("video").each((index, element) => {
        const videoElement = $(element);
        const videoSrc = videoElement.attr("mp4");
        const videoWidth = videoElement.attr("width");
        const videoHeight = videoElement.attr("height");
        const videoFrame = `<video width="100%" height="auto" controls>
                                  <source src="${videoSrc}" type="video/mp4"/>
                              </video>`;
        videoElement.replaceWith(videoFrame);
      });
      if (dark_mode) {
        $("body").addClass("dark_mode   article-content");
      } else {
        $("body").addClass("article-content");
      }
      set_parsed_html($.html());
    }
  }, [content]);

  return (
    <div className="single_article_details_with_donation_wrapper dark_mode">
      <div className="single_article_details_with_donation_container">
        <div
          className={`single_article_content_container ${
            dark_mode ? "dark_mode" : ""
          }`}
        >
          {article_loading ? (
            <Skeleton active paragraph={{ rows: 20 }} />
          ) : parsed_html ? (
            ReactHtmlParser(parsed_html, options)
          ) : (
            ""
          )}

          <ShareSaveContainer handle_save={handle_save} />
          <div className="join_whatsapp_channel_division">
            <JoinWhatsappChannel />
          </div>
          {/* <ReportContainer /> */}
          <SingleFactCheckReport type={"article"} />
        </div>
        <div className="single_article_donation_container">
          <div className="single_article_donation">
            <SocialMediaCard />
          </div>
          <JoinWhatsappChannel />
          <div className="social_media_card_wrapper">
            <Donation />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleArticleDetailsWithDonationContainer;
