import { Skeleton } from "antd";
import React from "react";

const ExposingFakeNewsThirdColumnSkeleton = () => {
  return (
    <div className="exposing_fake_news_third_skeleton">
      <Skeleton.Input className="exposing_fake_news_third_column_skeleton" active />
    </div>
  );
};

export default ExposingFakeNewsThirdColumnSkeleton;
