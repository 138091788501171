import React from 'react'
import { BUTTON_LINK_ICON, LINK_BUTTON } from "@src/helpers/image_constants";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@src/route/my_routes';

function Policy() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handle_redirect_correction_policy = () => {
    navigate(ROUTES.CORRECTION_POLICY)
  }
  const handle_redirect_political_involvement_policy = () => {
    navigate(ROUTES.POLITICAL_INVOLVEMENT_POLICY)
  }


  return (
    <div >
      <p className='policies_heading'>{t("policies")}</p>
      <div className='policies_button_div'>
        <button className='policies_button' onClick={handle_redirect_correction_policy}>{t("correction_policy")}<img src={LINK_BUTTON} alt="" className='button_img' /> </button>
        <button className='policies_button' onClick={handle_redirect_political_involvement_policy}>{t("political_involvement_policy")}<img src={LINK_BUTTON} alt="" className='button_img' /></button>
      </div>
    </div>
  )
}

export default Policy
