import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { get_recent_article_list_query } from "@src/services/graphql/graphql_single_article";
import RecentFactCheckCard from "../../single_fact_check/recent_fact_check_card";
import RecentFactCheckSkeleton from "../../single_fact_check/single_fact_check_report_donation/recent_fact_check_skeleton";

const RecentArticleList = () => {
  const language = retrieve_item("language");
  const { t } = useTranslation();

  const { items: recent_article_list, loading: recent_article_loading } =
    useDynamicSelector("get_recent_article_list");

  // let recent_article_list_request = useAPIRequest(
  //   "get_recent_article_list",
  //   get_recent_article_list_query
  // );

  // useEffect(() => {
  //   get_recent_article_list();
  // }, []);

  // const get_recent_article_list = () => {
  //   recent_article_list_request(
  //     {
  //       language_id: language?.id,
  //       page_limit: 4,
  //     },
  //     true
  //   );
  // };

  return (
    <div className="recent_fact_check_banner">
      <div className="recent_fact_check_title_container">
        <h4>{t("recently_added_article")}</h4>
      </div>

      <div className="recent_fact_check_container">
        {recent_article_loading ? (
          <>
            {recent_article_list?.map((_, index) => (
              <RecentFactCheckSkeleton key={index} />
            ))}
          </>
        ) : (
          recent_article_list?.map((factCheck, index) => (
            <RecentFactCheckCard
              key={index}
              list={factCheck}
              type={"article"}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default RecentArticleList;
