import React from 'react'
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../components/breadcrumb/breadcrumb';
import { ROUTES } from '@src/route/my_routes';

const CorrectionPolicy = () => {
    const { t } = useTranslation()
    const items = [
        {
            path: ROUTES.HOME,
            breadcrumb_name: t("home"),
        },
        {
            path: ROUTES.ABOUT_US,
            breadcrumb_name: t("about_us"),
        },
        {
            path: "",
            breadcrumb_name: t("corrections_policy"),
        },
    ];
    return (
        <>
            <Breadcrumbs items={items} />
            <div className="corrections-policy">
                <h1>{t("corrections_policy")}</h1>
                <p>We fact-check claims that require verification. All the reports published by us hold the proof links or source of the facts provided in the report. And the complete article is checked by our team before publishing it.</p>
                <p>If you think it needs a correction in the article or needs any improvement in the explanation of the information, please let us know in the “Report Article” section which is available below each article.</p>
                <p>You can also send us your complaints on our Website, Twitter, Facebook, Instagram page or email us at info@youturn.in.</p>
                <p>We are open to corrections, suggestions, and feedback which is essential for our improvement. Major corrections made in the article will be highlighted under the “Update” section.</p>
                <p>To err is human. We here in YouTurn are committed to making things right if any article published has any incorrect information readers can report for correction in the respective article through the website. Here is a summary of the correction policy for employees.</p>
                <ol>
                    <li>When any correction is reported, it is to be analyzed thoroughly. If the analyst finds it to be a valid correction it is being escalated to the writer. If the correction is found invalid the reported reader should respond to the reader with the details.</li>
                    <li>The respective employee shall make the corrections if applicable as quickly as possible which in turn would reflect in the published article. Any data before posted on the website should be reviewed and authorized by the editor.</li>
                    <li>A separate title named “UPDATE” will be added at the end of the article which will hold the corrected information. The same shall be notified to the reported reader.</li>
                    <li>Errors of fact need to be corrected and updated separately wherein typos, grammatical mistakes, misspellings, wrong word usage, and other such things just need correction but need not update it separately. Any additional information related to the article may also be added under the “UPDATE” column from time to time despite the correction.</li>
                </ol>
            </div>
        </>

    )
}

export default CorrectionPolicy
