import { retrieve_item } from "@src/helpers/cryptos";
import { useDynamicSelector } from "@src/services/redux";
import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const HomeArticlePrimaryTitle = () => {
  const { t } = useTranslation();
  const { dark_mode } = useDynamicSelector("dark_mode");
  return (
    <div className="home_article_title_container">
      <Typography
        className={`home_article_title ${dark_mode ? "dark_mode_text" : ""}`}
      >
        {t("whats_new")}
      </Typography>

      <h2
        className={`exposing_fake_news_secondary_title ${
          dark_mode ? "dark_mode_text" : ""
        }`}
      >
        {t("articles")}
      </h2>
    </div>
  );
};

export default HomeArticlePrimaryTitle;
