import { ROUTES } from "@src/route/my_routes";
import { useNavigate } from "react-router-dom";
import UserContributionRecentlyAddedMobileView from "./user_contribution_recently_added/user_contribution_recently_added_mobile_view";
import UserContributionRecentlyAddedDesktopView from "./user_contribution_recently_added/user_contribution_recently_added_desktop_view";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect } from "react";
import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_user_contribution_list_query } from "@src/services/graphql/graphql_user_contribution";

const RecentlyAddedUserContribution = () => {
  const current_language = retrieve_item("language");
  const navigate = useNavigate();
  const user_contribution_list_request = useAPIRequest(
    "get_user_contribution_list",
    get_user_contribution_list_query
  );

  const get_user_contribution_list = () => {
    user_contribution_list_request(
      {
        language_id: current_language?.id,
        page_limit: 10,
        page_number: 1,
      },
      true
    );
  };
  useEffect(() => {
    get_user_contribution_list();
  }, []);

  return (
    <>
      <div className="recommended_story_desktop_main_container">
        <UserContributionRecentlyAddedDesktopView />
      </div>
      <div className="recommended_story_mobile_main_container">
        <UserContributionRecentlyAddedMobileView />
      </div>
    </>
  );
};

export default RecentlyAddedUserContribution;
