import { FACEBOOK, INSTAGRAM, LINKEDIN, TELEGRAM, TWITTER, YOUTUBE } from "@src/helpers/image_constants";
import React from "react";

function StayConnect() {
  const stay_connect_icon = [
    {
      description: "Facebook",
      image_url:FACEBOOK,
    },
    {
      description: "X(Twitter)",
      image_url:TWITTER,
    },
    {
      description: "Instagram",
      image_url:INSTAGRAM,
    },
    {
      description: "Youtube",
      image_url:YOUTUBE,
    },
    {
      description: "Linkedin",
      image_url:LINKEDIN,
    },
    {
      description: "Telegram",
      image_url:TELEGRAM
    },
  ];
  return (
    <div className="stay_connect_mobile">
      <h5 className="stay_connect_mobile_heading">Stay Connected</h5>
      <div className="stay_connect_items">
        {stay_connect_icon.map((item)=>(
            <div className="stay_connect_item">
                <img src={item.image_url} alt="" />
                <p>{item.description}</p>
            </div>
        ))}
      </div>
    </div>
  );
}

export default StayConnect;
