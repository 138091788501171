import { Row, Skeleton } from "antd";
import React from "react";

const RelatedFactCheckSkeleton = () => {
  return (
    <Row className="home_article_trending_container_skeleton">
      <Skeleton.Input
        className="home_article_trending_container_skeleton"
        active
      />
    </Row>
  );
};

export default RelatedFactCheckSkeleton;
