// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1440px) and (max-width: 2559px) {
  .writer_profile_wrapper {
    padding: 2%;
    gap: 20px;
  }
  .writer_profile_details_container {
    width: 80%;
    background-color: #ffffff;
    display: flex;
    /* justify-content: space-between; */
    padding: 2%;
    gap: 20px;
    border-radius: 15px;
  }
  .writer_description {
    display: flex;
    flex-direction: column;
    gap: 30px;
    /* justify-content: space-between; */
    /* height: 85%; */
  }
  .contributions_card_wrapper {
    width: 30%;
  }
  .contributions_card_container {
    width: 80%;
    display: flex;
    justify-content: space-between;
  }
  .about_writer{
    font-size:20px
} 
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1440_1999/screen_1440_2559_writer_profile.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;IACX,SAAS;EACX;EACA;IACE,UAAU;IACV,yBAAyB;IACzB,aAAa;IACb,oCAAoC;IACpC,WAAW;IACX,SAAS;IACT,mBAAmB;EACrB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,oCAAoC;IACpC,iBAAiB;EACnB;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;IACV,aAAa;IACb,8BAA8B;EAChC;EACA;IACE;AACJ;AACA","sourcesContent":["@media only screen and (min-width: 1440px) and (max-width: 2559px) {\r\n  .writer_profile_wrapper {\r\n    padding: 2%;\r\n    gap: 20px;\r\n  }\r\n  .writer_profile_details_container {\r\n    width: 80%;\r\n    background-color: #ffffff;\r\n    display: flex;\r\n    /* justify-content: space-between; */\r\n    padding: 2%;\r\n    gap: 20px;\r\n    border-radius: 15px;\r\n  }\r\n  .writer_description {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 30px;\r\n    /* justify-content: space-between; */\r\n    /* height: 85%; */\r\n  }\r\n  .contributions_card_wrapper {\r\n    width: 30%;\r\n  }\r\n  .contributions_card_container {\r\n    width: 80%;\r\n    display: flex;\r\n    justify-content: space-between;\r\n  }\r\n  .about_writer{\r\n    font-size:20px\r\n} \r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
