import { retrieve_item } from "@src/helpers/cryptos";
import {
  date_format,
  ensure_https,
  format_view_count,
  get_reading_time,
  size_wise_split_content,
  split_content,
} from "@src/helpers/functions";
import { BROKEN_IMAGE, READING_TAG } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import { Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiFillClockCircle } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { readingTime } from "reading-time-estimator";

const HomeArticlePrimaryCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let language = retrieve_item("language");
  const { dark_mode } = useDynamicSelector("dark_mode");
  const { items: article_list } = useDynamicSelector("get_article_list");
  const { size } = useDynamicSelector("screen_width");

  const handle_click_page_redirect = () => {
    const url = `${ROUTES.SINGLE_ARTICLE}/${article_list?.[0]?.perma_link}.html`;
    window.open(url, "_blank");
    // navigate(`${ROUTES.SINGLE_ARTICLE}/${article_list?.[0]?.perma_link}.html`);
  };
  const image_url = ensure_https(article_list?.[0]?.thumbnail_img);

  // const parser = new DOMParser();
  // const doc = parser.parseFromString(article_list?.[0]?.content, "text/html");
  // const result = readingTime(
  //   doc?.body?.textContent,
  //   language?.name === "tamil" ? 20 : 100
  // );

  return (
    <div
      className="home_article_first_card_container"
      onClick={handle_click_page_redirect}
    >
      <div className="home_article_card_thumbnail_container">
        <img
          src={image_url || BROKEN_IMAGE}
          className="home_article_trending_thumbnail"
        />
      </div>
      <div className="home_article_card_details_wrapper">
        <div className="home_article_card_title">
          <Typography
            className={`title_text  ${dark_mode ? "dark_mode_text" : ""}`}
          >
            {article_list?.[0]?.title}
          </Typography>
        </div>
        <div className="home_article_card_subtitle">
          <Typography
            className={`sub_title_text ${dark_mode ? "dark_mode_text" : ""}`}
          >
            {size_wise_split_content(article_list?.[0]?.subtitle, size, 7, 5) ||
              ""}
          </Typography>
        </div>

        <div className="home_article_card_author_details_wrapper">
          <div className="home_article_card_author_name_container">
            <Typography
              className={`sub_title_text ${dark_mode ? "dark_mode_text" : ""}`}
            >
              {" "}
              {article_list?.[0]?.staff_profile?.name ||
                article_list?.[0]?.user_profile?.name ||
                "youturn Editorial"}
            </Typography>
          </div>
          <div className="home_article_card_author_details">
            <div className="home_article_card_time_container">
              <LuEye className="view_icon" />
              <Typography
                className={`sub_title_text  ${
                  dark_mode ? "dark_mode_text" : "is_red"
                }`}
              >
                {" "}
                {format_view_count(article_list?.[0]?.views)}
              </Typography>
            </div>
          </div>
        </div>

        <div className="home_article_card_author_details_wrapper">
          <div className="home_article_card_author_name_container">
            <Typography
              className={`sub_title_text ${dark_mode ? "dark_mode_text" : ""}`}
            >
              {" "}
              {date_format(
                article_list?.[0]?.published_date_time ||
                  article_list?.[0]?.created_date_time
              )}
            </Typography>
          </div>
          <div className="home_article_card_author_details">
            <div className="home_article_card_time_container">
              <AiFillClockCircle className="reading_icon" />
              <Typography
                className={`sub_title_text ${
                  dark_mode ? "dark_mode_text" : ""
                }`}
              >
                {get_reading_time(article_list?.[0]?.reading_time, t)}
              </Typography>
            </div>
          </div>
        </div>
        <div className="home_article_card_continue_read">
          <h4 className="continue_reading_text">{t("continue_reading")}</h4>
          <img src={READING_TAG} style={{ height: "30px" }} />
        </div>
      </div>
    </div>
  );
};

export default HomeArticlePrimaryCard;
