import { ensure_https } from "@src/helpers/functions";
import { USER_PROFILE } from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";
import { Image } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const WriterDetails = ({ user_name }) => {
  const { items: writer_details } = useDynamicSelector("get_writer_details");
  const { t } = useTranslation();

  const image_url = ensure_https(
    writer_details?.[0]?.staff_profile?.profile_pic ||
      writer_details?.[0]?.user_profile?.profile_pic
  );

  return (
    <div className="writer_profile_details_container">
      <div style={{ height: "150px" }}>
        <img
          preview={false}
          src={image_url || USER_PROFILE}
          className="editor_profile_image"
        />
      </div>
      <div className="writer_description">
        <h3>
          {writer_details?.[0]?.staff_profile?.name ||
            writer_details?.[0]?.user_profile?.name ||
            user_name}
        </h3>
        <text className="about_writer">
          {writer_details?.[0]?.staff_profile?.about ||
            writer_details?.[0]?.user_profile?.about ||
            t("about_writer")}
        </text>
      </div>
    </div>
  );
};

export default WriterDetails;
