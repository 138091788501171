import React from "react";
import { useNavigate } from "react-router-dom";

const HeaderDrawerMenuOptions = ({ handle_drawer_close }) => {
  const navigate = useNavigate();
  const menu_items = [
    { href: "/home", label: "Home" },
    { href: "/article", label: "Articles" },
    { href: "/fact-check", label: "Fact Check" },
    { href: "/user-contribution", label: "User Contributions" },
    { href: "/videos", label: "Videos" },
    { href: "/about-us", label: "About Us" },
    { href: "/contact-us", label: "Contact Us" },
    { href: "/support-us", label: "Support Us" },
  ];

  const handle_redirect = (path) => {
    navigate(path);
    handle_drawer_close();
  };
  return (
    <>
      <hr className="drawer_line" />
      <div className="drawer_menu_container">
        {menu_items.map((item) => (
          <a
            key={item.href}
            className="user_layout_menus"
            onClick={() => handle_redirect(item.href)}
          >
            {item.label}
          </a>
        ))}
      </div>
    </>
  );
};

export default HeaderDrawerMenuOptions;
