import { Skeleton } from "antd";
import React from "react";

const RecentFactCheckSkeleton = () => {
  return (
    <div className="recent_fact_check_card_skeleton">
      <Skeleton.Input className="recent_fact_check_card_skeleton" active />
    </div>
  );
};

export default RecentFactCheckSkeleton;
