import { retrieve_item } from "@src/helpers/cryptos";
import { split_featured_content } from "@src/helpers/functions";
import { useDynamicSelector } from "@src/services/redux";
import React from "react";
import { useTranslation } from "react-i18next";

const FeaturedArticleContentMobileView = () => {
  let language = retrieve_item("language");
  const { t } = useTranslation();

  const { items: featured_article } = useDynamicSelector(
    "get_featured_article"
  );
  return (
    <div className="featured_article_content_mobile_view">
      <h4 className="featured_story_article">{t("featured_article")}</h4>
      <text
        // className="featured_article_mobile_view_title"
        className={
          language?.name === "tamil"
            ? "featured_article_mobile_view_title_ta"
            : "featured_article_mobile_view_title"
        }
      >
        {featured_article?.[0]?.title}
      </text>
      <text className="featured_article_mobile_view_sub_title">
        {split_featured_content(
          featured_article?.[0]?.subtitle || featured_article?.[0]?.content,
          "_",
          60
        )}
      </text>
    </div>
  );
};

export default FeaturedArticleContentMobileView;
