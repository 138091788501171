import { retrieve_item } from "@src/helpers/cryptos";
import { countFormat, date_format, get_status } from "@src/helpers/functions";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import { Button, Card, Empty, Pagination, Skeleton, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlinePendingActions, MdPublish } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  query_get_my_all_article,
  query_get_my_published_article,
} from "@src/services/graphql/graphql_articles";
import HomeArticleTrendingCard from "../../home/home_articles/home_article_trending_card";
import CategoryWiseFactCheckCard from "../../fact_check/browse_categories/category_wise_fact_check_card";

const UserContributionArticlesList = () => {
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const { t } = useTranslation();
  const [publish_article, set_publish_article] = useState([]);
  const get_articles_request = useAPIRequest(
    "get_my_article_list",
    query_get_my_all_article
  );
  const get_article_published_request = useAPIRequest(
    "get_article_published_request",
    query_get_my_published_article
  );
  const language = retrieve_item("language");
  const navigate = useNavigate();
  const {
    items: all_articles_list,
    loading: all_articles_loading,
    pagination: all_articles_pagination,
    status: all_fat_check_list_status,
  } = useDynamicSelector("get_my_article_list");
  const {
    items: all_article_published_list,
    loading: all_article_published_loading,
    pagination: all_article_published_pagination,
  } = useDynamicSelector("get_article_published_request");

  const get_my_article_list = () => {
    get_articles_request(
      {
        // status: "pending",
        // language_id: language?.id
        page_limit: page_limit,
        page_number: page_number,
      },
      true
    );
  };

  useEffect(() => {
    get_my_article_list();
  }, [page_number, page_limit]);

  useEffect(() => {
    get_article_published_request(
      { status: "published", page_limit: 6, page_number: 1 },
      true
    );
  }, []);
  const handle_see_more = () => {
    get_article_published_request(
      {
        status: "published",
        page_limit: all_article_published_pagination?.page_limit + 3,
        page_number: 1,
      },
      true
    );
  };

  useEffect(() => {
    if (all_article_published_list) {
      set_publish_article([...all_article_published_list]);
    }
  }, [all_article_published_list]);
  const columns = [
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
    },

    {
      title: t("created_date"),
      key: "created_date_time",
      dataIndex: "created_date_time",
      render: date_format,
    },
    {
      title: t("status"),
      key: "status",
      dataIndex: "status",
      render: get_status,
    },
  ];
  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };
  const handle_add = () => {
    navigate(ROUTES.USER_ARTICLE_FORM);
  };
  const handle_row_click = (record) => {
    navigate(`${ROUTES.USER_ARTICLE_FORM}/${record?.id}`);
  };
  const MobileListCard = (props) => {
    const { created_date_time, description, status, ref_url, title } = props;
    return (
      // <Card style={{ marginBottom: "15px" }}>
      <div
        style={{
          width: "100%",
          border: "1px solid gray",
          borderRadius: "5px",
          marginBottom: "10px",
          padding: "5px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>{t("title")}</div>
        <div style={{ fontSize: "13px" }}>{title}</div>

        <table>
          <tr>
            <th>{t("date")}</th>
            <td>{date_format(created_date_time)}</td>
          </tr>
          <tr>
            <th>{t("status")}</th>
            <td>{get_status(status)}</td>
          </tr>
        </table>
        {/* // </Card> */}
      </div>
    );
  };
  const UserContributionFactCheckPendingList = () => {
    return (
      <div>
        <div className="donation_mobile_container">
          {all_articles_loading
            ? Array.from({ length: 2 }, (_, index) => (
                <Card style={{ marginBottom: "15px" }}>
                  <Skeleton key={index} active paragraph={{ rows: 2 }} />
                </Card>
              ))
            : all_articles_list?.map((article) => {
                return <MobileListCard {...article} />;
              })}
        </div>

        <Table
          style={{ width: "100%", marginTop: "0px" }}
          columns={columns}
          dataSource={all_articles_list}
          pagination={false}
          loading={all_articles_loading}
          onRow={(record) => ({
            onClick: () => handle_row_click(record),
          })}
          className="user_donation_details"
        />
        <div
          className="pagination_mobile_container"
          style={{ marginTop: "10px" }}
        >
          <Pagination
            total={all_articles_pagination?.total_count}
            showSizeChanger={true}
            page_number={page_number}
            hideOnSinglePage={false}
            onChange={fetchData}
            showTotal={(total, range) => `Total ${countFormat(total)} items`}
          />
        </div>
      </div>
    );
  };

  const UserContributionFactCheckPublishList = () => {
    return (
      <>
        <div className="book_mark_container">
          {all_article_published_loading &&
            Array.from({ length: 3 }, (_, index) => (
              <div className="bookmark bookmark_loading_container">
                <Skeleton.Image
                  key={index}
                  active
                  style={{ height: "200px", width: "380px" }}
                />
              </div>
            ))}
          {publish_article?.map((list, index) => (
            <CategoryWiseFactCheckCard
              key={index}
              list={list}
              type={"article"}
            />
          ))}
        </div>
        <div className="empty_container">
          {!all_article_published_loading &&
          !all_article_published_list?.length > 0 ? (
            <Empty description={t("no_published_article")} />
          ) : (
            ""
          )}
        </div>
        {all_article_published_list?.length > 3 && (
          <div className="see_more_container">
            <Button className="see_more_videos" onClick={handle_see_more}>
              {t("see_more")}
            </Button>
          </div>
        )}
      </>
    );
  };

  const tab_items = [
    {
      key: "1",
      label: t("published"),
      children: <UserContributionFactCheckPublishList />,
      icon: <MdPublish />,
    },
    {
      key: "2",
      label: t("pending"),
      children: <UserContributionFactCheckPendingList />,
      icon: <MdOutlinePendingActions />,
    },
  ];

  return (
    <div className="user_profile_contribution_fact_check">
      <h2 className="black_text">{t("my_articles")}</h2>
      <div className="pagination_mobile_container">
        <Button onClick={handle_add} type="primary">
          {t("create_user_contribution_article")}
        </Button>
      </div>
      <div className="tab_container">
        <Tabs items={tab_items} defaultActiveKey="1" />
      </div>
    </div>
  );
};

export default UserContributionArticlesList;
