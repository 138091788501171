import React from "react";
import SupportUsHeader from "../components/support_us/support_us_header";
import { ROUTES } from "@src/route/my_routes";
import Breadcrumbs from "../components/breadcrumb/breadcrumb";
import SupportUsSection from "../components/support_us/support_us_section";

const SupportUs = () => {
  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },
    {
      path: "",
      breadcrumb_name: "Support Us",
    },
  ];
  return (
    <div>
      <Breadcrumbs items={items} />
      <SupportUsHeader />
      <SupportUsSection />
    </div>
  );
};

export default SupportUs;
