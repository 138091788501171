import { Skeleton } from "antd";
import React from "react";

const SearchResultsListSkeletonLoading = () => {
  return (
    <div className="search_results_container_skeleton">
      <div className="search_results_list_skeleton">
        {Array.from({ length: 4 }).map((_, index) => (
          <div key={index} className="search_results_card">
            <Skeleton className="search_result_card_skeleton_width" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchResultsListSkeletonLoading;
