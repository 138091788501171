import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const SubTitle = () => {
  const { t } = useTranslation();
  const { TextArea } = Input
  return (
    <Form.Item
      label={
        <span style={{ color: "white" }}>{t("subtitle")}</span>
      }
      name="subtitle"
    >
      <TextArea placeholder="Subtitle" />
    </Form.Item>
  );
};
export default SubTitle;
