import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_user_contributors_list_query } from "@src/services/graphql/graphql_user_contribution";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContributionTitle from "./user_contribution_title";
import { DEFAULT_USER, USER_PROFILE } from "@src/helpers/image_constants";
import { useTranslation } from "react-i18next";
import { FaPen } from "react-icons/fa";
import { LuEye } from "react-icons/lu";
import { AiFillClockCircle } from "react-icons/ai";
import { date_format, format_view_count } from "@src/helpers/functions";
import TopContributersOverlayContent from "./top_contributers_overlay_content";
import HomeArticleTrendingCard from "../home/home_articles/home_article_trending_card";
import { Button } from "antd";
import { ROUTES } from "@src/route/my_routes";

const TopContributers = () => {
  const { t } = useTranslation();
  const current_language = retrieve_item("language");
  const navigate = useNavigate();

  let user_contributors_list_request = useAPIRequest(
    "get_user_contributors_list",
    get_user_contributors_list_query
  );

  const [page_limit, set_page_limit] = useState(4);
  const {
    items: user_contributors_list,
    pagination: user_contributors_pagination,
  } = useDynamicSelector("get_user_contributors_list");

  useEffect(() => {
    get_user_contributors_list();
  }, []);

  const get_user_contributors_list = () => {
    user_contributors_list_request(
      {
        language_id: current_language?.id,
        page_number: 1,
        page_limit: page_limit,
      },
      true
    );
  };
  const handle_see_all = () => {
    navigate(ROUTES.TOP_CONTRIBUTORS);
  };

  return (
    <div className="top_contributors_section">
      <div className="top_contributors_title">
        <UserContributionTitle title={t("top_contributors")} />
      </div>
      <div
        className="top_contributors_banner"
        style={{ position: "sticky", top: 100 }}
      >
        <div className="top_contributors_container">
          {user_contributors_list?.slice(0, 4)?.map((item, index) => {
            return <TopContributersOverlayContent item={item} index={index} />;
          })}
          <div></div>
        </div>
        {user_contributors_list && (
          <Button
            onClick={handle_see_all}
            style={{
              width: "200px",
            }}
            className="load_more_button"
          >
            {t("see_more")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TopContributers;
