import { Button, Form, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { YOUTURN_NEW_LOGO } from "@src/helpers/image_constants";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import { useDispatch } from "react-redux";
const OtpForm = (props) => {
  const { t } = useTranslation();
  const {
    otp,
    on_finish,
    sign_up,
    forgot_click,
    form,
    password_visible,
    toggle_password_visibility,
  } = props;
  const [login_popup, set_login_popup] = useState(false);
  const { loading: create_user_loading, error: login_error } =
    useDynamicSelector("login");
  const dispatch = useDispatch();
  const { loading } = useDynamicSelector("sign_up");
  useEffect(() => {
    if (login_error) {
      set_login_popup(true);
      setTimeout(() => {
        set_login_popup(false);
      }, 5000);
      setTimeout(() => {
        dispatch(dynamic_clear("login"));
      }, 7000);
    }
  }, [login_error]);
  return (
    <div className="form-container sign-in">
      {!otp && (
        <Form onFinish={on_finish} layout="vertical" form={form}>
          {/* <img
            style={{
              borderRadius: "9px",
              zIndex: 1,
              objectFit: "cover",

              height: "80px",
            }}
            src={YOUTURN_NEW_LOGO}
            alt="Profile"
            className="youturn_logo_mobile_view_only"
          /> */}
          <h1 className="sing_in_text">{t("login")}</h1>
          <Form.Item
            className="margin-bottom"
            name="username"
            rules={[{ required: true, message: "Email is required" }]}
          >
            <input
              type="text"
              placeholder="Username or Email"
              className="sign_up_input"
            />
          </Form.Item>

          <Form.Item
            className="margin-bottom"
            name="password"
            rules={[{ required: true, message: "Password is required" }]}
          >
            <div style={{ position: "relative" }}>
              <input
                type={password_visible ? "text" : "password"}
                placeholder="Password"
                className="sign_up_input"
              />
              {password_visible ? (
                <EyeInvisibleOutlined
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={toggle_password_visibility}
                />
              ) : (
                <EyeOutlined
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={toggle_password_visibility}
                />
              )}
            </div>
          </Form.Item>
          <text
            href=""
            onClick={forgot_click}
            style={{ textDecoration: "underline", cursor: "pointer" }}
            className="forgot_password_text"
          >
            {t("forgot_your_password")}
          </text>
          <Tooltip title={login_error?.message} open={login_popup}>
            <Button
              htmlType="submit"
              loading={create_user_loading || loading}
              className="submit_button"
            >
              {t("login")}
            </Button>
          </Tooltip>
          {/* <button type="submit">
            {create_user_loading ? (
              <div class="leap-frog">
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
              </div>
            ) : (
            )}
          </button> */}

          <div className="view-text">
            <text className="sing_in_text">{t("register_new_user")}</text>
            <test
              className="sing_in_text_underline"
              onClick={sign_up}
              style={{ marginLeft: "10px" }}
            >
              {t("sign_up")}
            </test>
          </div>
        </Form>
      )}
    </div>
  );
};

export default OtpForm;
