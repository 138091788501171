import React from "react";

const FeaturedFactCheckTitle = () => {
  return (
    <div className="featured_fact_check_title_container">
      <h2 className="featured_fact_check_title">Fact Check</h2>
    </div>
  );
};

export default FeaturedFactCheckTitle;
