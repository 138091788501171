import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import {
  amountFormatWithSymbol,
  initiate_request_razorpay_payment,
} from "@src/helpers/functions";
import AppreciationModal from "../../custom_components/appreciation_modal/appreciation_modal";
import UserDetailsFormModal from "../../home/home_donation.jsx/user_details_form_modal/user_details_form_modal";
import HomeDonationInput from "../../home/home_donation.jsx/home_donation_input";

const UserProfileDonate = (props) => {
  const { get_all_my_donation, show_donation } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [amount, set_amount] = useState("");
  const [selected_amount, set_selected_amount] = useState(null);
  const [manual_amount, set_manual_amount] = useState(false);
  const [payment_response, set_payment_response] = useState("");
  const [appreciation_modal_open, set_appreciation_modal_open] =
    useState(false);
  const [popper_status, set_popper_status] = useState(false);
  const [donation_modal_open, set_donation_modal_open] = useState(false);

  const amounts = [199, 499, 999, 1999];
  const other_amount = "others";

  const { status: create_donation_status, order_id } =
    useDynamicSelector("create_donation");
  const { mobile_number } = useDynamicSelector("user_entered_mobile_number");
  const payment_status = payment_response.razorpay_signature;

  useEffect(() => {
    if (create_donation_status === "Success") {
      set_selected_amount(null);
      set_manual_amount(false);
      dispatch(dynamic_clear("create_donation"));
    }
  }, [create_donation_status]);

  useEffect(() => {
    if (order_id) {
      initiate_request_razorpay_payment(
        order_id,
        set_payment_response,
        mobile_number
      );
      dispatch(dynamic_clear("create_donation"));
    }
  }, [order_id, mobile_number]);

  useEffect(() => {
    if (payment_status) {
      dispatch(dynamic_clear("user_entered_mobile_number"));
      set_appreciation_modal_open(true);
      set_popper_status(true);
      setTimeout(() => {
        set_appreciation_modal_open(false);
        set_popper_status(false);
        get_all_my_donation();
      }, 10000);
    }
  }, [payment_status]);

  const handle_click_amount = (amount) => {
    if (amount !== other_amount) {
      set_selected_amount(amount);
      set_amount(amount);
      set_manual_amount(false);
    } else {
      set_selected_amount(null);
      set_manual_amount(true);
    }
  };

  const handle_donate = () => {
    set_donation_modal_open(true);
  };

  const is_donate_button_enabled =
    (amount && amount !== "others") ||
    (amount === "others" && selected_amount > 0);
  return (
    <>
      {show_donation && (
        <div className="user_donation_container">
          <div className="home_donation_third_column_wrapper">
            <div className="home_amount_wrapper">
              {manual_amount ? (
                <div className="home_donation_input_wrapper">
                  <HomeDonationInput set_amount={set_amount} value={amount} />
                  <Button
                    className="cursor-pointer back_button"
                    onClick={() => {
                      set_manual_amount(false);
                      set_selected_amount(null);
                    }}
                  >
                    {t("Back")}
                  </Button>
                </div>
              ) : (
                <>
                  {amounts.map((amt) => (
                    <Button
                      key={amt}
                      className={`cursor-pointer ${
                        selected_amount === amt
                          ? "badge_selected"
                          : "badge_default"
                      }`}
                      onClick={() => handle_click_amount(amt)}
                    >
                      {amountFormatWithSymbol(amt)}
                    </Button>
                  ))}
                </>
              )}
              {!manual_amount && (
                <Button
                  className={`cursor-pointer ${
                    selected_amount === other_amount
                      ? "badge_selected"
                      : "badge_default"
                  }`}
                  onClick={() => {
                    set_manual_amount(true);
                    handle_click_amount(other_amount);
                  }}
                >
                  {t("Other")}
                </Button>
              )}
            </div>
            <div className="donate_inr_text">{t("donation_note")}</div>
            <Button
              disabled={!is_donate_button_enabled}
              className={
                !is_donate_button_enabled
                  ? "donate_button_disabled home_donate_button donation_button"
                  : "home_donate_button donation_button"
              }
              onClick={handle_donate}
            >
              {t("donate")}
            </Button>
          </div>
        </div>
      )}
      <AppreciationModal
        subtitle="We extend our sincere appreciation for your donation."
        message="Together, let's make an impact through your donations. Keep supporting us."
        popper_status={popper_status}
        appreciation_modal_open={appreciation_modal_open}
        set_appreciation_modal={set_appreciation_modal_open}
      />
      <UserDetailsFormModal
        modal_open={donation_modal_open}
        on_cancel={set_donation_modal_open}
        amount={selected_amount}
      />
    </>
  );
};

export default UserProfileDonate;
