import React from "react";

const FeaturedArticleTitle = () => {
  return (
    <div className="featured_video_title_container">
      <h2 className="featured_article_title">Articles</h2>
    </div>
  );
};

export default FeaturedArticleTitle;
