import React from 'react'
import {
    ProfilePic,
    UserAboutTextarea,
    UserAddressTextarea,
    UserMobileNumberInput,
    UserNameInput,
    UserProfileUpload,
} from "./user_form_inputs";
import { Button, Form, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const UserProfileUpdate = ({ onFinish, onCancel, form }) => {
    const { t } = useTranslation()
    return (
        <>
            <div className="report_modal_title_container">
                <text className="report_modal_title"> {t("profile_update")}</text>
            </div>
            <div className="report_modal_content">
                <Form onFinish={onFinish} form={form} layout='vertical'>
                    <UserNameInput />
                    {/* <ProfilePic /> */}
                    <UserMobileNumberInput />
                    <UserAboutTextarea />
                    <div className="report_modal_footer">
                        <Button onClick={onCancel} className="report_cancel_button">
                            {t("cancel")}
                        </Button>
                        <Button
                            htmlType="submit"
                            loading={false}
                            type="primary"
                            className="report_modal_submit_button"
                        >
                            <Typography style={{ color: "#FFFFFF" }}>
                                {t("update")}
                            </Typography>
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default UserProfileUpdate
