import { Skeleton } from "antd";
import React from "react";

const SingleFactCheckRatingSkeleton = () => {
  return (
    <div className="single_fact_check_rating_container">
      <Skeleton.Input className="single_fact_check_rating_skeleton" active />
    </div>
  );
};

export default SingleFactCheckRatingSkeleton;
