import Donation from "@src/views/ui/donation/donation";
import React from "react";
import { useTranslation } from "react-i18next";

function FightFakeNews() {
  const { t } = useTranslation()
  return (

    <div className="fight_fake_news_container">
      {/* <p className="fight_fake_news_head">
        {t("donate_top_content")}
      </p>
      <div className="fight_fake_news_div">
        <p className="fight_fake_news_hash">#</p>
        <p className="fight_fake_news_hash_val">{t("fight_against_fake_news")}</p>
      </div>
      <div className="fight_fake_news_second_div">
        <button className="figh_fake_news_button">{t("donate_now")}</button>
        <p className="figh_fake_news_note">{t("donation_note")}</p>
      </div> */}
      <Donation />
    </div>
  );
}

export default FightFakeNews;
