import React, { useEffect, useState } from "react";
import FeaturedUserContribution from "../components/user_contribution.jsx/featured_user_contribution";
import RecentlyAddedUserContribution from "../components/user_contribution.jsx/recently_added_user_contribution";
import UserContributionTrendingStories from "../components/user_contribution.jsx/user_contribution_trending_stories";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_user_contribution_list_query } from "@src/services/graphql/graphql_user_contribution";
import { retrieve_item } from "@src/helpers/cryptos";
import { useDynamicSelector } from "@src/services/redux";
import AllStoriesAndTopContributers from "../components/user_contribution.jsx/all_stories_and_top_contributers";
import JoinUs from "../components/custom_components/join_us/join_us";

const UserContribution = () => {
  const current_language = retrieve_item("language");
  const user_contribution_list_request = useAPIRequest(
    "get_user_contribution_list",
    get_user_contribution_list_query
  );

  const {
    items: user_contribution_list,
    loading: user_contribution_list_loading,
    pagination: user_contribution_pagination,
  } = useDynamicSelector("get_user_contribution_list");

  const [filtered_item, set_filtered_item] = useState([]);
  const [featured_user_contribution, set_featured_user_contribution] = useState(
    []
  );
  const [recently_added_top_fact_check, set_recently_added_top_fact_check] =
    useState([]);
  const [recently_added_small_card_list, set_recently_added_small_card_list] =
    useState([]);
  const [all_stories_list, set_all_stories_list] = useState([]);
  const [page_limit, set_page_limit] = useState(10);
  const [page_number, set_page_number] = useState(1);

  useEffect(() => {
    if (user_contribution_list && user_contribution_list?.length > 0) {
      set_all_stories_list([...user_contribution_list]);
    }
  }, [user_contribution_list]);

  useEffect(() => {
    set_featured_user_contribution(filtered_item?.slice(0, 1));
    set_recently_added_top_fact_check(filtered_item?.slice(1, 2));
    set_recently_added_small_card_list(filtered_item?.slice(2, 6));
    // if (user_contribution_pagination?.total_count > 6) {
    //   set_all_stories_list();
    //   filtered_item?.slice(6, user_contribution_pagination?.total_count);
    // } else {
    //   set_all_stories_list(filtered_item?.slice(0, 5));
    // }
  }, [filtered_item, user_contribution_pagination]);

  useEffect(() => {
    get_user_contribution_list();
  }, [page_limit, page_number]);

  useEffect(() => {
    if (user_contribution_list?.length > 0) {
      set_filtered_item([...user_contribution_list]);
    }
  }, [user_contribution_list]);

  const get_user_contribution_list = () => {
    user_contribution_list_request(
      {
        language_id: current_language?.id,
        page_limit: page_limit,
        page_number: page_number,
      },
      true
    );
  };

  const handle_page_limit = () => {
    set_page_limit(page_limit + 4);
  };

  return (
    <div>
      <FeaturedUserContribution
        featured_user_contribution={featured_user_contribution}
      />
      <RecentlyAddedUserContribution />
      <UserContributionTrendingStories />
      <AllStoriesAndTopContributers
        all_stories_list={all_stories_list}
        filtered_item={filtered_item}
        handle_page_limit={handle_page_limit}
        user_contribution_list_loading={user_contribution_list_loading}
        recently_added_small_card_list={recently_added_small_card_list}
      />
      <JoinUs />
    </div>
  );
};

export default UserContribution;
