// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .featured_article_image_component {
    width: 60% !important;
  }
  .featured_article_thumbnail_overlay_content {
    height: 120px;
    width: 69% !important;
  }
  .featured_article_view_count {
    font-size: 12px;
  }
  .featured_article_thumbnail_overlay_name_and_date {
    font-size: 12px !important;
  }
  .featured_article_button {
    width: 90px;
    /* height: 22px; */
    font-size: 9px;
  }
  .featured_story_article_title {
    font-size: 18px;
  }
  .featured_article_sub_title {
    font-size: 15px;
  }
  .featured_article_title {
    width: 90%;
  }
  .recommended_story_mobile_main_container {
    display: none;
  }
  .featured_story_continue_reading_text {
    font-size: 13px;
  }
  .featured_article_image_component_title_ta {
    font-size: 12px;
  }
  .featured_story_article_title_ta {
    font-size: 15px;
  }
  .featured_article_sub_title_ta {
    font-size: 13px;
    /* line-height: 25px; */
  }
  .featured_fact_check_image_component_title {
    font-size: 14px;
  }
  .featured_article_image_component_title {
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_1024_1439/screen_1024_1439_article.css"],"names":[],"mappings":"AAAA;EACE;IACE,qBAAqB;EACvB;EACA;IACE,aAAa;IACb,qBAAqB;EACvB;EACA;IACE,eAAe;EACjB;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,WAAW;IACX,kBAAkB;IAClB,cAAc;EAChB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,UAAU;EACZ;EACA;IACE,aAAa;EACf;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;IACf,uBAAuB;EACzB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":["@media only screen and (min-width: 1024px) and (max-width: 1439px) {\r\n  .featured_article_image_component {\r\n    width: 60% !important;\r\n  }\r\n  .featured_article_thumbnail_overlay_content {\r\n    height: 120px;\r\n    width: 69% !important;\r\n  }\r\n  .featured_article_view_count {\r\n    font-size: 12px;\r\n  }\r\n  .featured_article_thumbnail_overlay_name_and_date {\r\n    font-size: 12px !important;\r\n  }\r\n  .featured_article_button {\r\n    width: 90px;\r\n    /* height: 22px; */\r\n    font-size: 9px;\r\n  }\r\n  .featured_story_article_title {\r\n    font-size: 18px;\r\n  }\r\n  .featured_article_sub_title {\r\n    font-size: 15px;\r\n  }\r\n  .featured_article_title {\r\n    width: 90%;\r\n  }\r\n  .recommended_story_mobile_main_container {\r\n    display: none;\r\n  }\r\n  .featured_story_continue_reading_text {\r\n    font-size: 13px;\r\n  }\r\n  .featured_article_image_component_title_ta {\r\n    font-size: 12px;\r\n  }\r\n  .featured_story_article_title_ta {\r\n    font-size: 15px;\r\n  }\r\n  .featured_article_sub_title_ta {\r\n    font-size: 13px;\r\n    /* line-height: 25px; */\r\n  }\r\n  .featured_fact_check_image_component_title {\r\n    font-size: 14px;\r\n  }\r\n  .featured_article_image_component_title {\r\n    font-size: 14px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
