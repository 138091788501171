import { Skeleton } from "antd";
import React from "react";

const UserContributionListSkeletonLoading = () => {
  return (
    <div className="user_contribution_list_container_skeleton">
      <div className="user_contribution_list_skeleton">
        {Array.from({ length: 4 }).map((_, index) => (
          <div key={index} className="user_contribution_card">
            <Skeleton className="user_contribution_card_skeleton_width" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserContributionListSkeletonLoading;
