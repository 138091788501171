import React, { useEffect, useState } from "react";
import VideoList from "@src/views/components/videos/video_list";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import FeaturedVideoBackGroundContainer from "../components/videos/featured_video_back_ground_container";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_video_list_query } from "@src/services/graphql/graphql_videos";
import { useDynamicSelector } from "@src/services/redux";
import FeaturedVideoTitle from "../components/videos/featured_video_title";
import Breadcrumbs from "../components/breadcrumb/breadcrumb";
import { ROUTES } from "@src/route/my_routes";
import JoinUs from "../components/custom_components/join_us/join_us";

const Videos = () => {
  const { t } = useTranslation();
  const [page_limit, set_page_limit] = useState(7);

  const { loading: video_list_loading } = useDynamicSelector("get_video_list");

  let video_list_request = useAPIRequest(
    "get_video_list",
    get_video_list_query
  );

  useEffect(() => {
    get_video_list();
  }, [page_limit]);

  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },
    {
      path: "",
      breadcrumb_name: "Videos",
    },
  ];

  const get_video_list = () => {
    video_list_request(
      {
        page_limit: page_limit,
      },
      true
    );
  };

  const handle_see_more_videos = () => {
    set_page_limit(page_limit + 6);
  };

  return (
    <div className="video_container">
      <Breadcrumbs items={items} />
      <FeaturedVideoTitle />
      <FeaturedVideoBackGroundContainer />
      <VideoList />
      <div className="see_more_container">
        <Button
          loading={video_list_loading}
          className="see_more_videos"
          onClick={handle_see_more_videos}
        >
          {t("see_more")}
        </Button>
      </div>
      <JoinUs />
    </div>
  );
};

export default Videos;
