import { Skeleton } from "antd";
import React from "react";

const FeaturedFactCheckContentSkeleton = () => {
  return (
    <div className="featured_story_content_skeleton">
      <div className="featured_fact_check_content_title_wrapper">
        <Skeleton active paragraph={{ rows: 2 }} />
      </div>
      <div className="featured_fact_check_content_sub_title_wrapper">
        <Skeleton active paragraph={{ rows: 3 }} />
      </div>
      <div className="featured_fact_check_continue_reading_wrapper">
        <Skeleton.Input active />
      </div>
    </div>
  );
};

export default FeaturedFactCheckContentSkeleton;
