import { gql } from "@apollo/client";
export const get_otp_query = gql`
  query get_otp($mobile: String!, $email: String!) {
    get_otp(mobile: $mobile, email: $email) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_verify_mobile_otp_query = gql`
  query verify_otp($mobile: String!, $email: String!, $otp: String!) {
    verify_otp(mobile: $mobile, email: $email, otp: $otp) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
