import { useDynamicSelector } from "@src/services/redux";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

const SingleFactCheckSpreadNews = () => {
  const { t } = useTranslation();
  const { spread_news } = useDynamicSelector("get_fact_check");
  console.log("spread_news", spread_news);

  return (
    <div className="single_fact_check_spread_news_container">
      <div className="single_fact_details_title">
        <h5>{t("claim")}</h5>
      </div>
      {spread_news ? ReactHtmlParser(spread_news) : ""}
    </div>
  );
};
export default SingleFactCheckSpreadNews;
