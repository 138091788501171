import { EyeOutlined } from "@ant-design/icons";
import {
  countFormat,
  date_format,
  get_status_tag,
} from "@src/helpers/functions";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_claim_list_query } from "@src/services/graphql/graphql_user_profile";
import { useDynamicSelector } from "@src/services/redux";
import { Card, Empty, Pagination, Skeleton } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ClaimListMobile = () => {
  const [page_limit, set_page_limit] = useState(10);
  const [page_number, set_page_number] = useState(1);
  const { t } = useTranslation();
  const get_claims_request = useAPIRequest(
    "get_claim_list",
    get_claim_list_query
  );
  const {
    items: claims_list,
    pagination: claim_pagination,
    loading: claim_loading,
  } = useDynamicSelector("get_claim_list");

  const fetch_data = (value, value1) => {
    get_claims_request(
      {
        page_limit: value1,
        page_number: value,
      },
      true
    );
  };

  const MobileListCard = (props) => {
    const { created_date_time, description, status, ref_url, attachment } =
      props;
    return (
      <Card style={{ marginBottom: "15px" }}>
        <table>
          <tr>
            <th>{t("date")}</th>
            <td>{date_format(created_date_time)}</td>
          </tr>
          <tr>
            <th>{t("description")}</th>
            <td>{description || "-"}</td>
          </tr>
          <tr>
            <th>{t("link")}</th>
            <td>
              {ref_url ? (
                <a
                  href={ref_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="user_claim_url"
                >
                  {t("visit_link")}
                </a>
              ) : (
                "-"
              )}
            </td>
          </tr>

          <tr>
            <th>{t("status")}</th>
            <td>{get_status_tag(status)}</td>
          </tr>

          <tr>
            <th>{t("attachment")}</th>
            <td>
              <EyeOutlined
                style={{ cursor: "pointer", color: "#00adee" }}
                onClick={() => window.open(attachment, "_blank")}
              />
            </td>
          </tr>
        </table>
      </Card>
    );
  };

  return (
    <div className="donation_mobile_main_container">
      <h2>{t("claims")}</h2>
      {claim_loading ? (
        Array.from({ length: 2 }, (_, index) => (
          <Card style={{ marginBottom: "15px" }}>
            <Skeleton key={index} active paragraph={{ rows: 2 }} />
          </Card>
        ))
      ) : claims_list?.length > 0 ? (
        <>
          <div className="donation_mobile_container">
            {claims_list?.map((donation) => {
              return <MobileListCard {...donation} />;
            })}
          </div>
          <div className="pagination_mobile_container">
            <Pagination
              total={claim_pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetch_data}
              showTotal={(total, range) => `Total ${countFormat(total)} items`}
            />
          </div>
        </>
      ) : (
        <div className="no_donation_container">
          {" "}
          <Empty description={t("no_claims")} />
        </div>
      )}
    </div>
  );
};

export default ClaimListMobile;
