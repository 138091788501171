import { useDynamicSelector } from "@src/services/redux";
import StoryCard from "@src/views/ui/story_card/story_card";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import FactCheckPartyWiseFirstColumnMobileView from "../../fact_check/fact_check_party_wise/fact_check_party_wise_mobile_view.jsx/fact_check_party_wise_first_column_mobile_view";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";

const RecommendedStoriesMobileView = () => {
  const { t } = useTranslation();

  const [items, set_items] = useState([]);
  const {
    items: recommended_story_list,
    loading: recommended_story_list_loading,
  } = useDynamicSelector("get_recommended_story_list");

  useEffect(() => {
    if (recommended_story_list?.length > 0) {
      set_items(recommended_story_list);
    }
  }, [recommended_story_list]);


  return (
    <div className="recommended_story_mobile_container">
      <h6 className="party_wise_updates_mobile_text">
        {t("recommended_stories")}
      </h6>
      {!recommended_story_list_loading && (
        <div className="recommended_story_mobile_first_container">
          <FactCheckPartyWiseFirstColumnMobileView
            list={items?.[0]?.fact_check || items?.[0]?.article}
            type={items?.[0]?.fact_check ? "fact_check" : "article"}
          />
        </div>
      )}
      {recommended_story_list_loading && (
        <div className="recommended_story_mobile_middle_container_loading">
          {Array.from({ length: 6 }, (_, index) => (
            <div
              className="home_user_contribution_card_container"
              style={{ padding: "10px" }}
            >
              <Skeleton active paragraph={{ rows: 2 }} title={false} />
            </div>
          ))}
        </div>
      )}

    </div>
  );
};

export default RecommendedStoriesMobileView;
