import React, { useState } from "react";
import HeaderMenus from "./header_menus";
import HeaderSearch from "./header_search";
import HeaderLogo from "./header_logo";
import HeaderDrawerLogo from "./header_drawer_logo";
import DarkMode from "../../contrast/dark_mode";
import HeaderSearchMobileView from "./header_search_mobile_view";

const HeaderBar = ({ handle_show_search }) => {
  return (
    <div className="user_layout_header ">
      <div className="user_layout_container">
        <HeaderLogo />
        <HeaderSearch handle_show_search={handle_show_search} />
        <HeaderMenus />
        <HeaderDrawerLogo />
      </div>
    </div>
  );
};

export default HeaderBar;
