import React from "react";
import { useTranslation } from "react-i18next";

const Notes = () => {
  const { t } = useTranslation();
  return (
    <div className="ifcn_certificates_notes">
      <h4>{t("notes")}</h4>
      <text className="notes_text_size">
        If you believe we are violating the IFCN Code of Principles, you can
        inform the International Fact-Checking Network
      </text>
      <a
        href="https://ifcncodeofprinciples.poynter.org/complaints"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here
      </a>
    </div>
  );
};

export default Notes;
