import {
  ensure_https,
  format_views_count,
  get_days_since_published_text,
  split_tamil_english_words,
} from "@src/helpers/functions";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_video_list_query } from "@src/services/graphql/graphql_videos";
import { useDynamicSelector } from "@src/services/redux";
import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import VideoListSkeletonLoading from "./video_list_skeleton_loading";
import { YOUTUBE_LOGO } from "@src/helpers/image_constants";

const VideoList = () => {
  const { t } = useTranslation();
  const [filtered_item, set_filtered_item] = useState([]);

  const { items: video_list, loading: video_list_loading } =
    useDynamicSelector("get_video_list");
  const { dark_mode } = useDynamicSelector("dark_mode");

  useEffect(() => {
    if (video_list && video_list.length > 0) {
      set_filtered_item([...video_list]);
    }
  }, [video_list]);

  const go_to_youtube_video = (video_item) => {
    window.open(
      `https://www.youtube.com/watch?v=${video_item?.video_id}`,
      "_blank"
    );
  };

  const get_days_since_published_text = (daysSincePublished) => {
    if (daysSincePublished === 0) {
      return t("Today");
    }

    const years = Math.floor(daysSincePublished / 365);
    const months = Math.floor((daysSincePublished % 365) / 30);
    const days = daysSincePublished % 30;
    if (years > 0) {
      return `${years} ${years === 1 ? t("year") : t("years")} ${t("ago")}`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? t("month") : t("months")} ${t("ago")}`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? t("day") : t("days")} ${t("ago")}`;
    } else {
      return t("Today");
    }
  };

  return (
    <>
      <div className="video_list_container">
        <div className="video_list">
          {filtered_item?.slice(1).map((item, index) => (
            <div
              key={item?.video_id}
              className="video_card"
              onClick={() => go_to_youtube_video(item)}
            >
              {/* <div className="play_button_overlay_content"> */}
              <img
                src={ensure_https(item?.thumbnails)}
                alt={item?.title || "Video Thumbnail"}
                className="video_thumbnail"
              />
              <div className="video_thumbnail_logo_overlay">
                <img
                  src={YOUTUBE_LOGO}
                  alt={item?.title || "Video Thumbnail"}
                  style={{ width: "50px" }}
                />
              </div>
              {/* </div> */}
              <div className="video_title_container">
                <text
                  className={`video_title ${
                    dark_mode ? "dark_mode_video_text" : ""
                  }`}
                >
                  {split_tamil_english_words(item?.title, "_", 7)}
                </text>
                <text
                  className={`viewed_count_in_videos ${
                    dark_mode ? "dark_mode_video_text" : ""
                  }`}
                >{`${format_views_count(item?.views)} ${t(
                  "views"
                )} | ${get_days_since_published_text(
                  Number(item?.days_since_published)
                )}`}</text>
              </div>
            </div>
          ))}
        </div>
      </div>

      {video_list_loading && <VideoListSkeletonLoading />}
    </>
  );
};

export default VideoList;
