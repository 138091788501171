import { countFormat, date_format } from "@src/helpers/functions";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_my_donation_query } from "@src/services/graphql/graphql_donation";
import { useDynamicSelector } from "@src/services/redux";
import { Card, Empty, Pagination, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const DonationListMobile = () => {
  const [page_limit, set_page_limit] = useState(10);
  const [page_number, set_page_number] = useState(1);
  const { t } = useTranslation();
  const get_donations_request = useAPIRequest(
    "get_all_donations",
    get_my_donation_query
  );
  const {
    items: donation_list,
    pagination: donation_pagination,
    loading: donation_loading,
  } = useDynamicSelector("get_all_donations");

  const fetch_data = (value, value1) => {
    get_donations_request(
      {
        page_limit: value1,
        page_number: value,
      },
      true
    );
  };

  const MobileListCard = (props) => {
    const { created_date_time, order_ref, amount } = props;
    return (
      <Card style={{ marginBottom: "15px" }}>
        <table>
          <tr>
            <th>{t("date")}</th>
            <td>{date_format(created_date_time)}</td>
          </tr>
          <tr>
            <th>{t("reference_id")}</th>
            <td>{order_ref}</td>
          </tr>
          <tr>
            <th>{t("amount")}</th>
            <td>{amount || 0}</td>
          </tr>
        </table>
      </Card>
    );
  };

  return (
    <div className="donation_mobile_main_container">
      <h2>{t("donations")}</h2>
      {donation_loading ? (
        Array.from({ length: 2 }, (_, index) => (
          <Card style={{ marginBottom: "15px" }}>
            <Skeleton key={index} active paragraph={{ rows: 2 }} />
          </Card>
        ))
      ) : donation_list?.length > 0 ? (
        <>
          <div className="donation_mobile_container">
            {donation_list?.map((donation) => {
              return <MobileListCard {...donation} />;
            })}
          </div>
          <div className="pagination_mobile_container">
            <Pagination
              total={donation_pagination?.total_count}
              showSizeChanger={true}
              page_number={page_number}
              hideOnSinglePage={false}
              onChange={fetch_data}
              showTotal={(total, range) => `Total ${countFormat(total)} items`}
            />
          </div>
        </>
      ) : (
        <div className="no_donation_container">
          <Empty description={t("no_donations")} />
        </div>
      )}
    </div>
  );
};

export default DonationListMobile;
