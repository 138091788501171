import { gql } from "@apollo/client";

export const submit_feedback_mutation=gql`
mutation create_user_query($json: create_user_query_input){
    create_user_query(json:$json){
     id
     status
     error{
       status_code
       message
     }
   }
   }`