import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_party_wise_fact_check_list_query } from "@src/services/graphql/graphql_fact_check";
import {
  dynamic_clear,
  dynamic_set,
  useDynamicSelector,
} from "@src/services/redux";
import { Col, Input, Modal, Radio, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoOptionsOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";

const FactCheckPartyTagMobileView = () => {
  const { t } = useTranslation();
  const { items: party_tag_list, loading: party_tag_list_loading } =
    useDynamicSelector("get_party_tag_list");
  const current_language = retrieve_item("language");
  const [is_modal_visible, set_is_modal_visible] = useState(false);
  const [selected_party_id, set_selected_party_id] = useState(null);
  const dispatch = useDispatch();
  let party_wise_fact_check_list_request = useAPIRequest(
    "get_party_wise_fact_check_list",
    get_party_wise_fact_check_list_query
  );
  const { items: featured_fact_check } = useDynamicSelector(
    "get_featured_fact_check"
  );

  const hover_effect = (e) => {
    e.target.style.fontWeight = "bold";
    e.target.style.color = "#00adee";
  };

  const handle_radio_change = (e) => {
    dispatch(dynamic_set("selected_party_tag", { id: e?.target?.value }));
    set_selected_party_id(e?.target?.value);
  };
  const show_modal = () => {
    set_is_modal_visible(true);
  };

  const handle_cancel = () => {
    set_is_modal_visible(false);
  };

  const handle_ok = () => {
    dispatch(dynamic_clear("party_wise_fact_checks"));
    get_party_wise_fact_check_list();
    set_is_modal_visible(false);
  };
  const get_party_wise_fact_check_list = () => {
    party_wise_fact_check_list_request(
      {
        page_number: 1,
        page_limit: 5,
        json: {
          language_id: current_language?.id,
          party_tag: selected_party_id,
          exclude_id: featured_fact_check?.[0]?.id || "",
        },
      },
      true
    );
  };

  return (
    <div className="fact_check_mobile_title_container">
      <h6 className="party_wise_updates_mobile_text">
        {t("party_wise_updates")}
      </h6>
      <div onClick={show_modal} onMouseEnter={hover_effect}>
        <Input
          placeholder={t("select_party")}
          value={
            selected_party_id
              ? party_tag_list?.find((party) => party?.id === selected_party_id)
                  ?.name
              : ""
          }
          style={{ width: "100%" }}
          suffix={<IoOptionsOutline style={{ color: "#1890ff" }} />}
          readOnly
        />
      </div>
      <Modal
        title={t("select_party")}
        open={is_modal_visible}
        onOk={handle_ok}
        onCancel={handle_cancel}
        confirmLoading={party_tag_list_loading}
      >
        <Radio.Group
          onChange={handle_radio_change}
          value={selected_party_id}
          style={{ width: "100%" }}
        >
          <Row gutter={[16, 16]}>
            {party_tag_list &&
              party_tag_list?.map((option) => (
                <Col key={option.id} span={12}>
                  <Radio value={option.id}>{option.name}</Radio>
                </Col>
              ))}
          </Row>
        </Radio.Group>
      </Modal>
    </div>
  );
};

export default FactCheckPartyTagMobileView;
