import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const FooterSecondColumn = () => {
  const { t } = useTranslation();

  const quick_access = [
    {
      name: "Fact Check",
      url: "/fact-check",
    },
    {
      name: "Videos",
      url: "/videos",
    },
    {
      name: "Articles",
      url: "/article",
    },
    {
      name: "User Contributions",
      url: "/user-contribution",
    },
    {
      name: "About Us",
      url: "/about-us",
    },
    {
      name: "Contact Us",
      url: "/contact-us",
    },
    {
      name: "Privacy Policy",
      url: "/privacy-policy",
    },
    {
      name: "RSS Feed",
      url: "/rss",
      new_tab: true,
    },
  ];

  return (
    <div className="footer_second_page_column_container">
      <ul className="quick_access_label_container">
        <li className="main_header">{t("quick_access")}</li>

        {quick_access.map((link, index) => (
          <li key={index} className="quick_access_label">
            <Link
              to={link.url}
              className="footer_page_menus"
              target={link?.new_tab ? "_blank" : ""}
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterSecondColumn;
