import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const NameInput = () => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name="first_name"
      rules={[
        {
          required: true,
          message: t("required_name"),
        },
      ]}
    >
      <Input
        className="feedback-input"
        placeholder={t("name")}
        // style={{
        //   color: text_color,
        // }}
      />
    </Form.Item>
  );
};

export default NameInput;
