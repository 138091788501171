import { Skeleton } from "antd";
import React from "react";

const FeaturedVideoComponentSkeleton = () => {
  return (
    <div className="featured_article_image_component featured_story_image_component">
      <Skeleton.Image
        className="featured_video_image featured_story_image_skeleton"
        active
      />
    </div>
  );
};

export default FeaturedVideoComponentSkeleton;
