import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_all_reason_query } from "@src/services/graphql/graphql_single_fact_check";
import {
  dynamic_clear,
  dynamic_set,
  useDynamicSelector,
} from "@src/services/redux";
import { Button, Form, Modal, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import TextArea from "antd/es/input/TextArea";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { ROUTES } from "@src/route/my_routes";
import { create_report_mutation } from "@src/services/graphql/graphql_single_article";

const { Option } = Select;

const ReportModal = ({ model_open, article_id, fact_check_id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user_id = retrieve_item("user_id");
  const language = retrieve_item("language");
  const [form] = Form.useForm();

  const {
    status: create_report_status,
    error: create_report_error,
    loading: create_report_loading,
  } = useDynamicSelector("report_mutation");

  const { items: reasons } = useDynamicSelector("get_all_reason");

  const create_report_request = useAPIRequest(
    "report_mutation",
    create_report_mutation
  );

  const get_all_reason_request = useAPIRequest(
    "get_all_reason",
    get_all_reason_query
  );

  const create_report = (values) => {
    create_report_request({
      json: article_id
        ? {
            article_id: article_id,
            reason_id: values?.reason,
            description: values?.description,
          }
        : {
            fact_check_id: fact_check_id,
            reason_id: values?.reason,
            description: values?.description,
          },
    });
  };

  const get_all_reason = () => {
    get_all_reason_request({ language_id: language?.id });
  };
  useEffect(() => {
    if (create_report_status === "Success") {
      // toast.success("Report submitted");
      dispatch(dynamic_clear("report_mutation"));
      on_close();
      form.resetFields();
    } else if (create_report_error) {
      dispatch(dynamic_clear("report_mutation"));
      // toast.error(create_report_error?.message);
    }
  }, [create_report_status, create_report_error]);

  useEffect(() => {
    get_all_reason();
  }, []);

  const on_finish = (values) => {
    if (user_id) {
      create_report(values);
    } else {
      Navigate(`${ROUTES.ADMIN_LOGIN}`);
    }
  };

  const on_close = () => {
    dispatch(dynamic_set("report_modal_state", { is_report_modal: false }));
  };

  return (
    <Modal
      // title={t("report")}
      className="report_modal"
      open={model_open}
      onCancel={on_close}
      closeIcon={<IoMdClose className="report_modal_close_icon" />}
      destroyOnClose={true}
      footer={null}
    >
      <div className="report_modal_title_container">
        <text className="report_modal_title"> {t("report")}</text>
      </div>
      <div className="report_modal_content">
        <Form onFinish={on_finish} layout="vertical" form={form}>
          <Form.Item
            name="reason"
            label={t("reason")}
            rules={[{ required: true, message: t("please_select_reason") }]}
          >
            <Select style={{ width: "100%" }}>
              {reasons?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="description"
            label={t("description")}
            rules={[{ required: true, message: t("please_enter_description") }]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <div className="report_modal_footer">
            {/* <Button onClick={on_close} className="report_cancel_button">
              {t("cancel")}
            </Button> */}
            <Button
              htmlType="submit"
              loading={create_report_loading}
              type="primary"
              className="report_modal_submit_button"
            >
              <Typography style={{ color: "#FFFFFF" }}>
                {t("submit")}
              </Typography>
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ReportModal;
