import HomeArticleSkeleton from '@src/views/components/home/home_articles/home_article_skeleton'
import HomeSlotCardSkeleton from '@src/views/components/home/home_slots/home_slot_card_skeleton'
import { Skeleton } from 'antd'
import React from 'react'

const FactCheckPartyWiseFirstColumnSkeletonLoading = () => {
  return (
    <div className="home_article_card_skeleton_container party_wise_first_card_loading_container " style={{ display: "block" }}>
      <div className="w-100">
        <Skeleton.Image
          className="w-100"
          active
        />
      </div>
      <div className="home_article_card_details_skeleton_wrapper fact_check_details_skeleton_wrapper ">
        <Skeleton.Input className="home_article_card_skeleton_title w-100" active />
        <Skeleton.Input
          className="home_article_card_skeleton_subtitle w-100"
          active
        />
        <Skeleton.Input
          className="home_article_card_skeleton_author_details w-100"
          active
          size="small"
        />
        <Skeleton.Input
          className="home_article_card_skeleton_continue_read w-100"
          active
        />
      </div>
    </div>
  )
}

export default FactCheckPartyWiseFirstColumnSkeletonLoading
