import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_recent_fact_check_list_query } from "@src/services/graphql/graphql_single_fact_check";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import FactCheckPartyWiseSecondColumnSkeletonLoading from "../fact_check/fact_check_party_wise/fact_check_party_wise_loading/fact_check_party_wise_second_column_loading";
import VerticalStoryCard from "@src/views/ui/vertical_story_card";
import RecentFactCheckCard from "./recent_fact_check_card";
import RecentFactCheckSkeleton from "./single_fact_check_report_donation/recent_fact_check_skeleton";

const RecentFactCheckList = () => {
  const language = retrieve_item("language");
  const { t } = useTranslation();

  const {
    items: recent_fact_check_list,
    loading: recent_fact_check_list_loading,
  } = useDynamicSelector("get_recent_fact_check_list");
  const fact_check = useDynamicSelector("get_fact_check");

  let recent_fact_check_list_request = useAPIRequest(
    "get_recent_fact_check_list",
    get_recent_fact_check_list_query
  );

  useEffect(() => {
    get_recent_fact_check_list();
  }, [fact_check]);

  const get_recent_fact_check_list = () => {
    recent_fact_check_list_request(
      {
        page_number: 4,
        page_limit: 4,
        language_id: language?.id,
        exclude_id: fact_check?.id || "",
      },
      true
    );
  };
  return (
    <div className="recent_fact_check_banner">
      <div className="recent_fact_check_title_container">
        <h4>{t("recently_added_fact_check")}</h4>
      </div>

      <div className="recent_fact_check_container">
        {recent_fact_check_list_loading ? (
          <>
            {recent_fact_check_list?.map((_, index) => (
              <RecentFactCheckSkeleton key={index} />
            ))}
          </>
        ) : (
          recent_fact_check_list?.map((factCheck, index) => (
            <RecentFactCheckCard key={index} list={factCheck} />
          ))
        )}
      </div>
    </div>
  );
};

export default RecentFactCheckList;
