import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_party_wise_fact_check_list_query } from "@src/services/graphql/graphql_fact_check";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FactCheckPartyWiseFirstColumn from "./fact_check_party_wise/fact_check_party_wise_first_column";
import FactCheckPartyWiseThirdColumn from "./fact_check_party_wise/fact_check_party_wise_third_column";
import { useTranslation } from "react-i18next";
import FactCheckPartyWiseMobileView from "./fact_check_party_wise/fact_check_party_wise_mobile_view.jsx/fact_check_party_wise_mobile_view";
import { Empty } from "antd";

const PartyWiseFactCheck = () => {
  const current_language = retrieve_item("language");
  const { items: party_wise_fact_check_list } = useDynamicSelector(
    "get_party_wise_fact_check_list"
  );
  const { items: featured_fact_check } = useDynamicSelector(
    "get_featured_fact_check"
  );

  let party_wise_fact_check_list_request = useAPIRequest(
    "get_party_wise_fact_check_list",
    get_party_wise_fact_check_list_query
  );

  const get_party_wise_fact_check_list = () => {
    party_wise_fact_check_list_request(
      {
        page_number: 1,
        page_limit: 10,
        json: {
          language_id: current_language?.id,
          exclude_id: featured_fact_check?.[0]?.id || "",
        },
      },
      true
    );
  };
  useEffect(() => {
    get_party_wise_fact_check_list();
  }, [featured_fact_check?.[0]?.id]);

  // useEffect(() => {
  //   if (party_wise_fact_check_list && party_wise_fact_check_list.length > 0) {
  //     set_filtered_item_party_wise([...party_wise_fact_check_list]);
  //   } else {
  //     set_filtered_item_party_wise([]);
  //   }
  // }, [party_wise_fact_check_list]);

  return (
    <div className="fact_check_party_main_container">
      <div className="fact_check_party_container">
        <FactCheckPartyWiseFirstColumn />
        <FactCheckPartyWiseThirdColumn />
      </div>
      <div className="home_fact_check_mobile_view_wrapper">
        <FactCheckPartyWiseThirdColumn />
        <FactCheckPartyWiseMobileView />
      </div>
    </div>
  );
};

export default PartyWiseFactCheck;
