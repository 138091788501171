import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import HomeDonationUserDetailsForm from "../home_donation_user_details_form";

const UserDetailsFormModal = (props) => {
  const { modal_open, on_cancel, amount } = props;
  const { t } = useTranslation();

  return (
    <Modal
      open={modal_open}
      onCancel={() => on_cancel(false)}
      footer={null} // Remove default footer buttons
      closable={false} // Remove the default close button
      // width={400}
      className="custom_modal" // Custom class for additional styling
    >
      <div className="modal_header">
        <h2>{t("user_details")}</h2>
      </div>
      <HomeDonationUserDetailsForm modal_close={on_cancel} amount={amount}modal_open={modal_open} />
    </Modal>
  );
};

export default UserDetailsFormModal;
