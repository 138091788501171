import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const Email = () => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name="email"
      rules={[
        {
          type: "email",
          required: true,
          message: t("required_email"),
        },
      ]}
    >
      <Input className="feedback-input" placeholder={t("email")} />
    </Form.Item>
  );
};

export default Email;
