import { handleError } from "@apollo/client/link/http/parseAndCheckHttpResponse";
import { ROUTES } from "@src/route/my_routes";
import { Button, Divider, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HomeUserContributionButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handle_redirect = () => {
    navigate(ROUTES.USER_CONTRIBUTION);
  };

  return (
    <Row className="exposing_fake_news_divider_container">
      <Row className="exposing_fake_news_divider_wrapper">
        <Divider className="exposing_fake_news_divider">
          <Button
            className="exposing_fake_news_button"
            onClick={handle_redirect}
          >
            {t("see_all")}
          </Button>
        </Divider>
      </Row>
    </Row>
  );
};

export default HomeUserContributionButton;
