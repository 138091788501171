import React from "react";
import { useTranslation } from "react-i18next";

import FactCheckPartyWiseSecondColumn from "./fact_check_party_wise_second_column";
import { useDynamicSelector } from "@src/services/redux";
import FactCheckPartyTags from "../fact_check_party_tags";
import FactCheckPartyWiseCard from "./fact_check_main_card";
import FactCheckPartyWiseFirstColumnSkeletonLoading from "./fact_check_party_wise_loading/fact_check_party_wise_first_column_loading";
import FactCheckPartyWiseSecondColumnSkeletonLoading from "./fact_check_party_wise_loading/fact_check_party_wise_second_column_loading";
import HomeSlotCard from "../../home/home_slots/home_slot_card";
import SlotCard from "@src/views/ui/slot_card";
import { Empty } from "antd";

const FactCheckPartyWiseFirstColumn = () => {
  const { t } = useTranslation();
  const {
    items: party_wise_fact_check_list,
    loading: party_wise_fact_check_list_loading,
  } = useDynamicSelector("get_party_wise_fact_check_list");

  return (
    <div className="home_user_contributed_story_first_column_main_container">
      <h1 className="fact_check_party_wise_module_title">
        {t("party_wise_updates")}
      </h1>
      <FactCheckPartyTags />

      {party_wise_fact_check_list?.length <= 0 ? (
        <div className="party_wise_no_data">
          <Empty description="No data" />
        </div>
      ) : (
        <div className="display-flex flex-row justify-content-between ">
          <div className="fact_check_main_card_box ">
            {!party_wise_fact_check_list_loading &&
            party_wise_fact_check_list?.[0]?.id ? (
              <SlotCard
                {...party_wise_fact_check_list?.[0]}
                type={"fact_check"}
              />
            ) : (
              <FactCheckPartyWiseFirstColumnSkeletonLoading />
            )}
          </div>
          <div className="fact_check_second_card_box">
            {party_wise_fact_check_list_loading ? (
              <FactCheckPartyWiseSecondColumnSkeletonLoading />
            ) : (
              <FactCheckPartyWiseSecondColumn
                part_wise_fact_checks={party_wise_fact_check_list?.slice(1, 5)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FactCheckPartyWiseFirstColumn;
