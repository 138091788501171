import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import { get_recommended_story_list_query } from "@src/services/graphql/graphql_articles";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RecommendedStoriesDesktopView from "./recommended_stories/recommended_stories_desktop_view";
import RecommendedStoriesMobileView from "./recommended_stories/recommended_stories_mobile_view";
import Carousel from "react-multi-carousel";
import StoryCard from "@src/views/ui/story_card/story_card";
const RecommendedStories = () => {
  let language = retrieve_item("language");

  const navigate = useNavigate();
  const {
    items: recommended_story_list,
    loading: recommended_story_list_loading,
  } = useDynamicSelector("get_recommended_story_list");

  let recommended_story_list_request = useAPIRequest(
    "get_recommended_story_list",
    get_recommended_story_list_query
  );

  useEffect(() => {
    get_recommended_story_list();
  }, []);

  const get_recommended_story_list = () => {
    recommended_story_list_request(
      {
        language_id: language?.id,
      },
      true
    );
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 550 },
      items: 2,
    },
    // small_tablet: {
    //   breakpoint: { max: 650, min: 550 },
    //   items: 1.4,
    // },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1.2,
    },
  };
  return (
    <>
      <div className="recommended_story_desktop_main_container">
        <RecommendedStoriesDesktopView />
      </div>
      <div className="recommended_story_mobile_main_container">
        <RecommendedStoriesMobileView />
      </div>
      {!recommended_story_list_loading && (
        <div className="recommended_story_mobile_middle_container">
          {recommended_story_list?.length > 0 && (
            <Carousel responsive={responsive} arrows={false}>
              {recommended_story_list?.slice(1, 5)?.map((recommended) => {
                return (
                  <div style={{ padding: "30px" }}>
                    <StoryCard
                      {...(recommended?.fact_check
                        ? { ...recommended.fact_check, type: "fact_check" }
                        : { ...recommended?.article, type: "article" })}
                    />
                  </div>
                );
              })}
            </Carousel>
          )}
        </div>
      )}
    </>
  );
};

export default RecommendedStories;
