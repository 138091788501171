import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_recent_fact_check_result_list_query } from "@src/services/graphql/graphql_search_results";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect } from "react";
import SearchResultRecentlyAddedStoriesDesktopView from "./common_results_container/recently_added_for_search_results";
import SearchResultRecentlyAddedStoriesMobileView from "./common_results_container/recently_added_for_search_results_mobile_view";

const RecentFactCheckResults = () => {
  const language = retrieve_item("language");

  const {
    items: recent_fact_check_list,
    loading: recent_fact_check_list_loading,
  } = useDynamicSelector("get_recent_fact_check_result_list");

  let recent_fact_check_result_list_request = useAPIRequest(
    "get_recent_fact_check_result_list",
    get_recent_fact_check_result_list_query
  );

  useEffect(() => {
    get_recent_fact_check_result_list();
  }, []);

  const get_recent_fact_check_result_list = () => {
    recent_fact_check_result_list_request(
      { language_id: language?.id, page_number: 1, page_limit: 5 },
      true
    );
  };
  return (
    <>
      <div className="recommended_story_desktop_main_container">
        <SearchResultRecentlyAddedStoriesDesktopView />
      </div>
      <div className="recommended_story_mobile_main_container">
        <SearchResultRecentlyAddedStoriesMobileView />
      </div>
    </>
  );
};

export default RecentFactCheckResults;
