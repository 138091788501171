import { Skeleton } from "antd";
import React from "react";

const SingleFactCheckCommentsSkeleton = () => {
  return (
    <div className="comments_skeleton">
      <Skeleton.Input className="comments_skeleton" active />
    </div>
  );
};

export default SingleFactCheckCommentsSkeleton;
