import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect, useState, useTransition } from "react";
import TopContributersOverlayContent from "../top_contributers_overlay_content";
import { get_user_contributors_list_query } from "@src/services/graphql/graphql_user_contribution";
import { useAPIRequest } from "@src/helpers/hooks";
import { retrieve_item } from "@src/helpers/cryptos";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const TopContributorsList = () => {
  const current_language = retrieve_item("language");
  const { t } = useTranslation();
  const [page_limit, set_page_limit] = useState(10);
  const {
    items: user_contributors_list,
    pagination: user_contributors_pagination,
  } = useDynamicSelector("get_user_contributors_list");

  let user_contributors_list_request = useAPIRequest(
    "get_user_contributors_list",
    get_user_contributors_list_query
  );

  useEffect(() => {
    get_user_contributors_list();
  }, [page_limit]);

  const get_user_contributors_list = () => {
    user_contributors_list_request(
      {
        language_id: current_language?.id,
        page_number: 1,
        page_limit: page_limit,
      },
      true
    );
  };

  const handle_see_all = () => {
    set_page_limit(page_limit + 5);
  };

  return (
    <div className="user_contributors_container">
      <div className="user_contributors_list">
        {user_contributors_list?.map((item, index) => (
          <TopContributersOverlayContent
            key={index}
            item={item}
            index={index}
          />
        ))}
      </div>

      {user_contributors_pagination?.total_count > 0 &&
        user_contributors_pagination?.total_count !==
          user_contributors_list?.length && (
          <Button className="see_more_button" onClick={handle_see_all}>
            {t("see_more")}
          </Button>
        )}
    </div>
  );
};

export default TopContributorsList;
