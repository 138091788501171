import React, { useEffect, useState } from "react";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button, Form, Tooltip } from "antd";
import { YOUTURN_NEW_LOGO } from "@src/helpers/image_constants";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import ReactCountryPhoneInput from "react-country-phone-input";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";

const SignUpFormItems = (props) => {
  const {
    hide_form,
    handle_key_press,
    password_visible,
    toggle_password_visibility,
    sign_in,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mail_popup, set_mail_popup] = useState(false);
  const {
    status: get_otp_status,
    error: get_otp_error,
    loading: get_otp_loading,
  } = useDynamicSelector("get_otp");
  useEffect(() => {
    if (get_otp_error) {
      set_mail_popup(true);
      setTimeout(() => {
        set_mail_popup(false);
      }, 5000);
      setTimeout(() => {
        dispatch(dynamic_clear("get_otp"));
      }, 7000);
    }
  }, [get_otp_error]);
  return (
    <>
      {hide_form && (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2 className="sing_in_text">{t("create_account")}</h2>
          </div>
          <Form.Item
            className="margin-bottom"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <input className="sign_up_input" type="text" placeholder="Name" />
          </Form.Item>

          <Form.Item
            className="margin-bottom"
            name="mobile"
            rules={[{ required: true, message: "Mobile number is required" }]}
          >
            <PhoneInput
              country={"in"}
              placeholder="Mobile"
              rules={[
                {
                  pattern: /^[6-9]\d{9}$/,
                  message:
                    "Mobile number must start with 6 to 9 and be 10 digits long",
                },
              ]}
            />
          </Form.Item>
          <Tooltip open={mail_popup} title={get_otp_error?.message}>
            <Form.Item
              className="margin-bottom"
              name="email"
              rules={[
                { required: true, message: "Email is required" },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <input
                type="email"
                placeholder="Email"
                className="sign_up_input"
              />
            </Form.Item>
          </Tooltip>

          <Form.Item
            style={{
              display: "flex",
              flexDirection: "revert-layer",
              alignItems: "center",
              width: "min-content",
            }}
            className="margin-bottom"
            name="password"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject("Password is required");
                  }
                  if (value.length < 8) {
                    return Promise.reject(
                      "Password must be at least 8 characters long"
                    );
                  }
                  if (
                    !/(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])/.test(value)
                  ) {
                    return Promise.reject(
                      <div>{t("strong_password_rule")}</div>
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <div style={{ position: "relative" }}>
              <input
                type={password_visible ? "text" : "password"}
                placeholder="Password"
                className="sign_up_input"
              />
              {password_visible ? (
                <EyeInvisibleOutlined
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={toggle_password_visibility}
                />
              ) : (
                <EyeOutlined
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={toggle_password_visibility}
                />
              )}
            </div>
          </Form.Item>
          <div
            style={{
              // width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              htmlType="submit"
              loading={get_otp_loading}
              className="submit_button"
            >
              {t("sign_up")}
            </Button>

            {/* <button type="submit">
              {get_otp_loading ? (
                <div className="leap-frog">
                  <div className="leap-frog__dot"></div>
                  <div className="leap-frog__dot"></div>
                  <div className="leap-frog__dot"></div>
                </div>
              ) : (
                t("sign_up")
              )}
            </button> */}
          </div>

          <div className="view-text">
            <text className="sing_in_text">{t("already_have_an_account")}</text>
            <text
              className="sing_in_text_underline"
              onClick={sign_in}
              style={{ marginLeft: "10px" }}
            >
              {t("login")}
            </text>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUpFormItems;
