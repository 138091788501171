import React from "react";

const TopContributionsTitle = () => {
  return (
    <div className="featured_video_title_container">
      <h2 className="featured_article_title">Contributors</h2>
    </div>
  );
};

export default TopContributionsTitle;
