import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const UserDetailsSubmitButton = (props) => {
  const { modal_close } = props;
  const { t } = useTranslation();

  return (
    <div className="user_details_submit_button">
      <Button
        onClick={() => {
          modal_close(false);
        }}
        type="default"
        style={{
          backgroundColor: "#dc3545",
          color: "#fff",
        }}
      >
        {t("cancel")}
      </Button>
      <Button
        htmlType="submit"
        className="submit_button"
        style={{ marginLeft: "10px" }}
      >
        {t("submit")}
      </Button>
    </div>
  );
};

export default UserDetailsSubmitButton;
