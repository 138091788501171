import { gql } from "@apollo/client";

export const get_featured_article_query = gql`
  query get_all_article(
    $page_number: Int
    $page_limit: Int
    $json: get_all_article_input
  ) {
    get_featured_article: get_all_article(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        title
        subtitle
        thumbnail_img
        perma_link
        views
        comments_count
        created_date_time
        published_date_time
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const category_wise_article_list_query = gql`
  query get_all_article(
    $page_number: Int
    $page_limit: Int
    $json: get_all_article_input
  ) {
    get_category_wise_article_list: get_all_article(
      page_number: $page_number
      page_limit: $page_limit
      json: $json
    ) {
      pagination {
        page_number
        page_limit
        total_count
      }
      items {
        id
        thumbnail_img
        is_saved
        title
        perma_link
        created_date_time
        published_date_time
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_category_list_query = gql`
  query get_all_category_tag(
    $page_number: Int
    $page_limit: Int
    $language_id: String
  ) {
    get_category_list: get_all_category_tag(
      page_number: $page_number
      page_limit: $page_limit
      language_id: $language_id
    ) {
      items {
        id
        name
      }
    }
  }
`;

export const get_trending_article_list_query = gql`
  query get_trending_articles($language_id: String) {
    get_trending_article_list: get_trending_articles(
      language_id: $language_id
    ) {
      items {
        id
        title
        perma_link
        thumbnail_img
        created_date_time
        published_date_time
        staff_profile {
          id
          name
        }
        user_profile {
          id
          name
        }
      }
    }
  }
`;

export const get_recommended_story_list_query = gql`
  query get_all_recommended_story($language_id: String) {
    get_recommended_story_list: get_all_recommended_story(
      language_id: $language_id
    ) {
      items {
        fact_check_id
        fact_check {
          id
          title
          subtitle
          created_date_time
          published_date_time
          thumbnail_img
          perma_link
          reading_time
          views
          staff_profile {
            id
            name
          }
          user_profile {
            id
            name
          }
        }
        article_id
        article {
          id
          title
          subtitle
          created_date_time
          published_date_time
          thumbnail_img
          perma_link
          reading_time
          views
          staff_profile {
            id
            name
          }
          user_profile {
            id
            name
          }
        }
      }
    }
  }
`;

export const query_get_my_all_article = gql`
  query get_all_my_article(
  $language_id: String
  $page_limit: Int
  $status: String
  $page_number: Int
) {
  get_my_article_list: get_all_my_article(
    language_id: $language_id
    page_limit: $page_limit
    status: $status
    page_number: $page_number
  ) {
    pagination {
      page_number
      page_limit
      total_count
    }
    items {
      id
      title
      subtitle
      status
      perma_link
      content
      reading_time
      views
      thumbnail_img
      created_date_time
      published_date_time
      is_saved
      staff_profile {
        id
        name
      }
      user_profile {
        id
        name
      }
    }
  }
}

`;


export const query_get_my_published_article = gql`
  query get_all_my_article(
  $language_id: String
  $page_limit: Int
  $status: String
  $page_number: Int
) {
  get_article_published_request: get_all_my_article(
    language_id: $language_id
    page_limit: $page_limit
    status: $status
    page_number: $page_number
  ) {
    pagination {
      page_number
      page_limit
      total_count
    }
    items {
      id
      title
      subtitle
      status
      perma_link
      content
      reading_time
      views
      thumbnail_img
      created_date_time
      published_date_time
      is_saved
      staff_profile {
        id
        name
      }
      user_profile {
        id
        name
      }
    }
  }
}

`;
export const mutation_create_article = gql`
  mutation create_article($json: article_input) {
    create_article(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const query_get_article_preview = gql`
query get_article($id: String!) {
  get_article(id: $id) {
    id
    title
    subtitle
    perma_link
    language_id
    content
    reading_time
    views
    thumbnail_img
    party_tags {
      id
      name
    }
    created_date_time
  status
    published_date_time
    category_tags {
      id
      name
      language_id
    }
    staff_profile {
      id
      name
      profile_pic
    }
    user_profile {
      id
      name
      profile_pic
    }
  }
}
`;