import { retrieve_item } from "@src/helpers/cryptos";
import { generate_share_invite_friends } from "@src/helpers/functions";
import { ROUTES } from "@src/route/my_routes";
import { Button, Popover } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HomeUserContributionColumnThree = () => {
  const language = retrieve_item("language");
  const user_id = retrieve_item("user_id");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_WEB_URL;
  const share_invite_friends = generate_share_invite_friends(url);
  const handle_page_redirect = () => {
    navigate(ROUTES.LOGIN);
  };
  return (
    <div className="user_contributed_story_column_container">
      <div className="user_contributed_story_column_box">
        <h6
          className={
            language?.name === "tamil"
              ? "become_member_content_ta"
              : "become_member_content"
          }
        >
          {t("become_member_content")}
        </h6>
        <div className="donation_second_row_container">
          <h1>#</h1>
          <h1 className="empower_truth_tag">{t("empower_truth")}</h1>
        </div>
        {user_id ? (
          <Popover
            placement="top"
            content={share_invite_friends}
            trigger="hover"
            className="share_pop_over"
          >
            <Button className="become_a_member_button" type="primary">
              {/* {t("become_a_member")} */}
              {t("invite_friends")}
            </Button>
          </Popover>
        ) : (
          <Button className="join_us_button" onClick={handle_page_redirect}>
            {t("become_a_member")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default HomeUserContributionColumnThree;
