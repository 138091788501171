import React, { useEffect } from "react";
import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import { get_trending_fact_checks_query } from "@src/services/graphql/graphql_fact_check";
import { useDynamicSelector } from "@src/services/redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import FactCheckTrendingStoryCard from "./fact_check_trending_story_card";

const FactCheckTrendingStories = () => {
  const { t } = useTranslation();
  const current_language = retrieve_item("language");
  const navigate = useNavigate();

  let trending_fact_check_request = useAPIRequest(
    "get_trending_fact_check_list",
    get_trending_fact_checks_query
  );

  const {
    items: trending_fact_checks_list,
    loading: trending_fact_checks_list_loading,
  } = useDynamicSelector("get_trending_fact_check_list");

  useEffect(() => {
    get_trending_fact_check_list();
  }, []);

  const get_trending_fact_check_list = () => {
    trending_fact_check_request(
      {
        language_id: current_language?.id,
      },
      true
    );
  };
  const handle_redirect = (type, perma_link) => {
    if (type === "article") {
      navigate(`${ROUTES.SINGLE_ARTICLE_PAGE}/${perma_link}.html`);
    } else if (type === "fact-check" || type === "fact_check") {
      navigate(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
    }
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 4,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 4,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  return (
    <div className="fact_check_trending_stories_container">
      <div className="fact_check_trending_stories_title_container">
        <text className="fact_check_trending_stories_title">
          {t("trending_stories")}
        </text>
      </div>

      {trending_fact_checks_list?.length > 0 && (
        <Carousel responsive={responsive}>
          {trending_fact_checks_list?.map((list, index) => (
            <FactCheckTrendingStoryCard key={index} list={list} />
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default FactCheckTrendingStories;
