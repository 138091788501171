import React, { useEffect, useRef, useState } from "react";
import HeaderBar from "../components/auth/header_bar.jsx/header_bar";
import QuickAccessBar from "../components/auth/quick_access_bar/quick_access_bar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/auth/footer/footer";
import SubmitClaim from "../components/submit_claim/submit_claim";
import ScrollToTopButton from "../components/scroll_to_top_button/scroll_to_top_button";
import HeaderSearchMobileView from "../components/auth/header_bar.jsx/header_search_mobile_view";

const UserLayout = (props) => {
  const top_ref = useRef(null);
  const { pathname } = useLocation();

  const [show_scroll_icon, set_show_scroll_icon] = useState(false);
  const [show_mobile_search, set_show_mobile_search] = useState(false);
  const [writing_image_opacity, set_writing_image_opacity] = useState(1);
  const handle_show_search = () => {
    set_show_mobile_search(!show_mobile_search);
  };

  const handle_scroll = () => {
    const scroll_element = top_ref?.current;
    if (scroll_element) {
      const scroll_position = scroll_element.scrollTop;
      set_show_scroll_icon(scroll_position > 100); // Show icon if scrolled more than 100px from the top
      set_writing_image_opacity(0.1);
      setTimeout(() => {
        set_writing_image_opacity(1);
      }, 1000);
    }
  };

  useEffect(() => {
    const scroll_element = top_ref?.current;
    if (scroll_element) {
      scroll_element.addEventListener("scroll", handle_scroll);
      return () => {
        scroll_element.removeEventListener("scroll", handle_scroll);
      };
    }
  }, []);

  useEffect(() => {
    const element = top_ref.current;
    if (element) {
      element.scrollTo({
        top: 0,
      });
    }
    if (pathname === "/search-results") {
      set_show_mobile_search(false);
    }
  }, [pathname]);

  const handle_scroll_to_top = () => {
    const element = top_ref.current;
    if (element) {
      element.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div ref={top_ref} className="main-container">
      <QuickAccessBar />
      <HeaderBar handle_show_search={handle_show_search} />
      {show_mobile_search && (
        <div
          style={{
            position: "sticky",
            top: "49px",
            zIndex: 999,
          }}
          className="user_layout_mobile_view_search"
        >
          <HeaderSearchMobileView />
        </div>
      )}

      <Outlet />
      <Footer />
      <SubmitClaim writing_image_opacity={writing_image_opacity} />
      {show_scroll_icon && (
        <ScrollToTopButton
          handle_scroll_to_top={handle_scroll_to_top}
          opacity={writing_image_opacity}
        />
      )}
    </div>
  );
};

export default UserLayout;
