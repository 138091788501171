import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import { get_user_trending_stories_list_query } from "@src/services/graphql/graphql_user_contribution";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TrendingUserContributionCard from "./user_contribution_trending_stories/trending_user_contribution_card";
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";

const UserContributionTrendingStories = () => {
  const { t } = useTranslation();
  const current_language = retrieve_item("language");
  const navigate = useNavigate();

  const user_trending_stories_list_request = useAPIRequest(
    "get_user_trending_stories_list",
    get_user_trending_stories_list_query
  );

  const { items: trending_stories_list } = useDynamicSelector(
    "get_user_trending_stories_list"
  );

  useEffect(() => {
    get_user_trending_stories_list();
  }, []);

  const get_user_trending_stories_list = () => {
    user_trending_stories_list_request(
      {
        language_id: current_language?.id,
        page_limit: 6,
        page_number: 1,
      },
      true
    );
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 4,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 4,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  return (
    <div className="fact_check_trending_stories_container">
      <div className="fact_check_trending_stories_title_container">
        <text className="fact_check_trending_stories_title">
          {t("trending_stories")}
        </text>
      </div>

      {trending_stories_list?.length > 0 && (
        <Carousel responsive={responsive}>
          {trending_stories_list?.map((list, index) => (
            <TrendingUserContributionCard key={index} list={list} />
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default UserContributionTrendingStories;
