import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import { Button, InputNumber, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import UserDetailsFormModal from "../../home/home_donation.jsx/user_details_form_modal/user_details_form_modal";
import AppreciationModal from "../appreciation_modal/appreciation_modal";
import { initiate_request_razorpay_payment } from "@src/helpers/functions";
import { retrieve_item } from "@src/helpers/cryptos";

const SecondaryDonation = () => {
  const { t } = useTranslation();
  const { name } = retrieve_item("language");

  const dispatch = useDispatch();

  const [selected_amount, set_selected_amount] = useState(null);
  const [donation_modal, set_donation_modal] = useState(false);
  const [payment_response, set_payment_response] = useState("");
  const [appreciation_modal_open, set_appreciation_modal] = useState(false);
  const [popper_status, set_popper_status] = useState(false);
  const { mobile_number } = useDynamicSelector("user_entered_mobile_number");

  const { status: create_donation_status, order_id } =
    useDynamicSelector("create_donation");
  const payment_status = payment_response.razorpay_signature;

  useEffect(() => {
    if (order_id) {
      initiate_request_razorpay_payment(
        order_id,
        set_payment_response,
        mobile_number
      );
      dispatch(dynamic_clear("create_donation"));
    }
  }, [order_id]);

  useEffect(() => {
    if (create_donation_status === "Success") {
      set_selected_amount(null);
    }
    dispatch(dynamic_clear("create_donation"));
  }, [create_donation_status]);

  useEffect(() => {
    if (payment_status) {
      set_appreciation_modal(true);
      set_popper_status(true);
      setTimeout(() => {
        set_appreciation_modal(false);
        set_popper_status(false);
      }, 10000);
    }
  }, [payment_status]);

  const AmountInput = () => {
    const handle_change_amount = (value) => {
      set_selected_amount(value);
    };
    return (
      <div className="amount_input_container">
        <InputNumber
          className="amount_input"
          maxLength={10}
          controls={false}
          changeOnWheel={false}
          onChange={handle_change_amount}
          value={selected_amount}
          placeholder={t("enter_amount")}
        />
      </div>
    );
  };
  const DonationFooter = () => {
    const handle_donate = () => {
      set_donation_modal(true);
    };
    return (
      <div className="donation_footer_container">
        <Button
          className="donate_button load_more_button"
          disabled={selected_amount !== null ? false : true}
          onClick={handle_donate}
        >
          {t("donate")}
        </Button>
        <text>{t("donation_note")}</text>
      </div>
    );
  };
  return (
    <div className="secondary_donation_container">
      <div className="secondary_donation_content_container">
        <Typography
          className={
            name === "tamil"
              ? " donation_first_row_content"
              : "donation_first_row_content_en"
          }
        >
          {t("donate_top_content")}
        </Typography>
      </div>
      <div className="secondary_donation_fight_against_container">
        <h1>#</h1>
        <h2>{t("fight_against_fake_news")}</h2>
      </div>

      <div className="secondary_donation_amount_container">
        <div className="secondary_donation_amount_wrapper">{AmountInput()}</div>
      </div>
      <div className="secondary_donation_button_container">
        <DonationFooter />
      </div>
      <UserDetailsFormModal
        modal_open={donation_modal}
        on_cancel={set_donation_modal}
        amount={selected_amount}
      />
      <AppreciationModal
        subtitle={t("donation_success_title")}
        message={t("donation_success_content")}
        popper_status={popper_status}
        appreciation_modal_open={appreciation_modal_open}
        set_appreciation_modal={set_appreciation_modal}
      />
    </div>
  );
};

export default SecondaryDonation;
