import FeaturedFactCheck from "../components/fact_check/featured_fact_check";
import FactCheckTrendingStories from "../components/fact_check/fact_check_trending_stories/fact_check_trending_stories";
import PartyTagWiseFactCheckList from "../components/fact_check/party_tag_wise_fact_check_list";
import JoinUs from "../components/custom_components/join_us/join_us";
import CategoryTagWiseFactCheckList from "../components/fact_check/browse_categories/category_tag_wise_fact_check_list";
import PartyWiseFactCheck from "../components/fact_check/party_wise_fact_check";

const FactCheck = () => {
  return (
    <div className="fact_check_container">
      <FeaturedFactCheck />
      <CategoryTagWiseFactCheckList />
      <FactCheckTrendingStories />
      <PartyWiseFactCheck />
      <JoinUs />
    </div>
  );
};

export default FactCheck;
