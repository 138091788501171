import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const Title = ({ color }) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      label={
        <span style={{ color: color ? color : "black" }}>{t("Title")}</span>
      }
      name="title"
      style={{ marginTop: "22px" }}
      rules={[
        {
          required: true,
          message: "Please enter the title",
        },
      ]}
    >
      <Input placeholder="Title" />
    </Form.Item>
  );
};
export default Title;
