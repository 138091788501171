import { Skeleton } from "antd";
import React from "react";

const FeaturedVideoContentSkeleton = () => {
  return (
    <div className="featured_story_content_skeleton">
      <div className="featured_video_content_title_wrapper">
        <Skeleton active paragraph={{ rows: 2 }} />
      </div>
    </div>
  );
};

export default FeaturedVideoContentSkeleton;
