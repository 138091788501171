import React from "react";
import SocialMediaContainer from "./social_media_container";
import {
  FACEBOOK_FILED_LOGO,
  INSTAGRAM_FILED_LOGO,
  LINKEDIN_FILED_LOGO,
  SHARE_CHAT_FILED_LOGO,
  TELEGRAM_FILED_LOGO,
  THREADS_ICON,
  TWITTER_FILED_LOGO,
  WHATSAPP_FILED_LOGO,
  YOUTUBE_COLOR,
} from "@src/helpers/image_constants";
import { retrieve_item } from "@src/helpers/cryptos";

const SocialMediaCard = () => {
  let language = retrieve_item("language");

  const redirect_to_social_media = (socialMedia) => {
    switch (socialMedia) {
      case "Instagram":
        window.open("https://www.instagram.com/youturn.media/", "_blank");
        break;
      case "Facebook":
        window.open("https://www.facebook.com/youturn.in/", "_blank");
        break;
      case "Twitter":
        window.open("https://twitter.com/youturn_in", "_blank");
        break;
      case "YouTube":
        window.open(
          "https://www.youtube.com/channel/UCCLsLUlxvfdnRwG8_Uh40Ew",
          "_blank"
        );
        break;
      case "LinkedIn":
        window.open("https://in.linkedin.com/in/youturn32017", "_blank");
        break;
      case "Telegram":
        window.open("https://t.me/youturn_in", "_blank");
        break;
      case "Share chat":
        window.open(
          "https://sharechat.com/profile/youturn_in?referer=tagProfileSearchPage",
          "_blank"
        );
        break;
      case "Threads":
        const threads_url =
          language.name === "tamil"
            ? "https://www.threads.net/@youturn.media"
            : "https://www.threads.net/@youturn_english";
        window.open(threads_url, "_blank");
        break;
      case "Whatsapp":
        window.open(
          "https://api.whatsapp.com/send?phone=916369748351&text=Hi%20You%20Turn!",
          "_blank"
        );
        break;

      default:
    }
  };
  return (
    <div className="social_media_card_container">
      <text className="stay_connected_text">Stay Connected</text>

      <SocialMediaContainer
        logo={FACEBOOK_FILED_LOGO}
        media_name={"Facebook"}
        on_click={() => redirect_to_social_media("Facebook")}
      />
      <SocialMediaContainer
        logo={TWITTER_FILED_LOGO}
        media_name={"X (Twitter)"}
        on_click={() => redirect_to_social_media("Twitter")}
      />
      <SocialMediaContainer
        logo={INSTAGRAM_FILED_LOGO}
        media_name={"Instagram"}
        on_click={() => redirect_to_social_media("Instagram")}
      />
      <SocialMediaContainer
        logo={YOUTUBE_COLOR}
        media_name={"YouTube"}
        on_click={() => redirect_to_social_media("YouTube")}
      />
      <SocialMediaContainer
        logo={LINKEDIN_FILED_LOGO}
        media_name={"LinkedIn"}
        on_click={() => redirect_to_social_media("LinkedIn")}
      />
      <SocialMediaContainer
        logo={TELEGRAM_FILED_LOGO}
        media_name={"Telegram"}
        on_click={() => redirect_to_social_media("Telegram")}
      />
      <SocialMediaContainer
        logo={WHATSAPP_FILED_LOGO}
        media_name={"WhatsApp"}
        on_click={() => redirect_to_social_media("Whatsapp")}
      />
      <SocialMediaContainer
        logo={SHARE_CHAT_FILED_LOGO}
        media_name={"ShareChat"}
        on_click={() => redirect_to_social_media("Share chat")}
      />
      <SocialMediaContainer
        logo={THREADS_ICON}
        media_name={"Threads"}
        on_click={() => redirect_to_social_media("Threads")}
      />
    </div>
  );
};

export default SocialMediaCard;
