import React from "react";
import { Image } from "antd";
import { useTranslation } from "react-i18next";
import { WHATSAPP_GIF_ICON } from "@src/helpers/image_constants";
import { retrieve_item } from "@src/helpers/cryptos";

const CustomText = ({ class_name, text, font_family }) => (
  <span className={class_name} style={{ fontFamily: font_family }}>
    {text}
  </span>
);

const CustomJoinWhatsappChannel = () => {
  const { t } = useTranslation();
  const language = retrieve_item("language");

  const redirectToWhatsapp = () => {
    {
      language?.name === "tamil"
        ? window.open(
            "https://whatsapp.com/channel/0029Va4HSHgLikgBHdfkX53V",
            "_blank"
          )
        : window.open(
            "https://whatsapp.com/channel/0029Va4ndSk11ulVi5VkkN30",
            "_blank"
          );
    }
  };

  return (
    <div className="whatsapp_channel_container" onClick={redirectToWhatsapp}>
      <Image
        preview={false}
        src={WHATSAPP_GIF_ICON}
        className="whatsapp_channel_icon"
      />
      <CustomText
        class_name="whatsapp_channel_text"
        text={t("join_our_whatsapp_channel_for_quick_updates")}
        font_family="lato"
      />
    </div>
  );
};

export default CustomJoinWhatsappChannel;
