import React from "react";
import { useDynamicSelector } from "@src/services/redux";
import SingleFactCheckDetailContainer from "./single_fact_check_detail_container";
import SingleFactCheckClaim from "./single_fact_check_claim";

const SingleFactCheckDetails = (props) => {
  const { handle_save } = props;
  const item = useDynamicSelector("get_fact_check");

  return (
    <div>
      <div className="single_fact_check_claim_content_container">
        <div className="single_fact_check_claim_content_wrapper">
          <div className="single_fact_check_claim_container claim_wrapper">
            <SingleFactCheckClaim />
          </div>
          <SingleFactCheckDetailContainer handle_save={handle_save} />
        </div>
      </div>
    </div>
  );
};

export default SingleFactCheckDetails;
