import { retrieve_item } from "@src/helpers/cryptos";
import React from "react";
import { useTranslation } from "react-i18next";

const ContactUsHeader = () => {
  const { t } = useTranslation();
  const language = retrieve_item("language");

  return (
    <div className="contact_us_header">
      <div className="contact_us_header_container">
        <h3 className="white_font">Contact Us</h3>
        <text
          className={
            language?.name === "tamil"
              ? "contact_us_description_ta"
              : "contact_us_description"
          }
        >
          {t("contact_us_description")}
        </text>
      </div>
    </div>
  );
};

export default ContactUsHeader;
