import { useDynamicSelector } from "@src/services/redux";
import StoryCard from "@src/views/ui/story_card/story_card";
import React from "react";
import Carousel from "react-multi-carousel";
import FactCheckPartyWiseFirstColumnMobileView from "../../fact_check/fact_check_party_wise/fact_check_party_wise_mobile_view.jsx/fact_check_party_wise_first_column_mobile_view";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";
import "react-multi-carousel/lib/styles.css"; // Ensure carousel styles are imported

const SearchResultRecentlyAddedStoriesMobileView = () => {
  const { t } = useTranslation();

  const {
    items: recent_fact_check_list,
    loading: recent_fact_check_list_loading,
  } = useDynamicSelector("get_recent_fact_check_result_list");
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="recommended_story_mobile_container">
      <h3>{t("recommended_stories")}</h3>
      {!recent_fact_check_list_loading && (
        <div className="recommended_story_mobile_first_container">
          <FactCheckPartyWiseFirstColumnMobileView
            list={recent_fact_check_list?.[0]}
            type={"fact_check"}
          />
        </div>
      )}
      {recent_fact_check_list_loading ? (
        <div className="recommended_story_mobile_middle_container_loading">
          {Array.from({ length: 6 }, (_, index) => (
            <div
              className="home_user_contribution_card_container"
              style={{ padding: "10px" }}
            >
              <Skeleton active paragraph={{ rows: 2 }} title={false} />
            </div>
          ))}
        </div>
      ) : (
        recent_fact_check_list?.length > 0 && (
          <Carousel responsive={responsive} arrows={false}>
            {recent_fact_check_list?.map((recommended) => {
              return (
                <div style={{ padding: "30px" }}>
                  <StoryCard {...{ ...recommended, type: "fact_check" }} />
                </div>
              );
            })}
          </Carousel>
        )
      )}
    </div>
  );
};

export default SearchResultRecentlyAddedStoriesMobileView;
