import React, { useState } from "react";
import FactCheckCategoryTag from "./fact_check_category_tag";
import CategoryWiseFactCheck from "./category_wise_fact_check";
import { useTranslation } from "react-i18next";
import SocialMediaShare from "../social_media_share";

const CategoryTagWiseFactCheckList = () => {
  const { t } = useTranslation();
  const [category_name, set_category_name] = useState(t("all"));
  const [page_limit, set_page_limit] = useState(6);
  const [category_id, set_category_id] = useState();
  const [default_category_name, set_default_category_name] = useState(t("all"));
  const [fact_check_page_limit, set_fact_check_page_limit] = useState(6);
  return (
    <div className="browse_categories_container">
      <div className="category_wise_list_container">
        <text className="fact_check_browse_categories_title">
          {t("browse_categories")}
        </text>
        <FactCheckCategoryTag
          set_category_name={set_category_name}
          set_default_category_name={set_default_category_name}
          set_category_id={set_category_id}
          set_page_limit={set_page_limit}
          set_fact_check_page_limit={set_fact_check_page_limit}
        />
        <CategoryWiseFactCheck
          set_page_limit={set_page_limit}
          set_fact_check_page_limit={set_fact_check_page_limit}
          fact_check_page_limit={fact_check_page_limit}
          category_id={category_id}
          page_limit={page_limit}
          default_category_name={default_category_name}
          set_category_id={set_category_id}
        />
      </div>

      <div className="social_media_logo_container">
        <div className="half_circle">
          <SocialMediaShare />
        </div>
      </div>
    </div>
  );
};

export default CategoryTagWiseFactCheckList;
