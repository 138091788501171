import React from "react";
import FeaturedArticleImageContainer from "../featured_article_desktop_view/featured_article_image_components";
import FeaturedArticleContentMobileView from "./featured_article_content_mobile_view";
import FeaturedArticleContentSkeleton from "../featured_article_skeleton_loading/featured_article_content_skeleton";
import FeaturedArticleImageComponentSkeleton from "../featured_article_skeleton_loading/featured_article_image_component_skeleton";
import { useDynamicSelector } from "@src/services/redux";

const FeaturedArticleBackGroundContainerMobileView = () => {
  const { loading: featured_article_loading } = useDynamicSelector(
    "get_featured_article"
  );
  return (
    <div className="featured_story_fact_check_container_mobile_view">
      <div className="featured_article_back_ground_container_mobile_view"></div>
      <div className="featured_article_container_mobile_view">
        {featured_article_loading ? (
          <FeaturedArticleImageComponentSkeleton />
        ) : (
          <FeaturedArticleImageContainer />
        )}
      </div>
      {featured_article_loading ? (
        <FeaturedArticleContentSkeleton />
      ) : (
        <FeaturedArticleContentMobileView />
      )}
    </div>
  );
};

export default FeaturedArticleBackGroundContainerMobileView;
