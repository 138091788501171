import { useDynamicSelector } from "@src/services/redux";
import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const OtpInputFieldAndButtons = (props) => {
  const { t } = useTranslation();
  const {
    handle_verify,
    form_ref,
    verify_otp_loading,
    resend_otp,
    counter,
    otp,
    set_otp_value,
  } = props;

  const { status: verify_otp_status, error: verify_otp_error } =
    useDynamicSelector("verify_otp");

  useEffect(() => {
    if (verify_otp_status === "Success") {
      set_otp_value(Array(4).fill(""));
    } else if (verify_otp_status === "Failure") {
      set_otp_value(Array(4).fill(""));
    } else if (verify_otp_error) {
      set_otp_value(Array(4).fill(""));
    }
  }, [verify_otp_status, verify_otp_error]);

  const handle_change = (value, index) => {
    if (/^\d*$/.test(value)) {
      const new_otp = [...otp];
      new_otp[index] = value;
      set_otp_value(new_otp);

      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handle_key_down = (event, index) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  return (
    <>
      <form ref={form_ref} id="otp-form">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1vh",
          }}
        >
          {otp?.map((value, index) => (
            <Input
              key={index}
              id={`otp-input-${index}`}
              value={value}
              onChange={(e) => handle_change(e.target.value, index)}
              onKeyDown={(e) => handle_key_down(e, index)}
              maxLength={1}
              style={{
                width: "3rem",
                height: "3rem",
                textAlign: "center",
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "black",
                border: "1px solid #d9d9d9",
                borderRadius: "0.375rem",
              }}
            />
          ))}
        </div>
        <div className="max-w-[260px] mx-auto mt-4">
          <Button
            htmlType="submit"
            loading={verify_otp_loading}
            className="submit_button"
            onClick={(e) => {
              e.preventDefault();
              handle_verify();
            }}
          >
            Verify OTP
          </Button>

          {/* <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handle_verify();
            }}
          >
            {verify_otp_loading ? (
              <div class="leap-frog">
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
                <div class="leap-frog__dot"></div>
              </div>
            ) : (
            
            )}
          </button> */}
        </div>
        <div className="reset_otp_box">
          {counter > 0 ? (
            <div style={{ fontWeight: "bold", textAlign: "center" }}>
              <span className="resent_otp_text">
                {t("Resend OTP in")} 00:{" "}
                {counter < 10 ? `0${counter}` : counter}
              </span>
            </div>
          ) : (
            <div style={{ alignItems: "center" }}>
              <div className="resent_otp_text">
                {"Didn't receive OTP?"}

                <span
                  onClick={() => {
                    resend_otp();
                  }}
                  style={{
                    textDecoration: "underline",
                    marginLeft: "7px",
                    cursor: "pointer",
                  }}
                >
                  {"Resend OTP"}
                </span>
              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default OtpInputFieldAndButtons;
