import {
  FACEBOOK_FILED_LOGO,
  INSTAGRAM_FILED_LOGO,
  LINKEDIN_FILED_LOGO,
  TWITTER_FILED_LOGO,
} from "@src/helpers/image_constants";
import React from "react";

const WritersSocialMediaLinks = ({ author_links }) => {
  return (
    <div
      style={{
        display: "flex",
        height: "9vh",
        alignItems: "end",
      }}
    >
      {author_links?.map((link, index) =>
        link.type === "linked_in" ? (
          <a
            key={index}
            href={link.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "10px" }}
          >
            <div>
              <img
                className="social_media_urls"
                src={LINKEDIN_FILED_LOGO}
                alt="LinkedIn"
              />
            </div>
          </a>
        ) : link.type === "twitter" ? (
          <a
            key={index}
            href={link.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "10px" }}
          >
            <div>
              <img
                className="social_media_urls"
                src={TWITTER_FILED_LOGO}
                alt="Twitter"
              />
            </div>
          </a>
        ) : link.type === "instagram" ? (
          <a
            key={index}
            href={link.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "10px" }}
          >
            <div>
              <img
                className="social_media_urls"
                src={INSTAGRAM_FILED_LOGO}
                alt="instagram"
              />
            </div>
          </a>
        ) : link.type === "facebook" ? (
          <a
            key={index}
            href={link.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "10px" }}
          >
            <div>
              <img
                className="social_media_urls"
                src={FACEBOOK_FILED_LOGO}
                alt="instagram"
              />
            </div>
          </a>
        ) : null
      )}
    </div>
  );
};

export default WritersSocialMediaLinks;
