import React from "react";
import ContactUsHeader from "../components/contact_us/contact_us_header";
import ContactUsSection from "../components/contact_us/contact_us_section";
import { ROUTES } from "@src/route/my_routes";
import Breadcrumbs from "../components/breadcrumb/breadcrumb";

const ContactUs = () => {
  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },
    {
      path: "",
      breadcrumb_name: "Contact Us",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Breadcrumbs items={items} />
      <ContactUsHeader />
      <ContactUsSection />
    </div>
  );
};

export default ContactUs;
