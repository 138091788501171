import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const ViralPostLink = () => {
  const { t } = useTranslation();

  const validate_url = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const urlRegex =
      /^https:\/\/((([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,})|localhost)(:[0-9]{1,5})?(\/[^\s]*)?$/;
    if (urlRegex.test(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(t("invalid_url_message"));
    }
  };

  return (
    <Form.Item
      name="ref_url"
      rules={[
        {
          validator: validate_url,
        },
      ]}
    >
      <Input
        className="submit-claim-input"
        placeholder={t("viral_post_link")}
      />
    </Form.Item>
  );
};

export default ViralPostLink;
