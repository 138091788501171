import React, { useEffect, useState } from "react";
import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import {
  create_comments_mutation,
  get_fact_check_comment_list_query,
} from "@src/services/graphql/graphql_single_fact_check";
import {
  dynamic_clear,
  dynamic_set,
  useDynamicSelector,
} from "@src/services/redux";
import { Avatar, Button, Empty, Input, Tooltip } from "antd";
import { SendOutlined } from "@ant-design/icons";
import moment from "moment";
import { MdReply } from "react-icons/md";
import {
  PiArrowBendDownRightBold,
  PiArrowBendLeftUpBold,
} from "react-icons/pi";
import { date_format } from "@src/helpers/functions";
import { useTranslation } from "react-i18next";
import SingleFactCheckCommentsSkeleton from "./single_fact_check_comments_skeleton";
import { ROUTES } from "@src/route/my_routes";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import SingleFactCheckReplyCommentSkeleton from "./single_fact_check_report_donation/single_fact_check_reply_comment_skeleton";

const SingleFactCheckComments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_id = retrieve_item("user_id");
  const [create_comment_input, set_create_comment_input] = useState("");
  const [visible_replies, set_visible_replies] = useState({});
  const [reply_input_visible, set_reply_input_visible] = useState({});
  const [reply_content, set_reply_content] = useState({});
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(2);
  const [open_comment_popup, set_open_comment_popup] = useState(false);
  const language = retrieve_item("language");
  const { id: fact_check_id } = useDynamicSelector("get_fact_check");
  const { type } = useDynamicSelector("comment_type");
  const {
    items: fact_check_comment_list,
    loading: fact_check_comment_list_loading,
    pagination,
  } = useDynamicSelector("get_fact_check_comment_list");

  const fact_check_comment_list_request = useAPIRequest(
    "get_fact_check_comment_list",
    get_fact_check_comment_list_query
  );

  const { status: create_comment_status, error: create_comment_error } =
    useDynamicSelector("create_comment");

  useEffect(() => {
    if (create_comment_status === "Success") {
      // toast.success("Comment Created Successfully");
      set_open_comment_popup(true);
      setTimeout(() => {
        dispatch(dynamic_clear("comment_type"));
        set_open_comment_popup(false);
      }, 5000);
      dispatch(dynamic_clear("create_comment"));
      set_create_comment_input("");
      set_reply_content("");
    } else if (create_comment_status === "Failure") {
      // toast.error("Please Login");
      navigate(ROUTES.LOGIN);
      dispatch(dynamic_clear("create_comment"));
    }
  }, [create_comment_status, create_comment_error]);

  const create_comment_request = useAPIRequest(
    "create_comment",
    create_comments_mutation
  );

  const handle_see_more_comments = () => {
    if (page_limit > 2) {
      set_page_limit(2);
    } else {
      set_page_limit(pagination?.total_count);
    }
  };

  useEffect(() => {
    get_fact_check_comment_list();
  }, [page_limit, fact_check_id]);

  const get_fact_check_comment_list = () => {
    fact_check_comment_list_request(
      { fact_check_id, page_number, page_limit },
      true
    );
  };

  const toggle_replies = (comment_id) => {
    set_visible_replies((prev) => ({
      ...prev,
      [comment_id]: !prev[comment_id],
    }));
  };

  const toggle_reply_input = (comment_id) => {
    dispatch(dynamic_set("comment_type", { type: "parent" }));
    set_reply_input_visible((prev) => ({
      ...prev,
      [comment_id]: !prev[comment_id],
    }));
  };

  const handle_reply_change = (e, comment_id) => {
    set_reply_content((prev) => ({
      ...prev,
      [comment_id]: e.target.value,
    }));
  };

  const handle_comment_click = () => {
    if (user_id) {
      dispatch(dynamic_set("comment_type", { type: "main" }));
      const request = create_comment_request({
        json: {
          fact_check_id: fact_check_id,
          content: create_comment_input,
        },
      });

      if (request && request.then) {
        request
          .then(() => {
            set_create_comment_input("");
            get_fact_check_comment_list();
          })
          .catch((error) => {
            console.error("Error creating comment:", error);
          });
      }
    } else {
      const queryParams = new URLSearchParams();
      queryParams.set("type", "comment");
      queryParams.set("types", "fact_check");
      queryParams.set("id", fact_check_id);
      queryParams.set("redirecturl", window.location.pathname);
      navigate({
        pathname: "/login",
        search: `?${queryParams.toString()}`,
      });
    }
  };

  const handle_reply_click = (comment_id) => {
    dispatch(dynamic_set("comment_type", { type: "parent" }));
    const request = create_comment_request({
      json: {
        fact_check_id: fact_check_id,
        content: reply_content[comment_id],
        parent_comment_id: comment_id,
      },
    });

    if (request && request.then) {
      request
        .then(() => {
          set_reply_content((prev) => ({
            ...prev,
            [comment_id]: "",
          }));
          set_reply_input_visible((prev) => ({
            ...prev,
            [comment_id]: false,
          }));
          get_fact_check_comment_list();
        })
        .catch((error) => {
          console.error("Error creating reply:", error);
        });
    } else {
      console.error("create_comment_request did not return a promise.");
    }
  };

  const render_comments = (comments) => {
    return comments?.map((comment) => (
      <div key={comment.id} className="comment">
        <div className="comment_content">
          <div className="comment_header">
            <Avatar
              src={comment?.commented_by.user_profile.profile_pic}
              size="large"
            />
            <div className="comment_details">
              <span className="comment_details_user_name">
                {comment?.commented_by.user_profile.name}
              </span>
              <span className="comment_details_time">
                {date_format(comment?.created_date_time)}
              </span>
            </div>
          </div>
          <div className="comment_body">
            <div className="comments_parent_reply_row_content">
              <span className="comments_parent_comment_text">
                {comment?.content}
              </span>
              <Button
                type="link"
                icon={<MdReply size={30} className="reply_icon" />}
                onClick={() => toggle_reply_input(comment?.id)}
                className="comments_parent_comment_reply_button"
              >
                Reply
              </Button>
            </div>
            {comment?.child_comments.length > 0 && (
              <Button
                icon={
                  visible_replies[comment?.id] ? (
                    <PiArrowBendLeftUpBold size={20} />
                  ) : (
                    <PiArrowBendDownRightBold size={20} />
                  )
                }
                type="link"
                onClick={() => toggle_replies(comment?.id)}
              >
                {visible_replies[comment?.id] ? "Hide Replies" : "View Replies"}
              </Button>
            )}
            <div className="comment_actions">
              {reply_input_visible[comment?.id] && (
                <div className="reply_input_container">
                  <Tooltip
                    open={open_comment_popup && type === "parent"}
                    title={
                      "We have received your comments. We will review your comment and publish."
                    }
                    id="comment-content"
                  >
                    <Input
                      placeholder="Enter reply here"
                      value={reply_content[comment?.id] || ""}
                      onChange={(e) => handle_reply_change(e, comment?.id)}
                      className="reply_input"
                    />
                  </Tooltip>
                  <Button
                    type="primary"
                    icon={<SendOutlined />}
                    onClick={() => handle_reply_click(comment.id)}
                  >
                    {t("post")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        {visible_replies[comment.id] && comment.child_comments.length > 0 && (
          <div className="replies">
            {comment.child_comments?.map((reply) => (
              <div key={reply.id} className="reply">
                <Avatar
                  src={reply.commented_by?.user_profile?.profile_pic}
                  size="small"
                />
                <div className="reply_details">
                  <p className="reply_comment_container">
                    <text className="reply_comment_name_text">
                      {reply?.commented_by?.user_profile?.name}{" "}
                    </text>
                    <text className="reply_comment_date_text">
                      {moment(reply?.created_date_time).format("MMM DD, YYYY")}
                    </text>
                  </p>
                  <p className="reply_comment_text">{reply?.content}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="comments_container">
      <div className="comments_title_container">
        <h5 className="comments_title">{t("comments")}</h5>
      </div>

      <div className="comments_bordered_div">
        {fact_check_comment_list?.length === 0 ? (
          <Empty description={<span>{t("no_comments")}</span>} />
        ) : (
          <>
            {fact_check_comment_list_loading ? (
              <SingleFactCheckReplyCommentSkeleton />
            ) : (
              <>
                {render_comments(fact_check_comment_list)}
                <div className="show_all_button_container">
                  {page_limit > 3 && (
                    <span
                      className="see_more_text"
                      onClick={handle_see_more_comments}
                    >
                      {page_limit > 3 ? t("see_less") : t("see_more")}
                    </span>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className="comments_input_button_group">
        <Tooltip
          open={open_comment_popup && type === "main"}
          title={
            "We have received your comments. We will review your comment and publish."
          }
          id="comment-content"
        >
          <Input
            placeholder={
              user_id ? t("enter_text_here") : "Please login to comment"
            }
            className="comments_primary_input"
            value={create_comment_input}
            onChange={(e) => set_create_comment_input(e.target.value)}
            disabled={!user_id}
          />
        </Tooltip>
        <Button
          onClick={handle_comment_click}
          type="primary"
          className="comments_send_button"
          icon={<SendOutlined />}
        >
          {t("post")}
        </Button>
      </div>
    </div>
  );
};

export default SingleFactCheckComments;
