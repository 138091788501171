import { initiate_request_razorpay_payment } from "@src/helpers/functions";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import AppreciationModal from "@src/views/components/custom_components/appreciation_modal/appreciation_modal";
import UserDetailsFormModal from "@src/views/components/home/home_donation.jsx/user_details_form_modal/user_details_form_modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

function FightFakeNewsMob() {
  const { t } = useTranslation()
  const [selected_amount, set_selected_amount] = useState(null)
  const [donation_modal, set_donation_modal] = useState(false);
  const [appreciation_modal_open, set_appreciation_modal] = useState(false);
  const [popper_status, set_popper_status] = useState(false);
  const [payment_response, set_payment_response] = useState("");
  const { mobile_number } = useDynamicSelector("user_entered_mobile_number")

  const dispatch = useDispatch();
  const { status: create_donation_status, order_id } =
    useDynamicSelector("create_donation");
  const payment_status = payment_response.razorpay_signature;

  const handle_change = (e) => {
    set_selected_amount(Number(e?.target?.value))
  }
  const handle_donate = () => {
    set_donation_modal(true);
  }

  useEffect(() => {
    if (order_id) {
      initiate_request_razorpay_payment(order_id, set_payment_response, mobile_number);
      dispatch(dynamic_clear("create_donation"));
    }
  }, [order_id]);

  useEffect(() => {
    if (payment_status) {
      set_appreciation_modal(true);
      set_popper_status(true);
      setTimeout(() => {
        set_appreciation_modal(false);
        set_popper_status(false);
      }, 10000);
    }
  }, [payment_status]);
  return (
    <div className="fight_fake_news_container_mob">
      <div className="">
        <div className="fight_fake_news_div">
          <p className="fight_fake_news_hash">#</p>
          <p className="fight_fake_news_hash_val">{t("fight_against_fake_news")}</p>
        </div>
        <p className="fight_fake_news_head">
          {t("donate_top_content")}
        </p>
        <p style={{ color: "white", margin: "13px 0 0px 0", fontSize: "17px", fontWeight: "700" }}>{t("make_a_small_contribution")}</p>
        <div>
          <input
            type="number"
            placeholder={t("enter_amount")}
            className="figh_fake_news_input_mob"
            onChange={handle_change} maxLength={10} max={10}
          />
        </div>

        <div className="fight_fake_news_second_div">
          <button className="figh_fake_news_button_mob" disabled={selected_amount !== 0 ? false : true} onClick={handle_donate}>{t("donate")}</button>
          <p className="figh_fake_news_note_mob">
            {t("donation_note")}
          </p>
        </div>
      </div>
      <UserDetailsFormModal
        modal_open={donation_modal}
        on_cancel={set_donation_modal}
        amount={selected_amount}
      />
      <AppreciationModal
        subtitle={t("donation_success_title")}
        message={
          t("donation_success_content")
        }
        popper_status={popper_status}
        appreciation_modal_open={appreciation_modal_open}
        set_appreciation_modal={set_appreciation_modal}
      />
    </div>
  );
}

export default FightFakeNewsMob;
