import React from "react";
import SecondaryDonation from "../../custom_components/donation/secondary_donation";
import SingleFactCheckReport from "./single_fact_check_report";

const SingleFactCheckReportDonation = () => {
  return (
    <div className="single_fact_check_report_donation_container">
      <div className="single_fact_check_report_donation_wrapper">
        <div className="single_fact_check_report_container">
          <SingleFactCheckReport type={"fact-check"} />
        </div>
        <div className="donation_secondary_banner">
          <SecondaryDonation />
        </div>
      </div>
    </div>
  );
};

export default SingleFactCheckReportDonation;
