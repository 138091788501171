import React, { useEffect } from "react";
// import HomeBanner from '../components/about_us/about_banner'
import OurMission from "../components/about_us/about_us/our_mission";
import MeetTheTeam from "../components/about_us/about_us/meet_the_team";
import IfcnCertified from "../components/about_us/about_us/policies/ifcn_certified";
import FightFakeNews from "../components/about_us/about_us/policies/fight_fake_news";
import FightFakeNewsMob from "../components/about_us/about_us/policies/fight_fake_news_mob";
import Policies from "../components/about_us/about_us/policies";
import AwardsAndRecognition from "../components/about_us/about_us/awards_and_recognition";
import AwardsAndRecognitionMob from "../components/about_us/about_us/awards_and_recognition_mob";
import HomeBanner from "../components/about_us/about_us/about_banner";
import { get_team_member_list_query } from "@src/services/graphql/graphql_about_us";
import { useAPIRequest } from "@src/helpers/hooks";
import JoinUs from "../components/custom_components/join_us/join_us";
import Breadcrumbs from "../components/breadcrumb/breadcrumb";
import { ROUTES } from "@src/route/my_routes";
import { useTranslation } from "react-i18next";

function AboutUs() {
  const { t } = useTranslation();
  const get_user_dashboard_counts = useAPIRequest(
    "get_team_member_list",
    get_team_member_list_query
  );

  useEffect(() => {
    get_user_dashboard_counts({}, true);
  }, []);
  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: t("home"),
    },
    {
      path: "",
      breadcrumb_name: t("about_us"),
    },
  ];
  return (
    <>
      <Breadcrumbs items={items} />
      <div className="aboutus_container">
        <HomeBanner />
        <OurMission />
        <MeetTheTeam />
        <div className="policies_head">
          <IfcnCertified />
          <FightFakeNews />
        </div>
        <FightFakeNewsMob />
        <Policies />
        <FightFakeNewsMob />
        <AwardsAndRecognition />
        <div className="about_us_join_use_card_container">
          <JoinUs />
        </div>
        {/* <AwardsAndRecognitionMob /> */}
      </div>
    </>
  );
}

export default AboutUs;
