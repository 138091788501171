import { SITE_URL } from "@src/helpers/constants";
import { retrieve_item } from "@src/helpers/cryptos";
import { format_views_count, get_share_content } from "@src/helpers/functions";
import {
  SAVE_FILLED_COLOR,
  SAVE_ICON,
  SHARE_ICON_COLOR,
} from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";
import { Popover, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { LuEye } from "react-icons/lu";
import { useLocation } from "react-router-dom";

const ShareSaveContainer = (props) => {
  const { handle_save } = props;
  const { pathname } = useLocation();
  const { t } = useTranslation();
  let language = retrieve_item("language");
  const [share_url, set_share_url] = useState();
  const [show_bookmark_popup, set_show_bookmark_popup] = useState(false);
  const [show_copy_link_popup, set_copy_link_popup] = useState(false);
  const [is_copy_link, set_is_copy_link] = useState(false);
  const article = useDynamicSelector("get_article");
  const { is_saved: bookmark_saved } = useDynamicSelector("bookmark_saved");

  useEffect(() => {
    if (is_copy_link) {
      set_copy_link_popup(true);
      setTimeout(() => {
        set_copy_link_popup(false);
        set_is_copy_link(false);
      }, 500);
    }
  }, [is_copy_link]);

  const copy_to_clipboard = () => {
    const redirect_url = window.location.href;
    navigator.clipboard?.writeText(redirect_url);
    set_is_copy_link(true);
  };

  useEffect(() => {
    if (language?.name === "tamil") {
      const site_url = new URL(SITE_URL);
      const domain = site_url.hostname;
      const link_without_html = pathname?.replace(".html", "");
      const url = `https://share.${domain}${link_without_html}`;
      set_share_url(url);
    } else {
      const site_url = new URL(SITE_URL);
      const domain = site_url.hostname;
      const link_without_html = pathname?.replace(".html", "");
      const url = `https://share.${domain}/en${link_without_html}`;
      set_share_url(url);
    }
  }, [pathname, SITE_URL]);

  useEffect(() => {
    if (bookmark_saved) {
      set_show_bookmark_popup(true);
      setTimeout(() => {
        set_show_bookmark_popup(false);
      }, 5000);
    }
  }, [bookmark_saved]);

  const share_content = get_share_content(
    article?.title,
    share_url,
    article?.thumbnail_img,
    copy_to_clipboard,
    show_copy_link_popup
  );

  return (
    <div className="share_save_container">
      <div className="single_article_icons_wrapper">
        <LuEye color="red" className="single_view_article_icons" />
        <text className="single_article_view_count">
          {format_views_count(article?.views)}
        </text>
      </div>
      <div className="share_save_container_second_column">
        <Popover
          className="single_article_icons"
          placement="top"
          content={share_content}
          trigger="hover"
        >
          <img className="save_share_icon" src={SHARE_ICON_COLOR} />
          <text className="save_share_text">{t("share")}</text>
        </Popover>
        <div className="single_article_icons">
          {bookmark_saved ? (
            <Tooltip title={"Bookmark Saved"} open={show_bookmark_popup}>
              <div className="save_icon_wrapper">
                <img
                  className="save_share_icon"
                  src={SAVE_FILLED_COLOR}
                  onClick={handle_save}
                />
                <text className="save_share_text" onClick={handle_save}>
                  {t("save")}
                </text>
              </div>
            </Tooltip>
          ) : (
            <React.Fragment>
              <div className="save_icon_wrapper">
                <img
                  className="save_share_icon"
                  src={SAVE_ICON}
                  onClick={handle_save}
                />
                <text className="save_share_text" onClick={handle_save}>
                  {t("save")}
                </text>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareSaveContainer;
