import { gql } from "@apollo/client";
export const create_donation_mutation = gql`
  mutation create_donation($amount: Int!, $mobile: String, $address: String) {
    create_donation(amount: $amount, mobile: $mobile, address: $address) {
      status
      order_id
      error {
        status_code
        message
      }
    }
  }
`;

export const get_my_donation_query = gql`
  query get_all_donations(
    $page_limit: Int
    $page_number: Int
    $user_id: String
  ) {
    get_all_donations(
      page_limit: $page_limit
      page_number: $page_number
      user_id: $user_id
    ) {
      items {
        id
        amount
        payment_method
        receipt
        transaction_id
        created_date_time
        order_ref
        system_user {
          id
          username
          user_profile {
            name
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        message
      }
    }
  }
`;
