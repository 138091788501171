// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 768px) and (max-width: 1023px) {

  .user_dashboard_container {
    grid-template-columns: 100%;
    row-gap: 20px;
  }
  .user_profile_details_main_container {
    width: 100%;
  }
  .user_dashboard_third_container,
  .user_dashboard_second_container {
    flex-direction: row;
  }
  .empty_container_card {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /* .donations-card { */
    /* width: 400px; */
  /* } */
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_768_1023/screen_768_1023_user_donation.css"],"names":[],"mappings":"AAAA;;EAEE;IACE,2BAA2B;IAC3B,aAAa;EACf;EACA;IACE,WAAW;EACb;EACA;;IAEE,mBAAmB;EACrB;EACA;IACE,aAAa;IACb,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;EACA,sBAAsB;IACpB,kBAAkB;EACpB,MAAM;AACR","sourcesContent":["@media only screen and (min-width: 768px) and (max-width: 1023px) {\r\n\r\n  .user_dashboard_container {\r\n    grid-template-columns: 100%;\r\n    row-gap: 20px;\r\n  }\r\n  .user_profile_details_main_container {\r\n    width: 100%;\r\n  }\r\n  .user_dashboard_third_container,\r\n  .user_dashboard_second_container {\r\n    flex-direction: row;\r\n  }\r\n  .empty_container_card {\r\n    height: 250px;\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n  /* .donations-card { */\r\n    /* width: 400px; */\r\n  /* } */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
