import React from "react";

const SocialMediaContainer = (props) => {
  const { logo, media_name, on_click } = props;

  return (
    <div className="social_media_image_container">
      <img
        className="single_article_social_media_icon"
        src={logo}
        onClick={on_click}
      />
      <text className="single_article_social_media_name" onClick={on_click}>
        {media_name}
      </text>
    </div>
  );
};

export default SocialMediaContainer;
