import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_trending_article_list_query } from "@src/services/graphql/graphql_articles";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import TrendingArticleCard from "./trending_article_card";

const TrendingArticles = () => {
  const { t } = useTranslation();
  let language = retrieve_item("language");

  let trending_article_list_request = useAPIRequest(
    "get_trending_article_list",
    get_trending_article_list_query
  );
  const {
    items: trending_article_list,
    loading: trending_article_list_loading,
  } = useDynamicSelector("get_trending_article_list");

  useEffect(() => {
    get_trending_article_list();
  }, []);

  const get_trending_article_list = () => {
    trending_article_list_request(
      {
        language_id: language?.id,
      },
      true
    );
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 4,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 4,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 2,
      slidesToSlide: 1,
    },
  };
  return (
    <div className="fact_check_trending_stories_container">
      <div className="fact_check_trending_stories_title_container">
        <text className="fact_check_trending_stories_title">
          {t("trending_stories")}
        </text>
      </div>

      {trending_article_list?.length > 0 && (
        <Carousel responsive={responsive}>
          {trending_article_list?.map((list, index) => (
            <TrendingArticleCard key={index} list={list} />
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default TrendingArticles;
