import { MICK_IMAGE } from "@src/helpers/image_constants";
import { Button, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamic_clear,
  dynamic_set,
  useDynamicSelector,
} from "@src/services/redux";
import { useDispatch } from "react-redux";
import { retrieve_item } from "@src/helpers/cryptos";
import { Navigate, useNavigate } from "react-router-dom";
import { ROUTES } from "@src/route/my_routes";

const SingleFactCheckReport = ({ type }) => {
  const user_id = retrieve_item("user_id");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { name } = retrieve_item("language");
  const [show_report_popup, set_show_report_popup] = useState(false);
  const { status: create_report_status } =
    useDynamicSelector("report_mutation");

  const handle_report_click = () => {
    if (user_id) {
      dispatch(dynamic_set("report_modal_state", { is_report_modal: true }));
    } else {
      const queryParams = new URLSearchParams();
      queryParams.set("type", "report");
      queryParams.set("types", type);
      queryParams.set("redirecturl", window.location.pathname);
      navigate({
        pathname: "/login",
        search: `?${queryParams.toString()}`,
      });
    }
  };

  useEffect(() => {
    if (create_report_status === "Success") {
      dispatch(dynamic_clear("report_mutation"));
      set_show_report_popup(true);
      setTimeout(() => {
        set_show_report_popup(false);
      }, 5000);
    }
  }, [create_report_status]);

  return (
    <div className="report_container">
      <div className="report_title_container">
        <text className="report_title">
          {t("feel_something_is_wrong_with_the_article")}
        </text>
      </div>
      <div style={{ display: "flex" }}>
        <div className="report_container_first_column">
          {/* <text className="report_title">
            {t("feel_something_is_wrong_with_the_article")}
          </text> */}
          <div className="single_fact_check_mick_image_container">
            <img className="single_fact_check_mick_image" src={MICK_IMAGE} />
          </div>
        </div>
        <div className="report_container_second_column">
          <text
            className={
              name === "tamil"
                ? "we_are_committed_text_ta"
                : "we_are_committed_text"
            }
          >
            {t("we_are_committed_text")}
          </text>
          <Tooltip title={"Report Submitted"} open={show_report_popup}>
            <Button
              className="single_article_report_button"
              onClick={handle_report_click}
            >
              {t("report")}
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default SingleFactCheckReport;
