import React from "react";
import ContactUsForm from "./contact_us_form";
import Donation from "@src/views/ui/donation/donation";
import { useTranslation } from "react-i18next";
import { retrieve_item } from "@src/helpers/cryptos";

const ContactUsSection = () => {
  const { t } = useTranslation();
  const language = retrieve_item("language");

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="contact_us_section_wrapper">
        <div className="contact_us_form_container">
          <h3
            className={
              language?.name === "tamil"
                ? "leave_us_message_ta"
                : "leave_us_message"
            }
          >
            {t("leave_us_message")}
          </h3>
          <ContactUsForm />
        </div>
        <div className="contact_us_donation_wrapper">
          <Donation />
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
