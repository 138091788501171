import React from "react";
import RelatedFactCheckList from "./related_fact_check_list";
import RecentFactCheckList from "./recent_fact_check_list";
import { useTranslation } from "react-i18next";

const RelatedAndRecentFactCheck = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="related_recent_fact_check_container">
        <div className="related_recent_fact_check_wrapper">
          <div className="related_recent_fact_check_card_container">
            <RelatedFactCheckList />
            <RecentFactCheckList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedAndRecentFactCheck;
