import {
  amountFormatWithSymbol,
  initiate_request_razorpay_payment,
} from "@src/helpers/functions";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import { Button, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import UserDetailsFormModal from "../home/home_donation.jsx/user_details_form_modal/user_details_form_modal";
import AppreciationModal from "../custom_components/appreciation_modal/appreciation_modal";

const SupportUsDonation = () => {
  const { t } = useTranslation();
  const [selected_amount, set_selected_amount] = useState(null);
  const [donation_modal, set_donation_modal] = useState(false);
  const [payment_response, set_payment_response] = useState("");
  const [appreciation_modal_open, set_appreciation_modal] = useState(false);
  const [popper_status, set_popper_status] = useState(false);
  const [other_input, set_other_input] = useState();
  const { mobile_number } = useDynamicSelector("user_entered_mobile_number");

  const dispatch = useDispatch();
  const { status: create_donation_status, order_id } =
    useDynamicSelector("create_donation");
  const payment_status = payment_response.razorpay_signature;

  const handle_close_donation_modal = () => {
    set_donation_modal(false);
  };

  useEffect(() => {
    if (order_id) {
      initiate_request_razorpay_payment(
        order_id,
        set_payment_response,
        mobile_number
      );
      dispatch(dynamic_clear("create_donation"));
    }
  }, [order_id]);

  useEffect(() => {
    if (payment_status) {
      set_appreciation_modal(true);
      set_popper_status(true);
      setTimeout(() => {
        set_appreciation_modal(false);
        set_popper_status(false);
      }, 10000);
    }
  }, [payment_status]);

  const AmountValueCard = () => {
    let amount_values = [199, 499, 999, 1999, "Others"];
    const handle_click = (value) => {
      if (value === "Others") {
        set_selected_amount();
        set_other_input("Others");
      } else {
        set_selected_amount(value);
      }
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        {amount_values.map((list, index) => {
          return (
            <div
              className={
                selected_amount === list
                  ? "selected_amount_format_card_content"
                  : "amount_format_card_content"
              }
              key={index}
              onClick={() => handle_click(list)}
            >
              {amountFormatWithSymbol(list)}
            </div>
          );
        })}
      </div>
    );
  };
  const AmountInput = () => {
    const handle_change_amount = (value) => {
      set_selected_amount(value);
    };
    return (
      <div className="support_donation_amount_input_container">
        <InputNumber
          className="amount_input"
          maxLength={10}
          controls={false}
          changeOnWheel={false}
          onChange={handle_change_amount}
          placeholder={t("enter_amount")}
          value={selected_amount}
        />
        <span
          className="amount_format_card_content"
          onClick={() => {
            set_other_input(null);
          }}
        >
          Back
        </span>
      </div>
    );
  };
  const handle_donate = () => {
    set_donation_modal(true);
  };

  return (
    <div className="support_us_donation_main_container">
      <div
        style={{
          display: "flex",
          color: "#f49d36",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <text style={{ fontSize: "34px", fontWeight: "bold" }}>#</text>
        <text style={{ fontSize: "30px" }}>{t("fight_against_fake_news")}</text>
      </div>
      <text style={{ fontSize: "15px", color: "#ffffff" }}>
        {t("donate_top_content")}
      </text>
      <div className="donation_input_wrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h5 style={{ color: "white", fontSize: "15px", fontWeight: "bold" }}>
            {t("make_a_small_contribution")}
          </h5>
          {other_input === "Others" ? AmountInput() : AmountValueCard()}
          <text style={{ color: "gray", fontSize: "13px" }}>
            {t("donation_note")}
          </text>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          className="donate_button load_more_button"
          disabled={selected_amount !== null ? false : true}
          onClick={handle_donate}
        >
          {t("donate")}
        </Button>
      </div>
      <UserDetailsFormModal
        modal_open={donation_modal}
        on_cancel={set_donation_modal}
        amount={selected_amount}
      />
      <AppreciationModal
        subtitle={t("donation_success_title")}
        message={t("donation_success_content")}
        popper_status={popper_status}
        appreciation_modal_open={appreciation_modal_open}
        set_appreciation_modal={set_appreciation_modal}
      />
    </div>
  );
};

export default SupportUsDonation;
