import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TweetIframe from "../custom_components/tweet_iframe/tweet_iframe";
import ReactHtmlParser from "html-react-parser";
import { useDynamicSelector } from "@src/services/redux";
import cheerio from "cheerio";
import SingleFactCheckComments from "./single_fact_check_comments";
import FactCheckShareSaveContainer from "./single_fact_check_report_donation/fact_check_share_save_container";
import SingleFactCheckSpreadNews from "./single_fact_check_spread_news";
import SingleFactCheckClaim from "./single_fact_check_claim";
import JoinWhatsappChannel from "../single_article/single_article_details/join_whatsapp_channel";

const SingleFactCheckDetailContainer = (props) => {
  const { handle_save } = props;
  const { t } = useTranslation();
  const [parsed_html, set_parsed_html] = useState("");
  const { content } = useDynamicSelector("get_fact_check");
  const { dark_mode } = useDynamicSelector("dark_mode");
  const options = {
    replace: ({ name, attribs, children }) => {
      if (name === "TweetIframe" || name === "tweetiframe") {
        return <TweetIframe {...attribs}>{children}</TweetIframe>;
      }
      return null;
    },
  };

  useEffect(() => {
    if (content) {
      const $ = cheerio.load(
        content
          .replace(/\[/g, "<")
          .replace(/\]/g, ">")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
      );

      const twitterEmbed = (url) => {
        return `
              <blockquote class="twitter-tweet">
                <a href="${url}"></a>
              </blockquote>
              <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            `;
      };

      $("a[href^='https://twitter.com/']").each((index, element) => {
        const twitter_link = $(element).attr("href");
        if (twitter_link) {
          const embedded_tweet = twitterEmbed(twitter_link);
          $(element).replaceWith(embedded_tweet);
        }
      });

      $("blockquote.instagram-media").each((index, element) => {
        const blockquote = $(element);
        const permalink = blockquote.attr("data-instgrm-permalink");
        if (permalink) {
          const instagramUrl = permalink.split("?")[0];
          const iframe = `<iframe src="${instagramUrl}embed" width="500" height="800" frameborder="0" scrolling="no" allowtransparency></iframe>`;
          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });

      $("blockquote").each((index, element) => {
        const blockquote = $(element);
        const tweetUrl = blockquote
          .find('a[href^="https://twitter.com/"]')
          .last()
          .attr("href");

        if (tweetUrl) {
          const iframe = `<TweetIframe tweeturl=${tweetUrl}>`;
          if (blockquote.parent()[0]) {
            blockquote.replaceWith(iframe);
          } else {
            console.warn("Parent node does not exist for this blockquote.");
          }
        }
      });

      $("video").each((index, element) => {
        const video_element = $(element);
        const video_src = video_element.attr("mp4");
        const video_width = video_element.attr("width");
        const video_height = video_element.attr("height");
        // Wrap the video element with a video wrapper
        const video_frame = `
              <div class="video-wrapper">
                <video width="100%" height="auto" controls>
                  <source src="${video_src}" type="video/mp4"/>
                </video>
              </div>`;
        video_element.replaceWith(video_frame);
      });
      if (dark_mode) {
        $("body").addClass("dark_mode   article-content");
      } else {
        $("body").addClass("article-content");
      }

      set_parsed_html($.html());
    }
  }, [content]);

  return (
    <div className="single_fact_check_content_container">
      <SingleFactCheckSpreadNews />

      <div className="claim_wrapper_mob_screen">
        <SingleFactCheckClaim />
      </div>
      <div className="single_fact_details_title">
        <h5>{t("detailed_explanation")}</h5>
      </div>
      <div
        style={{ textAlign: "justify" }}
        className={`${dark_mode ? "dark_mode" : ""}`}
      >
        {parsed_html ? ReactHtmlParser(parsed_html, options) : ""}
      </div>
      <div className="single_fact_check_whats_app_mobile_container">
        <JoinWhatsappChannel />
      </div>

      <div className="fact_check_share_save_container">
        <FactCheckShareSaveContainer handle_save={handle_save} />
      </div>
      <SingleFactCheckComments />
    </div>
  );
};

export default SingleFactCheckDetailContainer;
