import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { dynamic_clear, useDynamicSelector } from "../../../services/redux";
import toast from "react-hot-toast";
import OtpInputFieldAndButtons from "./otp_input_field_and_buttons";
import { get_verify_mobile_otp_query } from "@src/services/graphql/graphql_otp";
import { useAPIRequest } from "@src/helpers/hooks";

const OTPScreen = (props) => {
  const { mobile_number, mutation_sign_in, all_values, get_otp_api_call } =
    props;

  const dispatch = useDispatch();
  const form_ref = useRef(null);
  const inputs_ref = useRef([]);
  const [show_resend_message, set_show_resend_message] = useState(false);
  const [counter, set_counter] = useState(30);
  const [otp, set_otp] = useState("");
  const [otp_value, set_otp_value] = useState(Array(4).fill(""));

  const {
    status: verify_otp_status,
    error: verify_otp_error,
    loading: verify_otp_loading,
  } = useDynamicSelector("verify_otp");

  let otp_verify_request = useAPIRequest(
    "verify_otp",
    get_verify_mobile_otp_query
  );

  const verify_otp = (otp) => {
    otp_verify_request(
      {
        mobile: all_values?.mobile,
        email: all_values?.email,
        otp: otp,
      },
      true
    );
  };

  useEffect(() => {
    if (verify_otp_status === "Success") {
      mutation_sign_in(all_values);
      dispatch(dynamic_clear("verify_otp"));
    } else if (verify_otp_status === "Failure") {
      // toast.error(verify_otp_error.message);
      set_otp("");

      dispatch(dynamic_clear("verify_otp"));
    }
    dispatch(dynamic_clear("verify_otp"));
  }, [verify_otp_status, verify_otp_error]);

  useEffect(() => {
    if (counter <= 30) {
      const timer =
        counter > 0 && setInterval(() => set_counter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter]);

  useEffect(() => {
    const form = form_ref.current;
    const inputs = [...form.querySelectorAll("input[type=text]")];
    inputs_ref.current = inputs;
    const submit = form.querySelector("button[type=submit]");

    const handle_key_down = (e) => {
      if (
        !/^[0-9]{1}$/.test(e.key) &&
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "Tab" &&
        !e.metaKey
      ) {
        e.preventDefault();
      }

      if (e.key === "Delete" || e.key === "Backspace") {
        const index = inputs.indexOf(e.target);
        if (index > 0) {
          inputs[index - 1].value = "";
          inputs[index - 1].focus();
        }
      }
    };

    const handle_input = (e) => {
      const { target } = e;
      const index = inputs.indexOf(target);
      if (target.value) {
        const new_otp = inputs.map((input) => input.value).join("");
        set_otp(new_otp);
        if (index < inputs.length - 1) {
          inputs[index + 1].focus();
        } else {
          submit?.focus();
        }
      }
    };

    const handle_focus = (e) => {
      e.target.select();
    };

    const handle_paste = (e) => {
      e.preventDefault();
      const text = e.clipboardData.getData("text");
      if (!new RegExp(`^[0-9]{${inputs.length}}$`).test(text)) {
        return;
      }
      const digits = text.split("");
      inputs.forEach((input, index) => (input.value = digits[index]));
      submit.focus();
    };

    inputs.forEach((input) => {
      input.addEventListener("input", handle_input);
      input.addEventListener("keydown", handle_key_down);
      input.addEventListener("focus", handle_focus);
      input.addEventListener("paste", handle_paste);
    });

    const timer = setTimeout(() => {
      set_show_resend_message(true);
    }, 30000);

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("input", handle_input);
        input.removeEventListener("keydown", handle_key_down);
        input.removeEventListener("focus", handle_focus);
        input.removeEventListener("paste", handle_paste);
      });

      clearTimeout(timer);
    };
  }, []);

  const handle_verify = () => {
    verify_otp(otp);
    const inputs = form_ref.current.querySelectorAll("input[type='text']");
    inputs.forEach((input) => {
      input.value = "";
    });
  };

  const resend_otp = () => {
    set_counter(30);
    set_otp_value(Array(4).fill(""));
    get_otp_api_call(all_values);
    const inputs = form_ref.current.querySelectorAll("input[type='text']");
    inputs.forEach((input) => {
      input.value = "";
    });
  };

  return (
    <div style={{ padding: "10%" }}>
      <div className="otp_text">
        <h1>OTP Verification</h1>
        <p>
          {`Enter the 4-digit verification code sent to  
    ${mobile_number}`}
        </p>
      </div>
      <OtpInputFieldAndButtons
        handle_verify={handle_verify}
        form_ref={form_ref}
        verify_otp_loading={verify_otp_loading}
        resend_otp={resend_otp}
        counter={counter}
        otp={otp_value}
        set_otp_value={set_otp_value}
      />
    </div>
  );
};
export default OTPScreen;
