import React from "react";
import { useTranslation } from "react-i18next";
import { LuEye } from "react-icons/lu";
import { AiFillClockCircle } from "react-icons/ai";
import { BROKEN_IMAGE, READING_TAG } from "@src/helpers/image_constants";
import {
  date_format,
  ensure_https,
  format_views_count,
  split_content,
} from "@src/helpers/functions";
import { retrieve_item } from "@src/helpers/cryptos";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import SlotCard from "@src/views/ui/slot_card";

const HomeUserContributionColumnOne = ({ value }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dark_mode } = useDynamicSelector("dark_mode");
  const language = retrieve_item("language");
  // const SlotCard = (props) => {
  //   const {
  //     thumbnail_img,
  //     title,
  //     subtitle,
  //     content,
  //     user_profile,
  //     views,
  //     published_date_time,
  //     reading_time,
  //     perma_link,
  //     record_type,
  //   } = value;

  //   const handle_click_page_redirect = () => {
  //     if (record_type === "article") {
  //       navigate(`${ROUTES.SINGLE_ARTICLE}/${perma_link}.html`);
  //     } else if (record_type === "fact_check") {
  //       navigate(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
  //     }
  //   };

  //   const image_url = ensure_https(thumbnail_img);

  //   return (
  //     <div
  //       onClick={handle_click_page_redirect}
  //       className={`slot_main_container w-auto margin_zero ${
  //         dark_mode ? "dark_mode_card" : ""
  //       }`}
  //     >
  //       <div className="slot_image_box zoom-effect">
  //         <img
  //           src={image_url || BROKEN_IMAGE}
  //           className=" image_hover_effect"
  //           style={{ width: "100%", height: "100%" }}
  //         />
  //       </div>
  //       {/* <div className="slot_card_details">
  //         <h6 className="slot_card_title_container">{title || ""}</h6>
  //         <text className={language?.name === "tamil" ? "slot_card_subtitle_container_ta" : "slot_card_subtitle_container"}>
  //           {split_content(subtitle || content)}
  //         </text>
  //         <div className="home_slot_card_author_detail">
  //           <text className="home_slot_card_author_name">
  //             {`${user_profile?.name}

  //         | ${date_format(published_date_time)}`}
  //           </text>
  //         </div>
  //         <div
  //           className="display-flex flex-row  user_contributed_story_container_footer"
  //           style={{ alignItems: "center" }}
  //         >
  //           <div className="home_slot_view_time_wrapper">
  //             <div className="display-flex flex-row g-1  align-center home_user_contribute_footer">
  //               <LuEye color="red" className="view-count-icon" />
  //               <text className="time_tag_text">
  //                 {format_views_count(views)}
  //               </text>
  //             </div>
  //             <div className="display-flex flex-row g-1 align-center home_user_contribute_footer">
  //               <AiFillClockCircle color="gray" className="reading-time-icon" />
  //               <text className="time_tag_text">
  //                 {reading_time} {t("min")}
  //               </text>
  //             </div>
  //           </div>
  //           <div className="continue_reading_container">
  //             <h4 className={language?.name === "tamil" ? "continue_reading_text_ta" : "continue_reading_text"}>{t("continue_reading")}</h4>
  //             <img src={READING_TAG} style={{ height: "30px" }} />
  //           </div>
  //         </div>
  //       </div> */}
  //       <div className="slot_card_details">
  //         <h6 className="slot_card_title_container">{title || ""}</h6>
  //         <text className="slot_card_subtitle_container">
  //           {split_content(subtitle || content) || ""}
  //         </text>
  //         <div className="home_slot_card_author_detail">
  //           <text className="home_slot_card_author_name">
  //             {`${user_profile?.name}

  //         | ${date_format(published_date_time)} `}
  //           </text>
  //         </div>
  //         <div
  //           className="display-flex flex-row  user_contributed_story_container_footer"
  //           style={{ alignItems: "center" }}
  //         >
  //           <div className="home_slot_view_time_wrapper">
  //             <div className="display-flex flex-row g-1  align-center home_user_contribute_footer">
  //               <LuEye color="red" className="view-count-icon" />
  //               <text className={`time_tag_text`}>
  //                 {format_views_count(views)}
  //               </text>
  //             </div>
  //             <div className="display-flex flex-row g-1 align-center home_user_contribute_footer">
  //               <AiFillClockCircle color="gray" className="reading-time-icon" />
  //               <text className="time_tag_text">
  //                 {reading_time} {t("min")}
  //               </text>
  //             </div>
  //           </div>
  //           <div className="continue_reading_container">
  //             <h4
  //               className={
  //                 language?.name === "tamil"
  //                   ? "continue_reading_text_ta"
  //                   : "continue_reading_text"
  //               }
  //             >
  //               {t("continue_reading")}
  //             </h4>
  //             <img src={READING_TAG} style={{ height: "30px" }} />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  return (
    <div className="home_user_contributed_story_first_column_main_container">
      <h1
        className={
          language?.name === "tamil"
            ? "user_contributed_module_title_ta"
            : "user_contributed_module_title"
        }
      >
        {t("user_contributed_stories")}
      </h1>
      {value && <SlotCard {...value} />}
    </div>
  );
};

export default HomeUserContributionColumnOne;
