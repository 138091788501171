import React from "react";
import WriterProfileDetails from "@src/views/components/writer_profile/writer_profile_details";

const WriterProfile = () => {
  return (
    <div>
      <WriterProfileDetails />
    </div>
  );
};

export default WriterProfile;
