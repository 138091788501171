import UserProfileLayout from '@src/views/ui/user_profile/user_profile'
import React from 'react'
import ClaimListMobile from './claim_list_mobile';
import ClaimList from './claim_list';
import Breadcrumbs from '../../breadcrumb/breadcrumb';
import { ROUTES } from '@src/route/my_routes';
import { useTranslation } from 'react-i18next';

const UserClaims = () => {
  const { t } = useTranslation()
  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: t("home"),
    },
    {
      path: ROUTES.USER_PROFILE,
      breadcrumb_name: t("profile"),
    },
    {
      path: "",
      breadcrumb_name: t("claims"),
    },
  ];

  return (
    <>
      <Breadcrumbs items={items} />
      <UserProfileLayout>
        <ClaimListMobile />
        <div className='user_donation_main_container'>
          <ClaimList />
        </div>
      </UserProfileLayout>
    </>
  )
}

export default UserClaims
