import { Col, Row, Skeleton } from "antd";
import React from "react";

const HomeArticleTrendingCardSkeleton = () => {
  return (
    <Row className="home_article_trending_container">
      <Skeleton.Input
        className="home_article_trending_container_skeleton"
        active
      />
    </Row>
  );
};

export default HomeArticleTrendingCardSkeleton;
