import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_category_tag_list_query } from "@src/services/graphql/graphql_fact_check";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const FactCheckCategoryTag = (props) => {
  const {
    set_category_name,
    set_default_category_name,
    set_category_id,
    set_page_limit,
    set_fact_check_page_limit,
  } = props;
  const { t } = useTranslation();
  const current_language = retrieve_item("language");
  const [categories_more, set_categories_more] = useState([]);
  const [show_more, set_show_more] = useState(true);
  const [category_key, set_category_key] = useState(t("all"));

  let category_tag_list_request = useAPIRequest(
    "get_category_tag_list",
    get_category_tag_list_query
  );

  const { items: category_tag_list, loading: category_tag_list_loading } =
    useDynamicSelector("get_category_tag_list");

  useEffect(() => {
    get_category_tag_list();
  }, []);

  useEffect(() => {
    if (category_tag_list) {
      set_categories_more([
        { id: "All", name: t("all") },
        ...category_tag_list?.slice(0, 7),
        { id: "More", name: t("more") },
      ]);
    }
  }, [category_tag_list]);

  const get_category_tag_list = () => {
    category_tag_list_request(
      {
        language_id: current_language?.id,
      },
      true
    );
  };

  const handle_click = (category) => {
    set_default_category_name(category?.name);
    set_page_limit(6);
    set_fact_check_page_limit(6);
    if (category?.name !== "More" && category?.name !== "மேலும்") {
      set_category_id(category?.id);
    } else if (category?.name === "More" || category?.name === "மேலும்") {
      set_show_more(false);
      set_category_key((pre) => pre);
    }
    if (category?.name !== "More" && category?.name !== "மேலும்") {
      set_category_key(category?.name);
    }
    set_category_name(category?.name);
    if (category?.name === "More" || category?.name === "மேலும்") {
      set_categories_more([
        { id: "All", name: t("all") },
        ...category_tag_list.slice(0, 20),
        ...(show_more ? [] : [{ id: "More", name: t("more") }]),
      ]);
    }
  };

  return (
    <div className="category_tags_wrapper">
      {categories_more?.map((item, index) => (
        <div
          className={
            category_key === item?.name
              ? "selected_fact_check_party_wise_tag"
              : "fact_check_party_wise_tag"
          }
          onClick={() => handle_click(item)}
        >
          {item?.name}
        </div>
      ))}
    </div>
  );
};

export default FactCheckCategoryTag;
