import React from "react";
import FeaturedArticle from "@src/views/components/articles/featured_article";
import CategoryWiseArticles from "@src/views/components/articles/category_wise_articles";
import TrendingArticles from "@src/views/components/articles/trending_articles/trending_articles";
import RecommendedStories from "@src/views/components/articles/recommended_stories";
import JoinUs from "../components/custom_components/join_us/join_us";

const Articles = () => {
  return (
    <div>
      <FeaturedArticle />
      <CategoryWiseArticles />
      <TrendingArticles />
      <RecommendedStories />
      <JoinUs />
    </div>
  );
};

export default Articles;
