import { retrieve_item } from "@src/helpers/cryptos";
import { ROUTES } from "@src/route/my_routes";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const HomeVideosTitleRowContentMobileView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let language = retrieve_item("language");
  const handle_redirect = () => {
    navigate(ROUTES.VIDEOS);
  };

  return (
    <div className="home_videos_title_row">
      <div className="home_videos_title">
        <h1
          className={
            language?.name === "tamil"
              ? "home_videos_title_text_ta"
              : "home_videos_title_text"
          }
          // className="home_videos_title_text"
        >
          {t("videos_title")}
        </h1>
      </div>

      <div className="see_all_btn_position">
        <Button
          className="see_all_btn"
          type="default"
          onClick={handle_redirect}
        >
          {t("see_all")}
        </Button>
      </div>
    </div>
  );
};
export default HomeVideosTitleRowContentMobileView;
