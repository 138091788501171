import { FOOTER_LOGO_ICON, SIGNATORY } from '@src/helpers/image_constants'
import { ROUTES } from '@src/route/my_routes'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const FooterFirstColumn = () => {
    const navigate = useNavigate()
    const handle_redirect = () => {
        window.open("https://ifcncodeofprinciples.poynter.org/profile/youturn")
    }
    const handle_home = () => {
        navigate(ROUTES.HOME)
    }
    return (
        <div className='footer_first_column_container'>
            <img src={FOOTER_LOGO_ICON} className='footer_first_column_footer_logo_image' onClick={handle_home} />
            <img src={SIGNATORY} className='footer_first_column_signatory_image' onClick={handle_redirect} />
        </div>
    )
}

export default FooterFirstColumn
