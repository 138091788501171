import React from "react";
import RelatedAndRecentArticles from "@src/views/components/single_article/related_and_recent_articles";
import SingleArticleDetails from "@src/views/components/single_article/single_article_details";
import HomeDonation from "../components/home/home_donation.jsx/home_donation";
import JoinUs from "../components/custom_components/join_us/join_us";
const SingleArticle = () => {
  return (
    <div>
      <SingleArticleDetails />
      <div className="donation_card_mobile_view">
        <HomeDonation />
      </div>
      <RelatedAndRecentArticles />
      <JoinUs />
    </div>
  );
};

export default SingleArticle;
