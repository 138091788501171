import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_party_wise_fact_check_list_query } from "@src/services/graphql/graphql_fact_check";
import { useDynamicSelector } from "@src/services/redux";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const FactCheckPartyWiseButtonMobileView = () => {
  const { t } = useTranslation();
  const [page_limit, set_page_limit] = useState(5);
  let party_wise_fact_check_list_request = useAPIRequest(
    "get_party_wise_fact_check_list",
    get_party_wise_fact_check_list_query
  );
  const selected_party_tag = useDynamicSelector("selected_party_tag");
  const current_language = retrieve_item("language");
  const {
    items: party_wise_fact_check_list,
    loading: party_wise_fact_check_list_loading,
    pagination,
  } = useDynamicSelector("get_party_wise_fact_check_list");
  const { items: featured_fact_check } = useDynamicSelector(
    "get_featured_fact_check"
  );

  const handle_see_more_videos = () => {
    set_page_limit(page_limit + 5);
  };
  const get_party_wise_fact_check_list = () => {
    party_wise_fact_check_list_request(
      {
        page_number: 1,
        page_limit: page_limit,
        json: {
          language_id: current_language?.id,
          party_tag: !selected_party_tag?.id?.includes("More" || "All")
            ? selected_party_tag?.id
            : null,
          exclude_id: featured_fact_check?.[0]?.id || "",
        },
      },
      true
    );
  };
  useEffect(() => {
    get_party_wise_fact_check_list();
  }, [page_limit]);
  return (
    !party_wise_fact_check_list_loading &&
    pagination?.total_count > page_limit && (
      <div div className="exposing_fake_news_button_mobile_container">
        <Button
          className="exposing_fake_news_button_mobile"
          onClick={handle_see_more_videos}
        >
          {t("see_all")}
        </Button>
      </div>
    )
  );
};

export default FactCheckPartyWiseButtonMobileView;
