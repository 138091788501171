import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import { get_article_list_query } from "@src/services/graphql/graphql_home";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect } from "react";
import HomeArticleFirstColumn from "./home_article_first_column";
import HomeArticleTrendingCard from "./home_article_trending_card";
import HomeArticleSecondColumn from "./home_article_second_column";
import ExposingFakeNewsButton from "../home_fact_check/home_fact_check_desktop_view.jsx/exposing_fake_news_button";
import HomeArticleTrendingCardSkeleton from "./home_article_trending_card_skeleton";
import HomeArticleSecondColumnSkeleton from "./home_article_second_column_sekeleton";
import { useNavigate } from "react-router-dom";
import HomeArticleButton from "./home_article_button";

const HomeArticles = () => {
  const language = retrieve_item("language");
  const navigate = useNavigate();
  const { items: article_list, loading: article_list_loading } =
    useDynamicSelector("get_article_list");

  const article_data = article_list?.slice(1, 2);

  let home_article_list_request = useAPIRequest(
    "get_article_list",
    get_article_list_query
  );

  useEffect(() => {
    get_article_list();
  }, []);

  const get_article_list = () => {
    home_article_list_request(
      {
        page_number: 1,
        page_limit: 10,
        json: {
          language_id: language?.id,
        },
      },
      true
    );
  };

  const handle_click = () => {
    navigate(ROUTES.ARTICLES);
  };
  return (
    <>
      <div className="home_article_first_column_container">
        <div className="home_article_first_column_wrapper">
          <HomeArticleFirstColumn />

          <div className="home_article_second_column">
            {!article_list_loading ? (
              <HomeArticleSecondColumn list={article_data} />
            ) : (
              <HomeArticleSecondColumnSkeleton />
            )}
          </div>
        </div>
      </div>

      <div className="home_article_row_container">
        <div className="home_article_row_wrapper">
          {!article_list_loading
            ? article_list
                ?.slice(2, 6)
                .map((article, index) => (
                  <HomeArticleTrendingCard key={index} list={article} />
                ))
            : article_list
                ?.slice(4, 8)
                .map((article, index) => (
                  <HomeArticleTrendingCardSkeleton key={index} />
                ))}
        </div>
      </div>
      <ExposingFakeNewsButton on_click={handle_click} />
      <div className="home_article_button_in_mobile">
        <HomeArticleButton />
      </div>
    </>
  );
};

export default HomeArticles;
