import { date_format, ensure_https, split_title } from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";
import { ROUTES } from "@src/route/my_routes";
import { Button, Col, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { LuEye } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const ExposingFakeNewsFirstColumnMobileView = (props) => {
  const { list } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const image_url = ensure_https(list?.[0]?.thumbnail_img);
  const published_date_format = list?.[0]?.published_date_time
    ? list?.[0]?.published_date_time
    : list?.[0]?.created_date_time;
  const { dark_mode } = useDynamicSelector("dark_mode");

  const handle_click_page_redirect = () => {
    const url = `${ROUTES.SINGLE_FACT_CHECK}/${list?.[0]?.perma_link}.html`;
    window.open(url, "_blank");
    // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${list?.[0]?.perma_link}.html`);
  };

  return (
    <Row className="inner_row_content" onClick={handle_click_page_redirect}>
      <img
        className="exposing_fake_news_first_column_image"
        src={image_url || BROKEN_IMAGE}
      />
      <Col className="exposing_fake_news_first_column_details_mobile_view">
        <Row className="exposing_fake_news_first_column_details_row">
          <Typography
            className={`exposing_fake_news_first_column_title ${
              dark_mode ? "dark_mode_card" : ""
            }`}
          >
            {split_title(list?.[0]?.title)}
          </Typography>
        </Row>
        <Row className="exposing_fake_news_first_column_details_wrapper">
          <Col className="exposing_fake_news_first_column_view_count">
            <Typography
              className={`exposing_fake_news_first_column_writer_name_and_views ${
                dark_mode ? "dark_mode_card" : ""
              }`}
            >
              {list?.[0]?.views}
            </Typography>
            <LuEye className="exposing_fake_news_first_column_view_icon" />
          </Col>
          <Col className="exposing_fake_news_first_column_writer_name_and_views">{`${
            list?.[0]?.staff_profile?.name ||
            list?.[0]?.user_profile?.name ||
            "youturn Editorial"
          } | ${date_format(published_date_format)}`}</Col>
          <Col>
            <Button className="exposing_fake_news_first_column_button">
              {t("fact_check_button_text")}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ExposingFakeNewsFirstColumnMobileView;
