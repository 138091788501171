import { useDynamicSelector } from "@src/services/redux";
import VerticalStoryCard from "@src/views/ui/vertical_story_card";
import SlotCard from "@src/views/ui/slot_card";
import React from "react";
import Donation from "@src/views/ui/donation/donation";
import { useTranslation } from "react-i18next";
import FactCheckPartyWiseSecondColumnSkeletonLoading from "../../fact_check/fact_check_party_wise/fact_check_party_wise_loading/fact_check_party_wise_second_column_loading";
import FactCheckPartyWiseFirstColumnSkeletonLoading from "../../fact_check/fact_check_party_wise/fact_check_party_wise_loading/fact_check_party_wise_first_column_loading";

const SearchResultRecentlyAddedStoriesDesktopView = () => {
  const { t } = useTranslation();

  const {
    items: recent_fact_check_list,
    loading: recent_fact_check_list_loading,
  } = useDynamicSelector("get_recent_fact_check_result_list");

  return (
    <div className="recommended_story_desktop_container">
      <div className="recommended_story_desktop_title_container">
        <h2>{t("recommended_stories")}</h2>
        <div className="recommended_story_desktop_first_column_container">
          <div style={{ width: "40%" }}>
            {recent_fact_check_list_loading ? (
              <FactCheckPartyWiseFirstColumnSkeletonLoading />
            ) : (
              <SlotCard {...recent_fact_check_list?.[0]} type={"fact_check"} />
            )}
          </div>

          <div
            className="display-flex flex-column justify-content-between"
            style={{ width: "60%" }}
          >
            {recent_fact_check_list_loading ? (
              <FactCheckPartyWiseSecondColumnSkeletonLoading />
            ) : (
              recent_fact_check_list?.slice(1, 5)?.map((list) => {
                return <VerticalStoryCard {...list} />;
              })
            )}
          </div>
        </div>
      </div>
      <Donation />
    </div>
  );
};

export default SearchResultRecentlyAddedStoriesDesktopView;
