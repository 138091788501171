import { Button, Divider, Row } from "antd";
import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

const ExposingFakeNewsButton = (props) => {
  const { on_click } = props;
  const { t } = useTranslation();
  return (
    <Row className="exposing_fake_news_divider_container">
      <Row className="exposing_fake_news_divider_wrapper">
        <Divider className="exposing_fake_news_divider">
          <Button className="exposing_fake_news_button" onClick={on_click}>
            {t("see_all")}
          </Button>
        </Divider>
      </Row>
    </Row>
  );
};

export default ExposingFakeNewsButton;
