import { amountFormatWithSymbol, date_format } from "@src/helpers/functions";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import { get_my_donation_query } from "@src/services/graphql/graphql_donation";
import { useDynamicSelector } from "@src/services/redux";
import { Button, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserProfileDonate from "../donation/user_profile_donate";

const UserProfileDonationCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [donate_amt_option, set_donate_amt_option] = useState(false);
  const [is_user_details_modal_open, set_user_details_modal_open] =
    useState(false);

  const { status: create_donation_status } =
    useDynamicSelector("create_donation");
  const { items: donation_list, loading: donation_loading } =
    useDynamicSelector("get_all_donations");
  const { dark_mode } = useDynamicSelector("dark_mode");

  useEffect(() => {
    if (create_donation_status === "Success") {
      set_donate_amt_option(false);
    }
  }, [create_donation_status]);

  useEffect(() => {
    get_all_my_donation();
  }, []);

  const handle_donate_click = () => {
    set_user_details_modal_open(true);
  };

  const get_donations_request = useAPIRequest(
    "get_all_donations",
    get_my_donation_query
  );

  const get_all_my_donation = () => {
    get_donations_request({ page_limit: 5, page_number: 1 }, true);
  };

  const handle_redirect_donations = () => {
    navigate(ROUTES.USER_DONATION);
  };

  const donate_click = () => {
    set_donate_amt_option(true);
  };

  return (
    <div
      className={`donations-card ${dark_mode ? "dark_mode_card" : ""}`}
      id="donations"
    >
      <div className={`donations-header ${dark_mode ? "dark_mode_card" : ""}`}>
        <h6
          className={`user_contribute_table_title_text ${
            dark_mode ? "light_mode_text" : "black_text"
          }`}
        >
          {t("donations")}
        </h6>

        {donation_list?.length > 0 && (
          <h6 id="view-all" onClick={handle_redirect_donations}>
            {t("view_all")}
          </h6>
        )}
      </div>
      {donation_list?.length > 0 ? (
        <div className="donations-list">
          {donation_loading
            ? Array.from({ length: 3 }, (_, index) => (
                <div key={index} className="donation-item">
                  <Skeleton active paragraph={{ rows: 1 }} title={false} />
                </div>
              ))
            : donation_list?.map((donation, index) => (
                <div key={index} className="donation-item">
                  <span>{date_format(donation?.created_date_time)}</span>
                  <span>{amountFormatWithSymbol(donation?.amount)}</span>
                </div>
              ))}
        </div>
      ) : (
        !donate_amt_option && (
          <div className="no_transaction_container">
            <div className="no_transaction_wrapper">
              <span className="no_transaction">
                {t("no_recent_transactions_contribute_today")}
              </span>
              <Button
                className={"donate_button load_more_button"}
                onClick={donate_click}
              >
                {t("donate")}
              </Button>
            </div>
          </div>
        )
      )}
      <UserProfileDonate
        get_all_my_donation={get_all_my_donation}
        show_donation={donate_amt_option}
      />
    </div>
  );
};

export default UserProfileDonationCard;
