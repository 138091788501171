import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";
import {
  date_format,
  ensure_https,
  split_content,
} from "@src/helpers/functions";
import { related_article_list_query } from "@src/services/graphql/graphql_single_article";
import { ROUTES } from "@src/route/my_routes";
import { useNavigate } from "react-router-dom";
import RelatedFactCheckSkeleton from "../../single_fact_check/single_fact_check_report_donation/related_fact_check_skeleton";

const RelatedArticleList = () => {
  const language = retrieve_item("language");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dark_mode } = useDynamicSelector("dark_mode");

  const { items: related_article_list, loading: related_article_loading } =
    useDynamicSelector("get_related_article_list");

  // let related_article_list_request = useAPIRequest(
  //   "get_related_article_list",
  //   related_article_list_query
  // );

  // useEffect(() => {
  //   get_related_article_list();
  // }, []);

  // const get_related_article_list = () => {
  //   related_article_list_request(
  //     {
  //       page_limit: 4,
  //       json: {
  //         language_id: language?.id,
  //       },
  //     },
  //     true
  //   );
  // };
  const navigate_to_detail_page = (perma_link) => {
    navigate(`${ROUTES.SINGLE_ARTICLE}/${perma_link}.html`);
  };

  return (
    <div className="related_fact_check_banner">
      <div className="related_fact_check_title_container">
        <h4>{t("related_articles")}</h4>
      </div>
      <div className="related_fact_check_container">
        {related_article_loading ? (
          <>
            {related_article_list?.map((_, index) => (
              <RelatedFactCheckSkeleton key={index} />
            ))}
          </>
        ) : (
          related_article_list &&
          related_article_list.map((article, index) => (
            <Row
              className="related_fact_check_card"
              onClick={() => navigate_to_detail_page(article?.perma_link)}
            >
              <img
                src={ensure_https(article?.thumbnail_img)}
                className="home_article_trending_image"
              />

              <Col className="home_article_trending_details_container">
                <div className="home_article_trending_title_container">
                  <Typography
                    className={`home_trending_title_text ${
                      dark_mode ? "dark_mode_card" : ""
                    }`}
                  >
                    {split_content(article?.title, 0, 7)}
                  </Typography>
                </div>
                <div className="home_article_trending_author_container">
                  <Typography
                    className={`home_trending_title_text ${
                      dark_mode ? "dark_mode_card" : ""
                    }`}
                  >
                    {`${
                      article?.staff_profile?.name ||
                      article?.user_profile?.name ||
                      "Youturn Editorial"
                    } | ${date_format(article?.created_date_time)}`}
                  </Typography>
                </div>
              </Col>
            </Row>
          ))
        )}
      </div>
    </div>
  );
};

export default RelatedArticleList;
