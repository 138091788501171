import React from "react";
import Editor from "../editor";

const SpreadNews = (props) => {
  const { label, content, is_disabled, set_content } = props;
  return (
    <Editor
      is_disabled={is_disabled || false}
      value={content}
      set_value={set_content}
      label={label}
    />
  );
};
export default SpreadNews;
