import { Form, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ContactNo = () => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name="mobile"
      rules={[
        { required: true, message: t("required_number") },
        {
          pattern: /^\d{10}$/,
          message: t("number_validation"),
        },
      ]}
    >
      <InputNumber
        style={{ width: "100%", display: "flex", alignItems: "center" }}
        className="feedback-input"
        placeholder={t("mobile")}
        maxLength={10}
        controls={false}
        changeOnWheel={false}
      />
    </Form.Item>
  );
};

export default ContactNo;
