import { date_format, ensure_https, split_title } from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { Col, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const FactCheckPartyWiseSecondColumnMobileView = (props) => {
  const { list, type } = props;
  const navigate = useNavigate();
  const image_url = ensure_https(list?.thumbnail_img);

  const handle_redirect = () => {
    if (type === "article") {
      const url = `${ROUTES.SINGLE_ARTICLE}/${list?.perma_link}.html`;
      window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_ARTICLE}/${list?.perma_link}.html`);
    } else {
      const url = `${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`;
      window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`);
    }
  };

  return (
    <Row
      className="exposing_fake_news_mobile_view_container"
      onClick={handle_redirect}
    >
      <Col className="exposing_fake_news_mobile_view_first_col">
        <img
          className="exposing_fake_news_mobile_view_first_col_img"
          src={image_url || BROKEN_IMAGE}
        />
      </Col>
      <Col className="exposing_fake_news_mobile_view_second_col">
        <text className="exposing_fake_news_mobile_view_title">
          {split_title(list?.title, 60, 40)}
        </text>
        <text className="exposing_fake_news_mobile_view_author_name">{`${
          list?.staff_profile?.name || list?.user_profile?.name
        } | ${date_format(list?.published_date_time)}`}</text>
      </Col>
    </Row>
  );
};

export default FactCheckPartyWiseSecondColumnMobileView;
