import { retrieve_item } from "@src/helpers/cryptos";
import { useDynamicSelector } from "@src/services/redux";
import React from "react";

const UserContributionTitle = ({ title }) => {
  const language = retrieve_item("language");
  const { dark_mode } = useDynamicSelector("dark_mode");

  return (
    <div className="user_contribution_title_container">
      <h2
        className={
          language?.name === "tamil"
            ? `${dark_mode ? "white_text" : ""} user_contribution_title_ta`
            : `${dark_mode ? "white_text" : ""} user_contribution_title`
        }
      //  className="user_contribution_title"
      >
        {title}
      </h2>
    </div>
  );
};

export default UserContributionTitle;
