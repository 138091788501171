import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import {
  get_party_tag_list_query,
  get_party_wise_fact_check_list_query,
} from "@src/services/graphql/graphql_fact_check";
import { dynamic_set, useDynamicSelector } from "@src/services/redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const FactCheckPartyTags = (props) => {
  const { set_party_id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const current_language = retrieve_item("language");
  const party_tag_list_request = useAPIRequest(
    "get_party_tag_list",
    get_party_tag_list_query
  );
  let party_wise_fact_check_list_request = useAPIRequest(
    "get_party_wise_fact_check_list",
    get_party_wise_fact_check_list_query
  );
  const [selected_category, set_selected_category] = useState({
    id: t("all"),
    name: t("all"),
  });
  const [categories, set_categories] = useState([]);

  const { items: party_tag_list, loading: party_tag_list_loading } =
    useDynamicSelector("get_party_tag_list");
  useEffect(() => {
    get_party_tag_list();
  }, []);

  useEffect(() => {
    if (party_tag_list) {
      set_categories([
        { id: "All", name: t("all") },
        ...party_tag_list,
        // { id: "More", name: t("more") },
      ]);
    }
  }, [party_tag_list]);

  // useEffect(() => {
  //   if (selected_category?.id === "More") {
  //     set_categories(categories?.filter((list) => list?.id !== "More"))
  //   } else {
  //     set_categories([...categories, { id: "See Less", name: "See Less" }]);
  //   }
  // }, [selected_category])

  const get_party_tag_list = () => {
    party_tag_list_request(
      {
        language_id: current_language?.id,
      },
      true
    );
  };

  const get_party_wise_fact_check_list = (tag) => {
    party_wise_fact_check_list_request(
      {
        page_number: 1,
        page_limit: 10,
        json: {
          language_id: current_language?.id,
          party_tag: tag?.id,
        },
      },
      true
    );
  };
  const handle_click_tag = (tag) => {
    dispatch(dynamic_set("selected_party_tag", tag));
    if (!tag?.id?.includes("All") && !tag?.id?.includes("More")) {
      get_party_wise_fact_check_list(tag);
    } else if (!tag?.id?.includes("More")) {
      get_party_wise_fact_check_list(null);
    }
    set_selected_category(tag);
  };

  return (
    <div className="fact_check_party_wise_tag_container">
      {categories?.map((tag) => {
        return (
          <div
            className={
              selected_category?.name === tag?.name
                ? "selected_fact_check_party_wise_tag"
                : "fact_check_party_wise_tag"
            }
            onClick={() => handle_click_tag(tag)}
          >
            <h6>{tag?.name}</h6>
          </div>
        );
      })}
    </div>
  );
};

export default FactCheckPartyTags;
