import { retrieve_item } from "@src/helpers/cryptos";
import {
  amountFormatWithSymbol,
  initiate_request_razorpay_payment,
} from "@src/helpers/functions";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import AppreciationModal from "@src/views/components/custom_components/appreciation_modal/appreciation_modal";
import UserDetailsFormModal from "@src/views/components/home/home_donation.jsx/user_details_form_modal/user_details_form_modal";
import { Button, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const Donation = () => {
  const { t } = useTranslation();
  const [selected_amount, set_selected_amount] = useState(null);
  const [donation_modal, set_donation_modal] = useState(false);
  const [payment_response, set_payment_response] = useState("");
  const [appreciation_modal_open, set_appreciation_modal] = useState(false);
  const [popper_status, set_popper_status] = useState(false);
  const dispatch = useDispatch();
  const { mobile_number } = useDynamicSelector("user_entered_mobile_number");

  const { status: create_donation_status, order_id } =
    useDynamicSelector("create_donation");
  const payment_status = payment_response.razorpay_signature;

  const handle_close_donation_modal = () => {
    set_donation_modal(false);
  };
  const language = retrieve_item("language");

  useEffect(() => {
    if (order_id) {
      initiate_request_razorpay_payment(
        order_id,
        set_payment_response,
        mobile_number
      );
      dispatch(dynamic_clear("create_donation"));
    }
  }, [order_id]);
  useEffect(() => {
    if (payment_status) {
      set_appreciation_modal(true);
      set_popper_status(true);
      setTimeout(() => {
        set_appreciation_modal(false);
        set_popper_status(false);
      }, 10000);
    }
  }, [payment_status]);
  const AmountValueCard = () => {
    let amount_values = [199, 499, 999, 1999];
    const handle_click = (value) => {
      set_selected_amount(value);
    };

    return (
      <div className="amount_card_container">
        {amount_values.map((list, index) => {
          return (
            <div
              className={
                selected_amount === list
                  ? "amount_selected"
                  : "amount_card_content"
              }
              key={index}
              onClick={() => handle_click(list)}
            >
              {amountFormatWithSymbol(list)}
            </div>
          );
        })}
      </div>
    );
  };
  const AmountInput = () => {
    const handle_change_amount = (value) => {
      set_selected_amount(value);
    };
    return (
      <div className="amount_input_container">
        <InputNumber
          className="amount_input"
          maxLength={10}
          controls={false}
          changeOnWheel={false}
          onChange={handle_change_amount}
          value={selected_amount}
          placeholder={t("enter_amount")}
        />
      </div>
    );
  };
  const DonationFooter = () => {
    const handle_donate = () => {
      set_donation_modal(true);
    };
    return (
      <div
        className={
          language?.name == "tamil"
            ? "donation_footer_container_ta"
            : "donation_footer_container"
        }
      >
        <Button
          className="donate_button load_more_button"
          disabled={selected_amount !== null ? false : true}
          onClick={handle_donate}
        >
          {t("donate")}
        </Button>
        <text>{t("donation_note")}</text>
      </div>
    );
  };
  return (
    <div className="donation_main_container">
      <div
        className={
          language.name === "tamil"
            ? "donation_first_row_container_ta"
            : "donation_first_row_container"
        }
      >
        <text className="donation_first_row_content">
          {t("donate_top_content")}
        </text>
      </div>
      <div
        className={
          language.name === "tamil"
            ? "donation_second_row_container_ta"
            : "donation_second_row_container"
        }
      >
        <h1>#</h1>
        <h1>{t("fight_against_fake_news")}</h1>
      </div>
      <div
        className={
          language.name === "tamil"
            ? "donation_third_row_container_ta"
            : "donation_third_row_container"
        }
      >
        <h5>{t("make_a_small_contribution")}</h5>
        <AmountValueCard />
        <h6>{t("or")}</h6>
        {AmountInput()}
      </div>
      <DonationFooter />
      <UserDetailsFormModal
        modal_open={donation_modal}
        on_cancel={set_donation_modal}
        amount={selected_amount}
      />
      <AppreciationModal
        subtitle={t("donation_success_title")}
        message={t("donation_success_content")}
        popper_status={popper_status}
        appreciation_modal_open={appreciation_modal_open}
        set_appreciation_modal={set_appreciation_modal}
      />
    </div>
  );
};

export default Donation;
