import { Skeleton } from "antd";
import React from "react";

const FeaturedFactCheckImageComponentSkeleton = () => {
  return (
    <div className="featured_fact_check_image_component featured_story_image_component">
      <Skeleton.Image
        className="featured_fact_check_image featured_story_image_skeleton"
        active
      />
    </div>
  );
};

export default FeaturedFactCheckImageComponentSkeleton;
