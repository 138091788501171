import React from "react";
import SingleArticleSecondColumn from "./single_article_second_column";
import SingleArticleFirstColumn from "./single_article_first_column";
import { ROUTES } from "@src/route/my_routes";
import FeaturedArticleImageComponentSkeleton from "../../articles/featured_article/featured_article_skeleton_loading/featured_article_image_component_skeleton";
import { useDynamicSelector } from "@src/services/redux";
import SingleArticleFirstColumnSkeleton from "./single_article_first_column_skeleton";

const SingleArticleBackGroundContainer = (props) => {
  const { handle_save } = props;
  const { loading: article_loading } = useDynamicSelector("get_article");

  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },
    {
      path: "",
      breadcrumb_name: "Fact Check",
    },
  ];
  return (
    <div className="single_article_first_container">
      <div className="single_article_back_ground_container"></div>
      <div className="single_article_top_details_wrapper">
        {article_loading ? (
          <SingleArticleFirstColumnSkeleton />
        ) : (
          <SingleArticleFirstColumn handle_save={handle_save} />
        )}
        {article_loading ? (
          <FeaturedArticleImageComponentSkeleton />
        ) : (
          <SingleArticleSecondColumn />
        )}
      </div>
    </div>
  );
};

export default SingleArticleBackGroundContainer;
