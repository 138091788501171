import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_category_list_query } from "@src/services/graphql/graphql_articles";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CategoryList = (props) => {
  const {
    set_category_id,
    set_default_category_name,
    set_category_name,
    set_load_more_page_limit,
    set_initial_page_limit,
    set_page_limit,
  } = props;
  let language = retrieve_item("language");
  const { t } = useTranslation();
  const [show_more, set_show_more] = useState(true);
  const [complete_category_list, set_complete_category_list] = useState();
  const [category_key, set_category_key] = useState(t("all"));
  const { items: category_list, loading: category_list_loading } =
    useDynamicSelector("get_category_list");

  let category_list_request = useAPIRequest(
    "get_category_list",
    get_category_list_query
  );

  useEffect(() => {
    get_category_list();
  }, []);

  useEffect(() => {
    if (category_list) {
      set_complete_category_list([
        { id: "All", name: t("all") },
        ...category_list?.slice(0, 7),
        { id: "More", name: t("more") },
      ]);
    }
  }, [category_list]);

  const get_category_list = () => {
    category_list_request(
      {
        language_id: language?.id,
      },
      true
    );
  };

  const handle_category_selection = (category) => {
    set_default_category_name(category?.name);
    set_page_limit(6);
    set_load_more_page_limit(6);
    set_initial_page_limit(7);
    if (category?.name !== "More" && category?.name !== "மேலும்") {
      set_category_id(category?.id);
    } else if (category?.name === "More" || category?.name === "மேலும்") {
      set_show_more(false);
      set_category_key((pre) => pre);
    }
    if (category?.name !== "More" && category?.name !== "மேலும்") {
      set_category_key(category?.name);
    }
    set_category_name(category?.name);

    if (category?.name === "More" || category?.name === "மேலும்") {
      set_complete_category_list([
        { id: "All", name: t("all") },
        ...category_list?.slice(0, 20),
        ...(show_more ? [] : [{ id: "More", name: t("more") }]),
      ]);
    }
  };

  return (
    <div className="category_tags_wrapper">
      {complete_category_list?.map((item, index) => (
        <div
          className={
            category_key === item?.name
              ? "selected_fact_check_party_wise_tag"
              : "fact_check_party_wise_tag"
          }
          onClick={() => handle_category_selection(item)}
        >
          {item?.name}
        </div>
      ))}
    </div>
  );
};

export default CategoryList;
