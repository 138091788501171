import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import { category_wise_article_list_query } from "@src/services/graphql/graphql_articles";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect, useState } from "react";
import CategoryWiseFactCheckCard from "../fact_check/browse_categories/category_wise_fact_check_card";
import { Button, Divider, Empty, Skeleton } from "antd";
import FactCheckCategoryTagsMobileView from "../fact_check/browse_categories/fact_check_category_tags_mobile_view";
import { useTranslation } from "react-i18next";
import { SEARCH_IMAGE } from "@src/helpers/image_constants";
import { useNavigate } from "react-router-dom";

const ArticleList = (props) => {
  const {
    default_category_name,
    set_category_id,
    category_id,
    set_initial_page_limit,
    handle_see_all_articles,
    page_limit,
  } = props;
  let language = retrieve_item("language");
  const { t } = useTranslation();
  const [filtered_item, set_filtered_item] = useState([]);
  const navigate = useNavigate();
  const {
    items: category_wise_article_list,
    loading: category_wise_article_list_loading,
    pagination: category_wise_article_list_pagination,
  } = useDynamicSelector("get_category_wise_article_list");

  const { items: featured_article, loading: featured_article_loading } =
    useDynamicSelector("get_featured_article");

  useEffect(() => {
    if (
      category_wise_article_list &&
      category_wise_article_list?.length > 0 &&
      (!default_category_name ||
        default_category_name === "All" ||
        default_category_name === "அனைத்தும்" ||
        default_category_name === "மேலும்" ||
        default_category_name === "More")
    ) {
      set_filtered_item([...category_wise_article_list]);
    } else if (
      category_wise_article_list &&
      category_wise_article_list?.length > 0
    ) {
      set_filtered_item([...category_wise_article_list]);
    } else if (
      !category_wise_article_list_loading &&
      !category_wise_article_list?.length
    ) {
      set_filtered_item([]);
    }
  }, [category_wise_article_list, default_category_name, category_id]);

  let category_wise_article_list_request = useAPIRequest(
    "get_category_wise_article_list",
    category_wise_article_list_query
  );

  useEffect(() => {
    get_category_wise_article_list();
  }, [category_id, page_limit, featured_article?.[0]?.id]);

  const get_category_wise_article_list = () => {
    category_wise_article_list_request(
      {
        page_limit: page_limit,
        json: {
          category_tag:
            category_id === "All" || category_id === "More"
              ? null
              : category_id,
          language_id: language?.id,
          exclude_id: featured_article?.[0]?.id,
        },
      },
      true
    );
  };

  const navigate_to_detail_page = (type, perma_link) => {
    navigate(`${ROUTES.SINGLE_ARTICLE}/${perma_link}.html`);
    // if (type === "article") {
    // } else if (type === "fact-check") {
    //   history.push(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
    // }
  };

  return (
    <>
      <div className="fact_check_party_tags_in_mobile">
        <FactCheckCategoryTagsMobileView
          get_category_wise_fact_check_list={get_category_wise_article_list}
          set_category_id={set_category_id}
        />
      </div>
      <div className="category_wise_list_cards_wrapper">
        {filtered_item?.map((item, index) => (
          <CategoryWiseFactCheckCard key={index} list={item} type={"article"} />
        ))}
        {category_wise_article_list?.length <= 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "300px",
            }}
          >
            <Empty description="No data" />
          </div>
        )}
        {category_wise_article_list_loading &&
          Array.from({ length: 6 }, (_, index) => (
            <Skeleton.Input
              key={index}
              active
              className="category_wise_card_container_skeleton"
            />
          ))}
      </div>
      <div style={{ display: "flex" }}>
        <Divider className="see_more_button_divider">
          {category_wise_article_list_pagination?.total_count >
            filtered_item?.length && (
            <Button
              className="see_more_button"
              onClick={handle_see_all_articles}
            >
              {t("see_more")}
            </Button>
          )}
        </Divider>
      </div>
    </>
  );
};

export default ArticleList;
