import React from "react";
import Policy from "./policies/policy";
import FactCheck from "./policies/fact_check";
import WhatWeCheck from "./policies/what_we_check";
import FactCheckCategorisation from "./policies/fact_check_categorisation";
import StayConnect from "./stay_connect_mobil";
import Funding from "./policies/funding";
import Notes from "./policies/notes";

function Policies() {
  return (
    <div className="policies_container">
      <Notes />
      <Policy />
      <FactCheck />
      <WhatWeCheck />
      <StayConnect />
      <FactCheckCategorisation />
      <Funding />
    </div>
  );
}

export default Policies;
