import React from "react";
import { useTranslation } from "react-i18next";

const UserContributionFeaturedTitle = () => {
  const { t } = useTranslation();
  return (
    <div className="featured_video_title_container">
      <h2 className="featured_article_title">{t("user_contribution")}</h2>
    </div>
  );
};

export default UserContributionFeaturedTitle;
