import { SITE_URL } from "@src/helpers/constants";
import { retrieve_item } from "@src/helpers/cryptos";
import {
  format_views_count,
  get_reading_time,
  get_share_content,
} from "@src/helpers/functions";
import {
  REPORT_ICON_COLOR,
  SAVE_ICON_FILLED,
  SHARE_ICON_COLOR,
} from "@src/helpers/image_constants";
import {
  dynamic_clear,
  dynamic_set,
  useDynamicSelector,
} from "@src/services/redux";
import { Popover, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiFillClockCircle } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import ReportModal from "../../single_fact_check/single_fact_check_report_donation/report_modal";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { readingTime } from "reading-time-estimator";

const ReportShareContainerMobileView = (props) => {
  const { pathname, reading_time } = useLocation();
  let user_id = retrieve_item("user_id");
  const { t } = useTranslation();
  let language = retrieve_item("language");
  const [share_url, set_share_url] = useState();
  const [show_report_popup, set_show_report_popup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const article = useDynamicSelector("get_article");
  const { status: create_report_status } =
    useDynamicSelector("report_mutation");
  const [show_copy_link_popup, set_copy_link_popup] = useState(false);
  const [is_copy_link, set_is_copy_link] = useState(false);

  const parser = new DOMParser();
  const doc = parser.parseFromString(article?.content, "text/html");

  const result = readingTime(
    doc?.body?.textContent,
    language?.name === "tamil" ? 20 : 100
  );

  const copy_to_clipboard = () => {
    const redirect_url = window.location.href;
    navigator.clipboard?.writeText(redirect_url);
    set_is_copy_link(true);
  };
  useEffect(() => {
    if (is_copy_link) {
      set_copy_link_popup(true);
      setTimeout(() => {
        set_copy_link_popup(false);
        set_is_copy_link(false);
      }, 500);
    }
  }, [is_copy_link]);
  useEffect(() => {
    if (create_report_status === "Success") {
      dispatch(dynamic_clear("report_mutation"));
      set_show_report_popup(true);
      setTimeout(() => {
        set_show_report_popup(false);
      }, 5000);
    }
  }, [create_report_status]);

  const handle_report_click = () => {
    if (user_id) {
      dispatch(dynamic_set("report_modal_state", { is_report_modal: true }));
    } else {
      const queryParams = new URLSearchParams();
      queryParams.set("type", "report");
      queryParams.set("types", "article");
      queryParams.set("redirecturl", window.location.pathname);
      navigate({
        pathname: "/login",
        search: `?${queryParams.toString()}`,
      });
    }
  };

  useEffect(() => {
    if (language?.name === "tamil") {
      const site_url = new URL(SITE_URL);
      const domain = site_url.hostname;
      const link_without_html = pathname?.replace(".html", "");
      const url = `https://share.${domain}${link_without_html}`;
      set_share_url(url);
    } else {
      const site_url = new URL(SITE_URL);
      const domain = site_url.hostname;
      const link_without_html = pathname?.replace(".html", "");
      const url = `https://share.${domain}/en${link_without_html}`;
      set_share_url(url);
    }
  }, [pathname, SITE_URL]);

  const share_content = get_share_content(
    article?.title,
    share_url,
    article?.thumbnail_img,
    copy_to_clipboard,
    show_copy_link_popup
  );

  return (
    <div className="report_share_container_mobile_view">
      <div className="report_share_first_column_container">
        <div className="single_article_icons">
          <LuEye color="red" />
          <text className="single_article_view_count">
            {format_views_count(article?.views)}
          </text>
        </div>
        <div className="single_article_icons">
          <AiFillClockCircle color="gray" />
          <text className="single_article_reading_time">
            {get_reading_time(article?.reading_time, t)}
          </text>
        </div>
      </div>
      <div className="report_share_second_column_container">
        <Tooltip title={"Report Submitted"} open={show_report_popup}>
          <img
            style={{ width: "20px" }}
            src={REPORT_ICON_COLOR}
            onClick={handle_report_click}
          />
        </Tooltip>
        <Popover placement="top" content={share_content} trigger="hover">
          <img style={{ width: "20px" }} src={SHARE_ICON_COLOR} />
        </Popover>
      </div>
      {/* <ReportModal
        model_open={report_modal_open}
        set_modal_open={set_report_modal_open}
        article_id={article?.id}
      /> */}
    </div>
  );
};

export default ReportShareContainerMobileView;
