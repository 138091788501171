// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 0px) and (max-width: 767px) {
  .contact_us_section_wrapper {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    padding: 0% !important;
  }
  .contact_us_form_container {
    width: 100% !important;
    gap: 15px;
    padding: 5%;
  }
  .contact_us_header {
    /* height: 180px !important; */
  }
  .contact_us_donation_wrapper {
    width: 100%;
  }
  .contact_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
  }
  .contact_us_description_ta {
    font-size: 12px !important;
    color: #ffffff;
  }
  .leave_us_message_ta {
    font-size: 15px !important;
    font-weight: 700 !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_767/screen_767_contact_us.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;IACX,wBAAwB;IACxB,iCAAiC;IACjC,yCAAyC;IACzC,sBAAsB;EACxB;EACA;IACE,sBAAsB;IACtB,SAAS;IACT,WAAW;EACb;EACA;IACE,8BAA8B;EAChC;EACA;IACE,WAAW;EACb;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,oBAAoB;EACtB;EACA;IACE,0BAA0B;IAC1B,cAAc;EAChB;EACA;IACE,0BAA0B;IAC1B,2BAA2B;EAC7B;AACF","sourcesContent":["@media only screen and (min-width: 0px) and (max-width: 767px) {\r\n  .contact_us_section_wrapper {\r\n    width: 100%;\r\n    display: flex !important;\r\n    flex-direction: column !important;\r\n    justify-content: space-between !important;\r\n    padding: 0% !important;\r\n  }\r\n  .contact_us_form_container {\r\n    width: 100% !important;\r\n    gap: 15px;\r\n    padding: 5%;\r\n  }\r\n  .contact_us_header {\r\n    /* height: 180px !important; */\r\n  }\r\n  .contact_us_donation_wrapper {\r\n    width: 100%;\r\n  }\r\n  .contact_info {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    width: 100%;\r\n    padding-bottom: 20px;\r\n  }\r\n  .contact_us_description_ta {\r\n    font-size: 12px !important;\r\n    color: #ffffff;\r\n  }\r\n  .leave_us_message_ta {\r\n    font-size: 15px !important;\r\n    font-weight: 700 !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
