import React, { useEffect } from "react";
import UserNameInput from "./user_details_form/user_name_input";
import UserMobileNoInput from "./user_details_form/user_mobile_no_input";
import UserAddressInput from "./user_details_form/user_address_input";
import UserDetailsSubmitButton from "./user_details_form/user_details_submit_button";
import { Form } from "antd";
import { create_donation_mutation } from "@src/services/graphql/graphql_donation";
import { useAPIRequest } from "@src/helpers/hooks";
import { dynamic_set, useDynamicSelector } from "@src/services/redux";
import { useDispatch } from "react-redux";

const HomeDonationUserDetailsForm = (props) => {
  const { modal_close, amount,modal_open } = props;
  const [form] = Form.useForm();
  const { user_profile } = useDynamicSelector("get_user");
  const dispatch = useDispatch()
  const handle_user_detail_submit = (values) => {
    dispatch(dynamic_set("user_entered_mobile_number", { mobile_number: values?.mobile?.toString() }))
    modal_close(false);
    create_donation(values);
    form.resetFields()
  };

  let create_donation_request = useAPIRequest(
    "create_donation",
    create_donation_mutation
  );

  const create_donation = (values) => {
    create_donation_request(
      {
        amount: parseInt(amount),
        mobile: String(values?.mobile),
        address: values?.address,
      },
      true
    );
  };
  useEffect(() => {
    form.setFieldsValue(user_profile)
  }, [amount,modal_open,user_profile])

  return (
    <div className="custom_donation_form">
      <Form
        form={form}
        onFinish={handle_user_detail_submit}
        id="donation_form"
        layout="vertical"
      >
        <UserNameInput />
        <UserMobileNoInput />
        <UserAddressInput />
        <UserDetailsSubmitButton modal_close={modal_close} />
      </Form>
    </div>
  );
};

export default HomeDonationUserDetailsForm;
