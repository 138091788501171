import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ items, height }) => {
  const generateBreadcrumbs = () => {
    return items.map((item, index) => {
      const isLast = index === items.length - 1;
      return (
        <Breadcrumb.Item className="breadcrumb_content" key={item.path}>
          {isLast ? (
            item.breadcrumb_name
          ) : (
            <Link className="breadcrumb_link" to={item.path}>
              {item.breadcrumb_name}
            </Link>
          )}
        </Breadcrumb.Item>
      );
    });
  };

  return (
    <div className="breadcrumb_container" style={{ height: height }}>
      <Breadcrumb className="breadcrumb_display_props">
        {generateBreadcrumbs()}
      </Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;
