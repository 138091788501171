import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import { Skeleton } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const UserContributionCard = () => {
  const { article_count, fact_check_count, loading } = useDynamicSelector(
    "get_user_dashboard_count"
  );
  const { dark_mode } = useDynamicSelector("dark_mode");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handle_redirect = (type) => {
    if (type === "fact_checks") {
      navigate(ROUTES.USER_FACT_CHECKS);
    } else {
      navigate(ROUTES.USER_ARTICLES);
    }
  };
  return (
    <div
      className={`donations-card ${dark_mode ? "dark_mode_card" : ""} `}
      id="contributions"
    >
      <div className={`bookmark_header ${dark_mode ? "dark_mode_card" : ""}`}>
        <h6 className="user_contribute_table_title_text">
          {t("user_contribution_in_footer")}
        </h6>
      </div>
      <div className="bookmark_body_container">
        {loading ? (
          Array.from({ length: 2 }, (_, index) => (
            <div className="bookmark">
              <Skeleton.Input key={index} active style={{ height: "80px" }} />
            </div>
          ))
        ) : (
          <React.Fragment>
            <div
              className={"bookmark bookmark-hover"}
              onClick={() => {
                handle_redirect("fact_checks");
              }}
            >
              <div className="bookmark-count">{fact_check_count}</div>
              <div
                className={
                  dark_mode
                    ? "bookmark-label light_mode_text"
                    : "bookmark-label"
                }
              >
                {t("fact_check_button_text")}
              </div>
            </div>
            <div
              className={"bookmark bookmark-hover"}
              onClick={() => {
                handle_redirect("articles");
              }}
            >
              <div className="bookmark-count">{article_count}</div>
              <div
                className={
                  dark_mode
                    ? "bookmark-label light_mode_text"
                    : "bookmark-label"
                }
              >
                {t("articles")}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default UserContributionCard;
