import React, { useEffect, useState } from "react";
import {
  dynamic_clear,
  dynamic_set,
  useDynamicSelector,
} from "@src/services/redux";
import FactCheckPartyWiseButtonMobileView from "./fact_check_party_wise_button_mobile_view";
import FactCheckPartyWiseFirstColumnMobileView from "./fact_check_party_wise_first_column_mobile_view";
import FactCheckPartyWiseSecondColumnMobileView from "./fact_check_party_wise_second_column_mobile_view";
import FactCheckPartyWiseSecondColumnSkeletonLoading from "../fact_check_party_wise_loading/fact_check_party_wise_second_column_loading";
import FactCheckPartyTagMobileView from "./fact_check_party_tag_mobile_view";
import FactCheckPartyWiseFirstColumnSkeletonLoading from "../fact_check_party_wise_loading/fact_check_party_wise_first_column_loading";
import { useDispatch } from "react-redux";
import { Empty } from "antd";

const FactCheckPartyWiseMobileView = () => {
  const [party_wise_fact_check_item, set_party_wise_fact_check_item] = useState(
    []
  );
  const dispatch = useDispatch();
  const {
    items: party_wise_fact_check_list,
    loading: party_wise_fact_check_list_loading,
  } = useDynamicSelector("get_party_wise_fact_check_list");
  let { items: party_wise_fact_checks } = useDynamicSelector(
    "party_wise_fact_checks"
  );

  const filtered_items = party_wise_fact_checks?.filter(
    (item, index) => index !== 0
  );

  useEffect(() => {
    if (party_wise_fact_check_list && party_wise_fact_check_list?.length > 0) {
      // set_party_wise_fact_check_item([...party_wise_fact_check_list]);
      dispatch(
        dynamic_set("party_wise_fact_checks", {
          items: [...party_wise_fact_check_list],
        })
      );
    }
  }, [party_wise_fact_check_list]);

  // useEffect(() => {
  //   dispatch(dynamic_clear("party_wise_fact_checks"))
  // }, [])

  return (
    <div className="fact_check_party_wise_mobile_container">
      <div className="home_fact_check_mobile_view">
        <FactCheckPartyTagMobileView />
        {party_wise_fact_check_list_loading ? (
          <FactCheckPartyWiseFirstColumnSkeletonLoading />
        ) : party_wise_fact_check_list?.length <= 0 ? (
          <div className="no_data_in_party_wise_mobile">
            <Empty description="No data" />
          </div>
        ) : (
          <FactCheckPartyWiseFirstColumnMobileView
            list={party_wise_fact_check_list?.[0]}
            type={"fact_check"}
          />
        )}
        <div className="exposing_fake_news_mobile_view_wrapper ">
          {filtered_items?.map((list) => (
            <FactCheckPartyWiseSecondColumnMobileView list={list} />
          ))}
        </div>
        {party_wise_fact_check_list_loading && (
          <FactCheckPartyWiseSecondColumnSkeletonLoading />
        )}
        <FactCheckPartyWiseButtonMobileView />
      </div>
    </div>
  );
};

export default FactCheckPartyWiseMobileView;
