import React from "react";
import { YOUTURN_ICON } from "@src/helpers/image_constants";
import { useTranslation } from "react-i18next";

function OurMission() {
  const { t } = useTranslation();

  return (
    <div className="our_mission_container">
      <div className="our_mission_left">
        <div className="our_mission_left_inner">
          <img src={YOUTURN_ICON} alt="youturn_logo" className="youturn_logo" />
          <p className="logo_content">
            part of People Educational Awareness Trust – Non-profitable
            education and awareness trust
          </p>
        </div>
      </div>
      <div className="our_mission_right">
        <p className="our_mission_right_heading">{t("our_mission")}</p>
        <p className="our_mission_right_content">
          {t("mission_header_content")}
        </p>
        <p className="our_mission_right_content">
          You Turn is a legally registered organization that is set up
          exclusively for the purpose of fact-checking. The registration
          certificate can be viewed {""}
          <a
            href="https://v-youturn.blr1.vultrobjects.com/2024/07/4ffded6c-c71e-44e6-a7bf-4b8961fff6da"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </p>
        <p className="our_mission_right_content">
          {t("mission_footer_content")}
        </p>
      </div>
    </div>
  );
}

export default OurMission;
