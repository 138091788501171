import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_my_donation_query } from "@src/services/graphql/graphql_donation";
import { useDynamicSelector } from "@src/services/redux";
import { countFormat } from "@src/helpers/functions";
import { useTranslation } from "react-i18next";

const DonationList = () => {
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const { t } = useTranslation();
  const get_donations_request = useAPIRequest(
    "get_all_donations",
    get_my_donation_query
  );
  const {
    items: donation_list,
    pagination: donation_pagination,
    loading: donation_loading,
  } = useDynamicSelector("get_all_donations");
  const get_all_my_donation = () => {
    get_donations_request(
      {
        page_limit: page_limit,
        page_number: page_number,
      },
      true
    );
  };

  useEffect(() => {
    get_all_my_donation();
  }, [page_limit, page_number]);

  const fetch_data = (value, value1) => {
    set_page_number(value);
    set_page_limit(value1);
  };

  const columns = [
    {
      title: t("date"),
      dataIndex: "created_date_time",
      key: "created_date_time",
      render: (text, record) => moment(text).format("MMM DD, YYYY"),
    },
    {
      title: t("reference_id"),
      dataIndex: "order_ref",
      key: "order_ref",
      render: (text) => {
        if (text === null || text === undefined) {
          return "-";
        }
        return text;
      },
    },
    {
      title: t("amount"),
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `₹ ${amount}`,
    },
  ];
  return (
    <div className="user_donation_details">
      <h2 className="black_text">{t("donations")}</h2>
      <div className="user_donation_list">
        <Table
          columns={columns}
          dataSource={donation_list}
          pagination={false}
          loading={donation_loading}
        />
        <div className="pagination_mobile_container">
          <Pagination
            total={donation_pagination?.total_count}
            showSizeChanger={true}
            page_number={page_number}
            hideOnSinglePage={false}
            onChange={fetch_data}
            showTotal={(total, range) => `Total ${countFormat(total)} items`}
          />
        </div>
      </div>
    </div>
  );
};

export default DonationList;
