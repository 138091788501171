import { retrieve_item } from "@src/helpers/cryptos";
import {
  countFormat,
  date_format,
  get_status,
  get_status_tag,
} from "@src/helpers/functions";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import {
  get_all_my_fact_check_query,
  get_all_my_published_fact_check_query,
} from "@src/services/graphql/graphql_fact_check";
import { useDynamicSelector } from "@src/services/redux";
import { Button, Card, Empty, Pagination, Skeleton, Table, Tabs } from "antd";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlinePendingActions, MdPublish } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../breadcrumb/breadcrumb";
import HomeArticleTrendingCard from "../../home/home_articles/home_article_trending_card";
import CategoryWiseFactCheckCard from "../../fact_check/browse_categories/category_wise_fact_check_card";

const UserContributionFactCheckList = () => {
  const [page_number, setPageNumber] = useState(1);
  const [page_limit, setPageLimit] = useState(10);
  const [publish_fact_check, set_publish_fact_check] = useState([]);
  const { t } = useTranslation();
  const get_fact_check_request = useAPIRequest(
    "get_all_my_fact_check",
    get_all_my_fact_check_query
  );
  const get_fact_check_published_request = useAPIRequest(
    "get_my_published_fact_check",
    get_all_my_published_fact_check_query
  );
  const language = retrieve_item("language");
  const navigate = useNavigate();
  const {
    items: all_fact_check_list,
    loading: all_fact_check_loading,
    pagination: all_fact_check_pagination,
    status: all_fat_check_list_status,
  } = useDynamicSelector("get_all_my_fact_check");
  const {
    items: all_fact_check_published_list,
    loading: all_fact_check_published_loading,
    pagination: all_fact_check_published_pagination,
  } = useDynamicSelector("get_my_published_fact_check");

  const get_all_my_fact_check = () => {
    get_fact_check_request(
      {
        status: "pending",
        // language_id: language?.id
        page_limit: page_limit,
        page_number: page_number,
      },
      true
    );
  };

  const get_all_my_published_fact_check = () => {
    get_fact_check_published_request(
      { status: "published", page_limit: 6, page_number: 1 },
      true
    );
  };

  useEffect(() => {
    get_all_my_fact_check();
    get_all_my_published_fact_check();
  }, [page_number, page_limit]);

  useEffect(() => {
    if (all_fact_check_published_list) {
      set_publish_fact_check([...all_fact_check_published_list]);
    }
  }, [all_fact_check_published_list]);

  const columns = [
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("created_date"),
      key: "created_date_time",
      dataIndex: "created_date_time",
      render: date_format,
    },
    {
      title: t("status"),
      key: "status",
      dataIndex: "status",
      width: 200,
      render: get_status,
    },
  ];
  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  const handle_row_click = (record) => {
    navigate(`${ROUTES.USER_FACT_CHECK_FORM}/${record?.id}`);
  };

  const handle_see_more = () => {
    get_fact_check_published_request(
      {
        status: "published",
        page_limit: all_fact_check_published_pagination?.page_limit + 3,
        page_number: 1,
      },
      true
    );
  };

  const MobileListCard = (props) => {
    const { created_date_time, description, status, ref_url, title } = props;
    return (
      <div
        style={{
          width: "100%",
          border: "1px solid gray",
          borderRadius: "5px",
          marginBottom: "10px",
          padding: "5px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>{t("title")}</div>
        <div style={{ fontSize: "13px" }}>{title}</div>

        <table>
          <tr>
            <th>{t("date")}</th>
            <td>{date_format(created_date_time)}</td>
          </tr>
          <tr>
            <th>{t("status")}</th>
            <td>{get_status(status)}</td>
          </tr>
        </table>
        {/* // </Card> */}
      </div>
    );
  };

  const UserContributionFactCheckPendingList = () => {
    return (
      <div>
        <div className="donation_mobile_container">
          {all_fact_check_loading
            ? Array.from({ length: 2 }, (_, index) => (
                <Card style={{ marginBottom: "15px" }}>
                  <Skeleton key={index} active paragraph={{ rows: 2 }} />
                </Card>
              ))
            : all_fact_check_list?.map((fact_check) => {
                return <MobileListCard {...fact_check} />;
              })}
        </div>
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={all_fact_check_list}
          pagination={false}
          loading={all_fact_check_loading}
          onRow={(record) => ({
            onClick: () => handle_row_click(record),
          })}
          className="user_donation_details"
        />
        <div
          className="pagination_mobile_container"
          style={{ marginTop: "10px" }}
        >
          <Pagination
            total={all_fact_check_pagination?.total_count}
            showSizeChanger={true}
            page_number={page_number}
            hideOnSinglePage={false}
            onChange={fetchData}
            showTotal={(total, range) => `Total ${countFormat(total)} items`}
          />
        </div>
      </div>
    );
  };

  const UserContributionFactCheckPublishList = () => {
    return (
      <>
        <div className="book_mark_container">
          {all_fact_check_published_loading &&
            Array.from({ length: 3 }, (_, index) => (
              <div className="bookmark bookmark_loading_container">
                <Skeleton.Image
                  key={index}
                  active
                  style={{ height: "200px", width: "380px" }}
                />
              </div>
            ))}
          {publish_fact_check?.map((list, index) => (
            <CategoryWiseFactCheckCard
              key={index}
              list={list}
              type={"fact-check"}
            />
          ))}
          {!all_fact_check_published_loading &&
          !publish_fact_check?.length > 0 ? (
            <div className="empty_container">
              <Empty description={t("no_published_fact_check")} />
            </div>
          ) : (
            ""
          )}
          {publish_fact_check?.length > 3 && (
            <div className="see_more_container">
              <Button className="see_more_videos" onClick={handle_see_more}>
                {t("see_more")}
              </Button>
            </div>
          )}
        </div>
      </>
    );
  };

  const tab_items = [
    {
      key: "1",
      label: t("published"),
      children: <UserContributionFactCheckPublishList />,
      icon: <MdPublish />,
    },
    {
      key: "2",
      label: t("pending"),
      children: <UserContributionFactCheckPendingList />,
      icon: <MdOutlinePendingActions />,
    },
  ];

  const handle_add = () => {
    navigate(ROUTES.USER_FACT_CHECK_FORM);
  };

  return (
    <div className="user_profile_contribution_fact_check">
      <h2 className="black_text">{t("my_fact_checks")}</h2>
      <div className="pagination_mobile_container">
        <Button onClick={handle_add} type="primary">
          {t("create_user_contribution_fact_check")}
        </Button>
      </div>
      <div className="tab_container">
        <Tabs items={tab_items} defaultActiveKey="1" />
      </div>
    </div>
  );
};

export default UserContributionFactCheckList;
