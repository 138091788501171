import React from "react";
import UserContributionFactCheckList from "../components/user_profile/user_contribution/user_contribution_fact_check_list";
import UserProfileLayout from "../ui/user_profile/user_profile";
import { ROUTES } from "@src/route/my_routes";
import Breadcrumbs from "../components/breadcrumb/breadcrumb";
import { useTranslation } from "react-i18next";

const UserContributionFactCheck = () => {
  const { t } = useTranslation();
  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },
    {
      path: ROUTES.USER_PROFILE,
      breadcrumb_name: "Profile",
    },
    {
      path: "",
      breadcrumb_name: t("my_fact_checks"),
    },
  ];

  return (
    <React.Fragment>
      <Breadcrumbs items={items} height={"30px"} />
      <UserProfileLayout>
        <UserContributionFactCheckList />
      </UserProfileLayout>
    </React.Fragment>
  );
};

export default UserContributionFactCheck;
