import {
  date_format,
  ensure_https,
  format_view_count,
  get_reading_time,
  size_wise_split_content,
  split_content,
  split_tamil_english_words,
  vertical_card_split_content,
} from "@src/helpers/functions";
import { BROKEN_IMAGE, READING_TAG } from "@src/helpers/image_constants";
import { Button, Skeleton, Typography } from "antd";
import React from "react";
import { AiFillClockCircle } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
import UserContributionListSkeletonLoading from "../../user_contribution.jsx/user_contribution_list_skeleton_loading";
import { useTranslation } from "react-i18next";
import TrendingResults from "../trending_results";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@src/route/my_routes";
import SearchResultsListSkeletonLoading from "./search_results_list_skeleton_loading";
import { useDynamicSelector } from "@src/services/redux";
import { readingTime } from "reading-time-estimator";
import { retrieve_item } from "@src/helpers/cryptos";

const CommonResultsContainer = (props) => {
  const {
    all_stories_list,
    handle_page_limit,
    user_contribution_list_loading,
    search_results_items_total_count,
  } = props;
  console.log(
    "search_results_items_total_count",
    search_results_items_total_count
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  let language = retrieve_item("language");
  const { dark_mode } = useDynamicSelector("dark_mode");
  const { size } = useDynamicSelector("screen_width");

  console.log(
    "all_stories_list",
    search_results_items_total_count,
    all_stories_list
  );

  const handle_redirect = (item) => {
    if (item?.record_type === "article") {
      const url = `${ROUTES.SINGLE_ARTICLE}/${item?.perma_link}.html`;
      window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_ARTICLE}/${item?.perma_link}.html`);
    } else {
      const url = `${ROUTES.SINGLE_FACT_CHECK}/${item?.perma_link}.html`;
      window.open(url, "_blank");
      // navigate(`${ROUTES.SINGLE_FACT_CHECK}/${item?.perma_link}.html`);
    }
  };

  return (
    <>
      <div>
        {user_contribution_list_loading &&
          Array.from({ length: 6 }, (_, index) => (
            <Skeleton.Input
              key={index}
              className="fact-check-list-news-story-card-skeleton"
              active
            />
          ))}

        {!user_contribution_list_loading &&
          all_stories_list?.map((item, index) => {
            const image_url = ensure_https(item?.thumbnail_img);
            // const parser = new DOMParser();
            // const doc = parser.parseFromString(item?.content, "text/html");
            // const result = readingTime(
            //   doc?.body?.textContent,
            //   language?.name === "tamil" ? 20 : 100
            // );

            return (
              <div className="all_stories_list_container_card">
                <div
                  className="home_article_first_card_container search_results_container"
                  onClick={() => handle_redirect(item)}
                >
                  <div className="home_article_card_thumbnail_container">
                    <img
                      src={image_url || BROKEN_IMAGE}
                      className="home_article_trending_thumbnail"
                    />
                  </div>
                  <div className="home_article_card_details_wrapper">
                    <div className="home_article_card_author_details_container">
                      <div className="home_article_card_author_details_wrapper">
                        <div className="home_article_card_author_name_container">
                          <Typography
                            className={`sub_title_text ${
                              dark_mode ? "dark_mode_text" : ""
                            }`}
                          >
                            {item?.user_profile?.name ||
                              item?.staff_profile?.name}
                          </Typography>
                        </div>
                        <div className="home_article_card_author_details">
                          <div className="home_article_card_time_container">
                            <LuEye className="view_icon" />
                            <Typography
                              className={`sub_title_text view_icon ${
                                dark_mode ? "dark_mode_text" : ""
                              }`}
                            >
                              {format_view_count(item?.views)}
                            </Typography>
                          </div>
                        </div>
                      </div>

                      <div className="home_article_card_author_details_wrapper">
                        <div className="home_article_card_author_name_container">
                          <Typography
                            className={`sub_title_text ${
                              dark_mode ? "dark_mode_text" : ""
                            }`}
                          >
                            {date_format(item?.published_date_time)}
                          </Typography>
                        </div>
                        <div className="home_article_card_author_details">
                          <div className="home_article_card_time_container">
                            <AiFillClockCircle className="reading_icon" />
                            <Typography
                              className={`sub_title_text ${
                                dark_mode ? "dark_mode_text" : ""
                              }`}
                            >
                              {get_reading_time(item?.reading_time, t)}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="home_article_card_title">
                      <Typography
                        className={`title_text  ${
                          dark_mode ? "dark_mode_text" : ""
                        }`}
                      >
                        {item?.title}
                      </Typography>
                    </div>
                    <div className="home_article_card_subtitle">
                      <Typography
                        className={`sub_title_text ${
                          dark_mode ? "dark_mode_text" : ""
                        }`}
                      >
                        {size_wise_split_content(item?.subtitle, size, 7) || ""}
                      </Typography>
                    </div>
                    <div className="home_article_card_continue_read">
                      <h4 className="continue_reading_text">
                        {t("continue_reading")}
                      </h4>
                      <img src={READING_TAG} style={{ height: "30px" }} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        {all_stories_list?.length < search_results_items_total_count && (
          <div className="load_more_container">
            <Button
              style={{
                width: "200px",
              }}
              onClick={handle_page_limit}
              className="load_more_button"
            >
              {t("load_more")}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
export default CommonResultsContainer;
