import { ensure_https } from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { useDynamicSelector } from "@src/services/redux";
import React from "react";

const SingleFactCheckSecondColumn = () => {
  const fact_check = useDynamicSelector("get_fact_check");
  const image_url = ensure_https(fact_check.thumbnail_img);

  return (
    <div className="single_article_second_column_container">
      <img style={{ width: "100%" }} src={image_url || BROKEN_IMAGE} />
    </div>
  );
};

export default SingleFactCheckSecondColumn;
