import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import { get_featured_fact_check_query } from "@src/services/graphql/graphql_fact_check";
import React, { useEffect } from "react";
import FeaturedFactCheckBackGroundContainer from "./featured_fact_check/featured_fact_check_desktop_view/featured_fact_check_back_ground_container";
import FeaturedFactCheckTitle from "./featured_fact_check/featured_fact_check_desktop_view/featured_fact_check_title";
import FeaturedFactCheckBackGroundContainerMobileView from "./featured_fact_check/featured_fact_check_mobile_view/featured_fact_check_back_ground_container_mobile_view";
import Breadcrumbs from "../breadcrumb/breadcrumb";
import { dynamic_set, useDynamicSelector } from "@src/services/redux";
import { useDispatch } from "react-redux";

const FeaturedFactCheck = () => {
  const current_language = retrieve_item("language");
  const dispatch = useDispatch();

  const { items: featured_fact_check, loading: featured_fact_check_loading } =
    useDynamicSelector("get_featured_fact_check");

  let featured_fact_check_request = useAPIRequest(
    "get_featured_fact_check",
    get_featured_fact_check_query
  );

  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },
    {
      path: "",
      breadcrumb_name: "Fact Check",
    },
  ];

  useEffect(() => {
    get_featured_fact_check();
  }, []);

  const get_featured_fact_check = () => {
    featured_fact_check_request(
      {
        page_limit: 1,
        page_number: 1,
        json: {
          language_id: current_language?.id,
        },
      },
      true
    );
  };

  return (
    <div>
      <Breadcrumbs items={items} />
      <FeaturedFactCheckTitle />
      <FeaturedFactCheckBackGroundContainer />
      <FeaturedFactCheckBackGroundContainerMobileView />
    </div>
  );
};

export default FeaturedFactCheck;
