import { Skeleton } from "antd";
import React from "react";

const SingleArticleFirstColumnSkeleton = () => {
  return (
    <div className="featured_story_content_skeleton">
      <div className="single_article_title_report_share_save_container">
        <Skeleton active paragraph={{ rows: 2 }} />
      </div>
      <div className="single_article_writer_details">
        <Skeleton active paragraph={{ rows: 2 }} />
      </div>
    </div>
  );
};

export default SingleArticleFirstColumnSkeleton;
