import { useDynamicSelector } from "@src/services/redux";
import StoryCard from "@src/views/ui/story_card/story_card";
import React from "react";
import Carousel from "react-multi-carousel";
import FactCheckPartyWiseFirstColumnMobileView from "../../fact_check/fact_check_party_wise/fact_check_party_wise_mobile_view.jsx/fact_check_party_wise_first_column_mobile_view";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";
import VerticalStoryCard from "@src/views/ui/vertical_story_card";
import FactCheckPartyWiseSecondColumnMobileView from "../../fact_check/fact_check_party_wise/fact_check_party_wise_mobile_view.jsx/fact_check_party_wise_second_column_mobile_view";
import HomeUserContributionMobileView from "../../home/home_user_contribution_stories/home_user_contribution_mobile_view";
import { retrieve_item } from "@src/helpers/cryptos";
import TopContributors from "../../home/home_user_contribution_stories/top_contributors";

const UserContributionRecentlyAddedMobileView = () => {
  const { t } = useTranslation();
  const language = retrieve_item("language");
  const {
    items: user_contribution_list,
    loading: user_contribution_list_loading,
    pagination: user_contribution_pagination,
  } = useDynamicSelector("get_user_contribution_list");
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 650 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 650 },
      items: 2,
    },
    // small_tablet: {
    //   breakpoint: { max: 768, min: 550 },
    //   items: 1.4,
    // },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="recommended_story_mobile_container">
      <h3
        className={
          language?.name === "tamil"
            ? "user_contribution_title_ta"
            : "user_contribution_title"
        }
      >
        {t("recently_added")}
      </h3>
      <div className="recommended_story_mobile_first_container">
        <FactCheckPartyWiseFirstColumnMobileView
          list={user_contribution_list?.[0]}
          type={user_contribution_list?.[0]?.record_type}
        />
      </div>

      {/* <div className="recently_added_mobile_container">
        {user_contribution_list?.map((contribution) => {
          return (
            <FactCheckPartyWiseSecondColumnMobileView
              list={contribution}
              type={contribution?.record_type}
            />
          );
        })}
      </div> */}
      <div className="home_user_contribution_mobile_view_main_container">
        <HomeUserContributionMobileView
          user_contribution_list={user_contribution_list}
          title={"recently_added"}
        />
        <TopContributors />
      </div>
      {user_contribution_list_loading && (
        <div
          className="home_user_contribution_card_container"
          style={{ padding: "10px" }}
        >
          <Skeleton active paragraph={{ rows: 2 }} />
        </div>
      )}
    </div>
  );
};

export default UserContributionRecentlyAddedMobileView;
