import React from "react";
import FeaturedVideoComponent from "./featured_video_component";
import FeaturedVideoContent from "./featured_video_content";
import FeaturedVideoContentSkeleton from "./featured_video_content_skeleton";
import FeaturedVideoComponentSkeleton from "./featured_video_component_skeleton";
import { useDynamicSelector } from "@src/services/redux";

const FeaturedVideoBackGroundContainer = () => {
  const { loading: video_list_loading } = useDynamicSelector("get_video_list");

  return (
    <div>
      <div className="featured_story_video_container">
        <div className="featured_video_back_ground_container"></div>
        <div className="featured_video_container">
          {video_list_loading ? (
            <FeaturedVideoComponentSkeleton />
          ) : (
            <FeaturedVideoComponent />
          )}

          {video_list_loading ? (
            <FeaturedVideoContentSkeleton />
          ) : (
            <FeaturedVideoContent />
          )}
        </div>
      </div>

      <div className="featured_story_video_container_mobile_view">
        <div className="featured_video_back_ground_container"></div>
        <div className="featured_video_container_mobile_view">
          {video_list_loading ? (
            <FeaturedVideoComponentSkeleton />
          ) : (
            <FeaturedVideoComponent />
          )}
          {video_list_loading ? (
            <FeaturedVideoContentSkeleton />
          ) : (
            <FeaturedVideoContent />
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturedVideoBackGroundContainer;
