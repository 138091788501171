import React from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../components/breadcrumb/breadcrumb";
import { ROUTES } from "@src/route/my_routes";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: t("home"),
    },

    {
      path: "",
      breadcrumb_name: "Privacy Policy",
    },
  ];

  return (
    <>
      <Breadcrumbs items={items} />
      <div className="corrections-policy">
        <h1>{t("privacy_policy")}</h1>
        <p>
          This privacy policy is an electronic record in the form of an
          electronic contract formed under the information technology act, 2000
          and the rules made thereunder and the amended provisions pertaining to
          electronic documents / records in various statutes as amended by the
          information technology act, 2000. This privacy policy does not require
          any physical, electronic or digital signature.
        </p>
        <p>
          This privacy policy is a legally binding document between you and
          youturn.in (both terms defined below). The terms of this privacy
          policy will be effective upon your acceptance of the same (directly or
          indirectly in electronic form, by clicking on the “i agree” tab or by
          use or the website or by other means) and will govern the relationship
          between you and youturn.in for your use of the website (defined
          below).
        </p>
        <p>
          This document is published and shall be construed in accordance with
          the provisions of the information technology (reasonable security
          practices and procedures and sensitive personal data of information)
          rules, 2011 under information technology act, 2000; that require
          publishing of the privacy policy for collection, use, storage and
          transfer of sensitive personal data or information.
        </p>
        <p>
          Please read this privacy policy carefully. By using the website, you
          indicate that you understand, agree and consent to this privacy
          policy. If you do not agree with the terms of this privacy policy,
          please do not use this website. You hereby provide your unconditional
          consent or agreements to youturn.in as provided under section 43a and
          section 72a of information technology act, 2000.
        </p>
        <p>
          By providing us your Information or by making use of the facilities
          provided by the Website, you hereby consent to the collection,
          storage, processing and transfer of any or all of Your Personal
          Information and Non-Personal Information by YOUTURN as specified under
          this Privacy Policy. You further agree that such collection, use,
          storage and transfer of Your Information shall not cause any loss or
          wrongful gain to you or any other person.
        </p>
        <p>
          People Educational Awareness Trust (also know as, “YOUTURN”) is
          concerned about the privacy of the data and information of users
          (including client/customers whether registered or non- registered)
          accessing, offering, services on Youturn’s websites, mobile sites or
          mobile applications (“Website”) on the Website.
        </p>
        <p>
          The terms “We” / “Us” / “Our” individually and collectively refer to
          entity being part of the definition of YOUTURN and the terms “You” /
          “Your” / “Yourself” refer to the users. This Privacy Policy is a
          contract between You and the respective YOUTURN entity whose website
          you use or access or You otherwise deal with. This Privacy Policy
          shall be read together with the respective Terms of Use or other terms
          and condition of the respective YOUTURN entity and its respective
          website or nature of business of the Website.
        </p>
        <p>
          We value the trust you place in us. That’s why we insist upon the
          highest standards for secure transactions and customer information
          privacy. Please read the following statement to learn about our
          information gathering and dissemination practices.
        </p>

        <h3>{t("notes")}</h3>

        <ol>
          <li>
            Our privacy policy is subject to change at any time without notice.
            To make sure you are aware of any changes, please review this policy
            periodically.
          </li>
          <li>
            By visiting this Website, you agree to be bound by the terms and
            conditions of this Privacy Policy. If you do not agree please do not
            use or access our website.
          </li>
          <li>
            By mere use of the Website, you expressly consent to our use and
            disclosure of your personal information in accordance with this
            Privacy Policy. This Privacy Policy is incorporated into and subject
            to the Terms & Conditions.
          </li>
        </ol>

        <h4>
          1.Collection of Personally Identifiable Information and other
          Information
        </h4>
        <p>
          When you use our website, we collect and store your personal
          information, which is provided by you from time to time. Our primary
          goal in doing so is to provide you a safe, efficient, smooth and
          customized experience. This allows us to provide services and features
          that most likely meet your needs, and to customize our website to make
          your experience safer and easier. More importantly, while doing so we
          collect personal information from you that we consider necessary for
          achieving this purpose.
        </p>
        <p>
          In general, you can browse the Website without telling us who you are
          or revealing any personal information about yourself. Once you give us
          your personal information, you are not anonymous to us. Where
          possible, we indicate which fields are required and which fields are
          optional. You always have the option to not provide information by
          choosing not to use a particular service or feature on the Website. We
          may automatically track certain information about you based upon your
          behavior on our Website. We use this information to do internal
          research on our users’ demographics, interests, and behavior to better
          understand, protect and serve our users. This information is compiled
          and analyzed on an aggregated basis. This information may include the
          URL that you just came from (whether this URL is on our website or
          not), which URL you next go to (whether this URL is on our website or
          not), your computer browser information, and your IP address.
        </p>
        <p>
          We use data collection devices such as “cookies” on certain pages of
          the Website to help analyze our web page flow, measure promotional
          effectiveness, and promote trust and safety. “Cookies” are small files
          placed on your hard drive that assist us in providing our services. We
          offer certain features that are only available through the use of a
          “cookie”. We also use cookies to allow you to enter your password less
          frequently during a session. Cookies can also help us provide
          information that is targeted to your interests. Most cookies are
          “session cookies,” meaning that they are automatically deleted from
          your hard drive at the end of a session. You are always free to
          decline our cookies if your browser permits, although in that case you
          may not be able to use certain features on the Website and you may be
          required to re-enter your password more frequently during a session.
        </p>
        <p>
          Additionally, you may encounter “cookies” or other similar devices on
          certain pages of the Website that are placed by third parties. We do
          not control the use of cookies by third parties.
        </p>
        <p>
          If you choose to buy on the Website, we collect information about your
          buying behavior.
        </p>
        <p>
          If you transact with us, we collect some additional information, such
          as a billing address, a credit / debit card number and a credit /
          debit card expiration date and/ or other payment instrument details
          and tracking information from cheques or money orders.
        </p>
        <p>
          If you choose to post messages on our message boards, chat rooms or
          other message areas or leave feedback, we will collect that
          information you provide to us. We retain this information as necessary
          to resolve disputes, provide customer support and troubleshoot
          problems as permitted by law.
        </p>
        <p>
          If you send us personal correspondence, such as emails or letters, or
          if other users or third parties send us correspondence about your
          activities or postings on the Website, we may collect such information
          into a file specific to you.
        </p>
        <p>
          We collect personally identifiable information (email address, name,
          phone number, credit card ,debit card / other payment instrument
          details, etc.) from you when you set up a free account with us. While
          you can browse some sections of our Website without being a registered
          member, certain activities (such as placing an order) do require
          registration. We do use your contact information to send you offers
          based on your previous orders and your interests.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
