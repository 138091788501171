import React, { useEffect, useState } from "react";
import AllStoriesUserContribution from "./all_stories_user_contribution";
import TopContributers from "./top_contributers";
import HomeArticleTrendingCard from "../home/home_articles/home_article_trending_card";
import UserContributionTitle from "./user_contribution_title";
import { useTranslation } from "react-i18next";
import UserContributionListSkeletonLoading from "./user_contribution_list_skeleton_loading";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import { useAPIRequest } from "@src/helpers/hooks";
import { get_user_contributors_list_query } from "@src/services/graphql/graphql_user_contribution";
import { useNavigate } from "react-router-dom";
import { retrieve_item } from "@src/helpers/cryptos";
import { USER_PROFILE } from "@src/helpers/image_constants";
import Carousel from "react-multi-carousel";
import TopContributersCarousel from "./top_contributers_carousel";
import { Button } from "antd";
import SecondaryDonation from "../custom_components/donation/secondary_donation";

const AllStoriesAndTopContributers = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const current_language = retrieve_item("language");

  const {
    all_stories_list,
    handle_page_limit,
    user_contribution_list_loading,
    recently_added_small_card_list,
    filtered_item,
  } = props;

  const { pagination: user_contribution_pagination } = useDynamicSelector(
    "get_user_contribution_list"
  );

  let user_contributors_list_request = useAPIRequest(
    "get_user_contributors_list",
    get_user_contributors_list_query
  );

  const { items: user_contributors_list } = useDynamicSelector(
    "get_user_contributors_list"
  );

  useEffect(() => {
    get_user_contributors_list();
  }, []);

  const get_user_contributors_list = () => {
    user_contributors_list_request(
      {
        language_id: current_language?.id,
      },
      true
    );
  };
  const handle_see_all = () => {
    navigate(ROUTES.TOP_CONTRIBUTORS);
  };

  return (
    <>
      <div className="all_stories_and_contributors_section">
        <div className="all_stories_and_contributors_container">
          <AllStoriesUserContribution
            all_stories_list={all_stories_list}
            filtered_item={filtered_item}
            handle_page_limit={handle_page_limit}
            user_contribution_list_loading={user_contribution_list_loading}
            title={t("all_stories")}
          />
          <TopContributers />
        </div>
      </div>
      <div className="top_contributors_section_mobile_view">
        {/* <div className="mobile_title_view_container">
          <UserContributionTitle title={t("top_contributors")} />
        </div> */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "120px",
          }}
        >
          <TopContributersCarousel
            user_contributors_list={user_contributors_list}
          />
        </div> */}
        <div className="donation_secondary_banner">
          <SecondaryDonation />
        </div>

        <div className="mobile_title_view_container">
          <UserContributionTitle title={t("recommended_stories")} />
        </div>

        <div className="home_article_row_container">
          <div className="home_article_row_wrapper">
            {recently_added_small_card_list?.map((item, index) => {
              return <HomeArticleTrendingCard list={item} />;
            })}
          </div>
        </div>

        <div className="mobile_title_view_container">
          <UserContributionTitle title={t("all_stories")} />
        </div>

        <div className="home_article_row_container">
          <div className="home_article_row_wrapper">
            {all_stories_list?.map((item, index) => {
              return <HomeArticleTrendingCard list={item} />;
            })}
          </div>
        </div>

        {user_contribution_pagination?.total_count > 0 &&
          user_contribution_pagination?.total_count !==
            all_stories_list?.length && (
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <Button
                style={{
                  width: "200px",
                }}
                onClick={handle_page_limit}
                className="load_more_button"
              >
                {t("load_more")}
              </Button>
            </div>
          )}
        {user_contribution_list_loading && (
          <UserContributionListSkeletonLoading />
        )}
      </div>
    </>
  );
};

export default AllStoriesAndTopContributers;
