import React from "react";
import { YOUTURN_NEW_LOGO } from "../../../../helpers/image_constants";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "@src/services/redux";

const SignInFormMobileView = (props) => {
  const { t } = useTranslation();
  const { sign_in, sign_up } = props;
  const { loading: login_loading } = useDynamicSelector("login");
  return (
    <div className="toggle-container">
      <div className="toggle">
        <div className="toggle-panel toggle-left">
          <div className="login_button_and_logo_image">
            <img
              style={{
                borderRadius: "9px",
                zIndex: 1,
                objectFit: "cover",

                height: "80px",
              }}
              src={YOUTURN_NEW_LOGO}
              alt="Profile"
            />
            <h1>{t("welcome_back")}</h1>
            <p>{t("already_have_an_account")}</p>
            <div className="sign_in_button_div">
              <button className="hidden" id="login" onClick={sign_in}>
                {login_loading ? (
                  <div class="leap-frog">
                    <div class="leap-frog__dot"></div>
                    <div class="leap-frog__dot"></div>
                    <div class="leap-frog__dot"></div>
                  </div>
                ) : (
                  t("login")
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="toggle-panel toggle-right">
          <div className="sign_up_and_log_div">
            <img
              style={{
                borderRadius: "9px",
                zIndex: 1,
                objectFit: "cover",

                height: "80px",
              }}
              src={YOUTURN_NEW_LOGO}
              alt="Profile"
            />
            <h1>{t("welcome")}</h1>
            <p>{t("register_new_user")}</p>
            <div className="sing_up_button_div">
              <button className="hidden" id="register" onClick={sign_up}>
                {t("sign_up")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInFormMobileView;
