// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .user_profile_background {
    height: fit-content;
  }
  .bookmark_title_container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .book_mark_container {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 11px;
    padding: 2%;
    width: 100%;
  }
  .profile-card {
    min-height: 420px;
  }
  /* .profile_body_container { */
    /* min-height: 250px; */
  /* } */
  #donations,
  #claims {
    width: 65%;
    height: 100%;
  }
  #bookmarks,
  #contributions {
    width: 30%;
  }
  .bookmark_body_container {
    height: 85%;
  }

    
  .no_transaction{
    font-size:20px;
    margin-bottom: 20px;
  }
 
}
`, "",{"version":3,"sources":["webpack://./src/styles/screen_768_1023/screen_768_1023_user_profile.css"],"names":[],"mappings":"AAAA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;EACA;IACE,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,WAAW;IACX,WAAW;EACb;EACA;IACE,iBAAiB;EACnB;EACA,8BAA8B;IAC5B,uBAAuB;EACzB,MAAM;EACN;;IAEE,UAAU;IACV,YAAY;EACd;EACA;;IAEE,UAAU;EACZ;EACA;IACE,WAAW;EACb;;;EAGA;IACE,cAAc;IACd,mBAAmB;EACrB;;AAEF","sourcesContent":["@media only screen and (min-width: 768px) and (max-width: 1023px) {\r\n  .user_profile_background {\r\n    height: fit-content;\r\n  }\r\n  .bookmark_title_container {\r\n    padding-left: 20px;\r\n    padding-right: 20px;\r\n  }\r\n  .book_mark_container {\r\n    display: grid;\r\n    grid-template-columns: 50% 50%;\r\n    gap: 11px;\r\n    padding: 2%;\r\n    width: 100%;\r\n  }\r\n  .profile-card {\r\n    min-height: 420px;\r\n  }\r\n  /* .profile_body_container { */\r\n    /* min-height: 250px; */\r\n  /* } */\r\n  #donations,\r\n  #claims {\r\n    width: 65%;\r\n    height: 100%;\r\n  }\r\n  #bookmarks,\r\n  #contributions {\r\n    width: 30%;\r\n  }\r\n  .bookmark_body_container {\r\n    height: 85%;\r\n  }\r\n\r\n    \r\n  .no_transaction{\r\n    font-size:20px;\r\n    margin-bottom: 20px;\r\n  }\r\n \r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
