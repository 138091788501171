import { ROUTES } from "@src/route/my_routes";
import { useNavigate } from "react-router-dom";
import UserContributionFeaturedTitle from "./user_contribution_featured_content/user_contribution_featured_title";
import FeaturedUserContributionBackGroundContainer from "./user_contribution_featured_content/user_contribution_back_ground_container";
import FeaturedUserContributionBackGroundContainerMobileView from "./featured_user_contribution_mobile_view/featured_user_contribution_back_ground_container_mobile_view";
import Breadcrumbs from "../breadcrumb/breadcrumb";

const FeaturedUserContribution = (props) => {
  const { featured_user_contribution } = props;

  const navigate = useNavigate();

  const handle_redirect = (perma_link, type) => {
    if (type === "article") {
      navigate(`${ROUTES.SINGLE_ARTICLE_PAGE}/${perma_link}.html`);
    } else if (type === "fact-check" || type === "fact_check") {
      navigate(`${ROUTES.SINGLE_FACT_CHECK}/${perma_link}.html`);
    }
  };
  const items = [
    {
      path: ROUTES.HOME,
      breadcrumb_name: "Home",
    },
    {
      path: "",
      breadcrumb_name: "User Contributions",
    },
  ];

  return (
    <div>
      <Breadcrumbs items={items} />
      <UserContributionFeaturedTitle />
      <FeaturedUserContributionBackGroundContainer
        featured_user_contribution={featured_user_contribution}
      />
      <FeaturedUserContributionBackGroundContainerMobileView
        featured_user_contribution={featured_user_contribution}
      />
    </div>
  );
};

export default FeaturedUserContribution;
