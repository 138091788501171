import { retrieve_item } from "@src/helpers/cryptos";
import { date_format, ensure_https, split_title } from "@src/helpers/functions";
import { BROKEN_IMAGE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { useDynamicSelector } from "@src/services/redux";
import { Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const RecentFactCheckCard = (props) => {
  const { list, type } = props;
  const navigate = useNavigate();
  const { name } = retrieve_item("language");
  const { dark_mode } = useDynamicSelector("dark_mode");
  const image_url = ensure_https(list?.thumbnail_img);

  const navigate_to_detail_page = () => {
    if (type === "article") {
      navigate(`${ROUTES.SINGLE_ARTICLE}/${list?.perma_link}.html`);
    } else {
      navigate(`${ROUTES.SINGLE_FACT_CHECK}/${list?.perma_link}.html`);
    }
  };
  return (
    <div
      className="recent_fact_check_card_container"
      onClick={navigate_to_detail_page}
    >
      <div className="recent_fact_check_thumbnail_container">
        <img
          className="recent_fact_check_thumbnail"
          src={image_url || BROKEN_IMAGE}
        />
      </div>
      <div className="recent_fact_check_inner_title_container">
        <div className="recent_fact_check_title_wrapper">
          <Typography
            className={`
              ${
                name === "tamil"
                  ? "recent_fact_check_title_text_ta"
                  : "recent_fact_check_title_text"
              }
              ${dark_mode ? "dark_mode_text" : ""}
            `}
          >
            {split_title(list?.title, 60, 40)}
          </Typography>
        </div>
        <h6
          className={`home_user_contribution_two_profile  ${
            dark_mode ? "dark_mode_text" : ""
          }`}
        >
          {`${list?.staff_profile?.name} | ${date_format(
            list?.created_date_time
          )}`}
        </h6>
      </div>
    </div>
  );
};

export default RecentFactCheckCard;
