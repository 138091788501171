import { useDynamicSelector } from "@src/services/redux";
import { Col, Row, Typography } from "antd";
import React from "react";
import { LuEye } from "react-icons/lu";
import { AiFillClockCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { BROKEN_IMAGE, READING_TAG } from "@src/helpers/image_constants";
import {
  date_format,
  ensure_https,
  format_views_count,
  split_content,
} from "@src/helpers/functions";
import moment from "moment";
import { retrieve_item } from "@src/helpers/cryptos";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@src/route/my_routes";
import SlotCard from "@src/views/ui/slot_card";

const ExposingFakeNewsSecondColumn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dark_mode } = useDynamicSelector("dark_mode");
  const { items: fact_check_list, loading: fact_check_list_loading } =
    useDynamicSelector("get_fact_check_list");
  const language = retrieve_item("language");
  let is_english = language?.name === "english";
  return (
    fact_check_list?.length > 0 && (
      <div className="slot_main_container  margin_zero">
        <SlotCard {...fact_check_list?.[1]} type="fact_check" />
      </div>
    )
  );
};

export default ExposingFakeNewsSecondColumn;
