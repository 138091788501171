import { gql } from "@apollo/client";

export const forgot_password_query = gql`
  query forgot_password($username: String!) {
    forgot_password(username: $username) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;