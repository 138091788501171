import React from 'react'

const UserProfileLayout = ({ children }) => {
    return (
        <div className='user_profile_background'>
            {children}
        </div>
    )
}

export default UserProfileLayout
