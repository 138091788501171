import React from 'react';
import Breadcrumbs from '../components/breadcrumb/breadcrumb';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@src/route/my_routes';

const PoliticalInvolvementPolicy = () => {
    const { t } = useTranslation()
    const items = [
        {
            path: ROUTES.HOME,
            breadcrumb_name: t("home"),
        },
        {
            path: ROUTES.ABOUT_US,
            breadcrumb_name: t("about_us"),
        },
        {
            path: "",
            breadcrumb_name: t("political_involvement_policy"),
        },
    ];
    return (
        <>
            <Breadcrumbs items={items} />
            <div className="policy-container">
                <h1>{t("political_involvement_policy")}</h1>
                <p>YouTurn is a non-partisan fact-checking organization, and as a result, employees associated with us are strictly refrained to participate in any political or non-political activity that effects the company’s policy. To protect YouTurn’s commitment to non-partisanship any New/Existing – Employees/Volunteers should adhere to all the company’s standards and policies. This document covers the important aspects of the policy but it is not limited to only below. Every employee plays a vital and is deemed responsible in case of any violations.</p>
                <p>The Term Employee here refers to all the people who work full time, Part-time or Individual contributors. This Policy is applicable to all the above.</p>
                <ol>
                    <li><strong>Unbiased Analysis</strong> – Employees are subject to fact-check, validate, analyse the claims on a non-partisan basis irrespective of their personal opinions. This includes Political Parties, Corporations or Organizations but not limited to the above. Fact-check is to be completely based on the evidence gathered.</li>
                    <li><strong>Political Activity</strong> – Employees should not engage in any political activities, such as but not limited to, being a part of any of the political parties, advocacy organizations, participating in any events of electoral campaigns or raising funds towards the same.</li>
                    <li><strong>Non-partisan Article</strong> – Employees must provide unbiased fact-check information in the article regardless of their personal affiliation to any Political Party, Religion, Caste, Community, Race and Sexual Orientation or anything for that matter. The article may not endorse or oppose anything as stated above but it has to provide the required information with appropriate evidence.</li>
                    <li><strong>Article Review</strong> – Effective multi-layer validation of the article is to done before publishing it. Validation done by any volunteer is to be re-checked by an Employee or by the supervisor. No compromise is accepted on providing biased information. Influencing personal preferences in the article is not encouraged.</li>
                </ol>
            </div>
        </>
    );
};

export default PoliticalInvolvementPolicy;
