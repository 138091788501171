import { retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { ROUTES } from "@src/route/my_routes";
import { get_category_wise_fact_check_list_query } from "@src/services/graphql/graphql_fact_check";
import { useDynamicSelector } from "@src/services/redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CategoryWiseFactCheckCard from "./category_wise_fact_check_card";
import { Button, Divider, Empty, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import FactCheckCategoryTagsMobileView from "./fact_check_category_tags_mobile_view";

const CategoryWiseFactCheck = (props) => {
  const {
    set_page_limit,
    set_fact_check_page_limit,
    fact_check_page_limit,
    category_id,
    page_limit,
    default_category_name,
    set_category_id,
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const current_language = retrieve_item("language");
  const [filtered_item, set_filtered_item] = useState([]);
  let category_wise_fact_check_request = useAPIRequest(
    "get_category_wise_fact_check_list",
    get_category_wise_fact_check_list_query
  );

  const { items: featured_fact_check } = useDynamicSelector(
    "get_featured_fact_check"
  );

  const {
    items: category_wise_list,
    loading: category_wise_list_loading,
    pagination: category_wise_list_pagination,
  } = useDynamicSelector("get_category_wise_fact_check_list");

  useEffect(() => {
    if (category_id !== "More") {
      get_category_wise_fact_check_list(category_id);
    }
  }, [
    category_id,
    page_limit,
    fact_check_page_limit,
    featured_fact_check?.[0]?.id,
  ]);

  useEffect(() => {
    if (
      category_wise_list &&
      category_wise_list?.length > 0 &&
      (!default_category_name ||
        default_category_name === "All" ||
        default_category_name === "அனைத்தும்" ||
        default_category_name === "மேலும்" ||
        default_category_name === "More")
    ) {
      set_filtered_item([...category_wise_list]);
    } else if (category_wise_list && category_wise_list?.length > 0) {
      set_filtered_item([...category_wise_list]);
    } else if (!category_wise_list_loading && !category_wise_list?.length) {
      set_filtered_item([]);
    }
  }, [
    category_wise_list,
    default_category_name,
    page_limit,
    fact_check_page_limit,
  ]);

  const get_category_wise_fact_check_list = (category_id) => {
    category_wise_fact_check_request(
      {
        page_number: 1,
        page_limit:
          !default_category_name ||
          default_category_name === "All" ||
          default_category_name === "அனைத்தும்" ||
          default_category_name === "மேலும்" ||
          default_category_name === "More"
            ? fact_check_page_limit
            : page_limit,
        json: {
          language_id: current_language?.id,
          category_tag: category_id === "All" ? null : category_id,
          exclude_id: featured_fact_check?.[0]?.id,
        },
      },
      true
    );
  };

  const handle_see_more = () => {
    set_page_limit(page_limit + 6);
    set_fact_check_page_limit(fact_check_page_limit + 6);
  };

  return (
    <>
      <div className="fact_check_party_tags_in_mobile">
        <FactCheckCategoryTagsMobileView
          get_category_wise_fact_check_list={get_category_wise_fact_check_list}
          set_category_id={set_category_id}
        />
      </div>
      <div className="category_wise_list_cards_wrapper">
        {filtered_item?.length > 0 &&
          filtered_item?.map((item, index) => (
            <CategoryWiseFactCheckCard
              key={index}
              list={item}
              type={"fact-check"}
            />
          ))}
        {category_wise_list_loading &&
          Array.from({ length: 6 }, (_, index) => (
            <Skeleton.Input
              key={index}
              active
              className="category_wise_card_container_skeleton"
            />
          ))}
        {category_wise_list?.length <= 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "300px",
            }}
          >
            <Empty description="No data" />
          </div>
        )}
      </div>
      <div style={{ display: "flex" }}>
        <Divider className="see_more_button_divider">
          {category_wise_list_pagination?.total_count >
            filtered_item?.length && (
            <Button className="see_more_button" onClick={handle_see_more}>
              {t("see_more")}
            </Button>
          )}
        </Divider>
      </div>
    </>
  );
};

export default CategoryWiseFactCheck;
